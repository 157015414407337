import { ColumnMode } from '@swimlane/ngx-datatable';

export class ListConfig {
	columnMode?: ColumnMode = ColumnMode.force;
	headerHeight?: number = 40;
	rowHeight?: 'auto' | number = 'auto';
	footerHeight?: number = 50;
	limit?: number = 10;
	externalPaging?: boolean = true;
	externalSorting?: boolean = true;
	scrollbarH?: boolean = true;
	scrollbarV?: boolean = false;
	reorderable?: boolean = true;
}
