import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentEditorComponent} from './document-editor/document-editor.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {FormsModule} from '@angular/forms';


@NgModule({
    declarations: [
        DocumentEditorComponent
    ],
    exports: [
        DocumentEditorComponent
    ],
    imports: [
        CommonModule,
        CKEditorModule,
        FormsModule
    ]
})
export class EditorModule {
}
