import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ColumnMode, DatatableComponent} from '@swimlane/ngx-datatable';
import {Subject} from 'rxjs';
import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {EmployeeService} from '../employee.service';
import {BaseListComponent} from 'app/main/shared/base/components/base-list-component';
import {TextboxControl} from 'app/layout/components/filter/interfaces/textbox.control';
import Swal from 'sweetalert2';
import {EmployeeRequest} from '../data/models/employee-request.model';
import {EmployeeResponse} from '../data/models/employee.model';
import {Router} from '@angular/router';
import {LeaveTimeType} from '../../leave-form/data/enums/leaveTimeType.enum';
import {EducationStatus} from '../data/enums/educationStatus.enum';
import {EducationLevel} from '../data/enums/educationLevel.enum';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-employee-list',
    templateUrl: './employee-list.component.html',
    styleUrls: ['./employee-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EmployeeListComponent extends BaseListComponent<EmployeeRequest, EmployeeResponse> implements OnInit {
    // Public
    public sidebarToggleRef = false;
    public rows;
    public selectedOption = 10;
    public ColumnMode = ColumnMode;
    public leaveTimeType = LeaveTimeType;
    public educationStatus = EducationStatus;
    // Decorator
    @ViewChild(DatatableComponent) table: DatatableComponent;

    // Private
    private tempData = [];
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {CoreSidebarService} _coreSidebarService
     * @param _employeeService
     * @param router
     * @param translate
     */
    constructor(
        _coreSidebarService: CoreSidebarService,
        public _employeeService: EmployeeService,
        private router: Router,
        private translate: TranslateService,
    ) {
        super(_employeeService, _coreSidebarService);
        this._unsubscribeAll = new Subject();
        this.pageConfig = {
            pageKey: 'EmployeeList',
            filterSidebarKey: 'employee-list-filter-sidebar',
        };

        this.filterConfig = [
            {
                class: 'row',
                formGroups: [
                    {
                        class: 'col-6',
                        control: new TextboxControl({
                            key: 'name',
                            label: 'Name',
                        }),
                    },
                    {
                        class: 'col-6',
                        control: new TextboxControl({
                            key: 'surname',
                            label: 'Last Name',
                        }),
                    },
                    {
                        class: 'col-6',
                        control: new TextboxControl({
                            key: 'title',
                            label: 'Title',
                        }),
                    },
                    {
                        class: 'col-6',
                        control: new TextboxControl({
                            key: 'personalInformation.identificationNumber',
                            label: 'Identification Number',
                        }),
                    },
                ],
            },
        ];
    }

    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * filterUpdate
     *
     * @param event
     */
    filterUpdate(event) {
        const val = event.target.value.toLowerCase();

        // Filter Our Data
        const temp = this.tempData.filter(function (d) {
            return d.fullName.toLowerCase().indexOf(val) !== -1 || !val;
        });

        // Update The Rows
        this.rows = temp;
        // Whenever The Filter Changes, Always Go Back To The First Page
        this.table.offset = 0;
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */

    ngOnInit(): void {
    }

    getTranslateName(name) {
        return `employee.${EducationLevel[name].toString()}`;
    }

    deleteEmployee = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ff9f43',
            cancelButtonColor: '#ee5818',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.value) {
                this._employeeService.deleteApi(id).subscribe((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Deleted!',
                        text: 'Employee has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    });
                    this.dataService.dataSubject$.next(null);
                });
            }
        });
    };

    employeeDetailPage(id) {
        this.router.navigateByUrl(`/employees/employee-view/${id}`);
    }

    activatedEmployee(id) {
        this._employeeService.changeEmployeeActivatedStatus(id, true).subscribe((res) => {
            if (res.results) {
                Swal.fire({
                    icon: 'success',
                    title: 'Activated!',
                    text: 'Employee has been activated.',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                });
                this.dataService.dataSubject$.next(null);
            }
        });
    }

    deactivatedEmployee(id) {
        this._employeeService.changeEmployeeActivatedStatus(id, false).subscribe((res) => {
            if (res.results) {
                Swal.fire({
                    icon: 'success',
                    title: 'Deactivated!',
                    text: 'Employee has been deactivated.',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                });
                this.dataService.dataSubject$.next(null);
            }
        });
    }
}
