import {Component, OnInit, ViewChild} from '@angular/core';
import {colors} from '../../../colors.const';
import {AuthenticationService} from '../../../auth/service';
import {RemainingWorkService} from '../remaining-work/shared/remaining-work.service';
import moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {RemainingWorkDTO} from '../remaining-work/shared/models/remaining-work-response.model';
import {EmployeeEntryModel} from '../remaining-work/shared/models/employee-entry.model';
import {EmployeeEntriesModalComponent} from '../remaining-work/employee-entries-modal/employee-entries-modal.component';
import * as _ from 'lodash';

@Component({
    selector: 'app-employee-time-tracker',
    templateUrl: './employee-time-tracker.component.html',
    styleUrls: ['./employee-time-tracker.component.scss']
})
export class EmployeeTimeTrackerComponent implements OnInit {

    private $white = '#fff';
    private $textHeadingColor = '#5e5873';
    public supportChartoptions = {
        chart: {
            height: 290,
            type: 'radialBar',
            sparkline: {
                enabled: false
            }
        },
        plotOptions: {
            radialBar: {
                offsetY: 20,
                startAngle: -150,
                endAngle: 150,
                hollow: {
                    size: '65%'
                },
                track: {
                    background: this.$white,
                    strokeWidth: '100%'
                },
                dataLabels: {
                    name: {
                        offsetY: -5,
                        color: this.$textHeadingColor,
                        fontSize: '1rem'
                    },
                    value: {
                        offsetY: 15,
                        color: this.$textHeadingColor,
                        fontSize: '1.714rem'
                    }
                }
            }
        },
        colors: [colors.solid.danger],
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: [colors.solid.primary],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
            }
        },
        stroke: {
            dashArray: 6
        },
        labels: ['Completed Work Time']
    };
    public dayCount = 7;
    public type = 'thisWeek';
    public currentWorkHour = 45;
    public employeeTimeData: RemainingWorkDTO;
    public entries: EmployeeEntryModel[];
    public groupedEntries: { day: string, entries: EmployeeEntryModel[] }[];

    @ViewChild(EmployeeEntriesModalComponent) entriesModal: EmployeeEntriesModalComponent;


    public data = {
        series: [0]
    };

    getStartAndEndDate(type: string): { startDate: Date, endDate: Date } {
        let today, startDate, endDate, monday, friday, monthFirst, monthLast;
        switch (type) {
            case 'today':
                today = moment().set({hours: 0, minutes: 0}).toDate();
                startDate = moment(today);
                endDate = moment(startDate).add(1, 'days');
                return {startDate, endDate};
            case 'yesterday':
                today = moment().set({hours: 0, minutes: 0}).toDate();
                startDate = moment(today).add(-1, 'days');
                endDate = moment(today);
                return {startDate, endDate};
            case 'thisWeek':
                monday = moment().startOf('week').set({hours: 0, minutes: 0, seconds: 0}).toDate();
                friday = moment().endOf('week').set({hours: 23, minutes: 59, seconds: 59}).toDate();
                return {startDate: monday, endDate: friday};
            case 'lastWeek':
                monday = moment().startOf('week').add(-1, 'weeks').set({hours: 0, minutes: 0, seconds: 0}).toDate();
                friday = moment().endOf('week').add(-1, 'weeks').set({hours: 23, minutes: 59, seconds: 59}).toDate();
                return {startDate: monday, endDate: friday};
            case 'thisMonth':
                monthFirst = moment().startOf('month').set({hours: 0, minutes: 0, seconds: 0}).toDate();
                monthLast = moment().endOf('month').set({hours: 23, minutes: 59, seconds: 59}).toDate();
                return {startDate: monthFirst, endDate: monthLast};
            case 'lastMonth' :
                monthFirst = moment().startOf('month').add(-1, 'month').set({hours: 0, minutes: 0, seconds: 0}).toDate();
                monthLast = moment().endOf('month').add(-1, 'month').set({hours: 23, minutes: 59, seconds: 59}).toDate();
                return {startDate: monthFirst, endDate: monthLast};
        }
    }

    onWeekChange(days: string) {
        const dayCount = parseInt(days.split(',')[0], 10);
        this.type = days.split(',')[1];
        const {startDate, endDate} = this.getStartAndEndDate(this.type);
        this.currentWorkHour = this.addWeekdays(moment(startDate), moment(endDate)) * 9;
        this.getEmployeeTimeData(moment(startDate), moment(endDate));
        this.dayCount = dayCount;
    }

    get selectedDayCountName() {
        return this.translate.instant(`general.${this.type}`);
    }

    constructor(private _remainingWorkService: RemainingWorkService,
                private _authService: AuthenticationService,
                private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.onWeekChange('7,thisWeek');
    }

    addWeekdays(start, end) {
        let weekdayCounter = 0;

        while (start < end) {
            if (start.format('ddd') !== 'Sat' && start.format('ddd') !== 'Sun') {
                weekdayCounter++;
            }
            start = moment(start, 'YYYY-MM-DD').add(1, 'days');
        }
        return weekdayCounter;
    }

    getTimeFromMinutes(minutes: number) {
        if (!minutes) {
            return `0 ${this.translate.instant('general.minutes')}`;
        }
        if (minutes < 0) {
            return `0 ${this.translate.instant('general.minutes')}`;
        }
        return `${Math.floor(minutes / 60).toFixed(0)} ${this.translate.instant('general.hours')} ${minutes % 60} ${this.translate.instant('general.minutes')}`;
    }

    getEmployeeTimeData(startDate, endDate) {
        if (this._authService.currentUserValue.employeeId) {
            this.currentWorkHour = this.addWeekdays(startDate, endDate) * 9;
            this._remainingWorkService.getEmployeeDetail(
                this._authService.currentUserValue.employeeId.toString(),
                startDate.format('YYYY-MM-DD'),
                endDate.format('YYYY-MM-DD')
            ).subscribe((res) => {
                if (!res.results) {
                    this.data.series = [0];
                } else {
                    const remainingTime = ((Number(res.results.workingTimeInMinute)
                        + res.results.leaveTimeAsMinutes + res.results.holidayAsMinutes -
                        Number(res.results.travelTimeInMinute) + res.results.travelTimeAsMinutes
                    ) / (this.currentWorkHour * 60));
                    let percentage = parseInt((remainingTime * 100).toFixed(0), 10);
                    percentage = percentage > 100 ? 100 : percentage;
                    percentage = percentage < 0 ? 0 : percentage;
                    this.data.series = [percentage];
                }
                this.entries = res.results.entries && res.results.entries[0] ? res.results.entries : [];
                const groupedByDay = _.groupBy(res.results.entries, this.day);
                this.groupedEntries = Object.keys(groupedByDay).map(item => {
                    return {
                        day: item,
                        entries: _.orderBy(groupedByDay[item], ['verifyDate'], ['asc'])
                    };
                });
                this.employeeTimeData = res.results;
            });
        }
    }

    day = (item: EmployeeEntryModel) => moment(item.verifyDate).format('DD-MM-YYYY');

    openEntriesModal() {
        this.entriesModal.modalOpenVC();
    }

}
