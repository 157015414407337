import {BaseService} from 'app/main/shared/base/services/base.service';
import {fillFilterData, getLocalStorage, removeLocalStorage, setLocalStorage} from './../../utils';
import {PageConfig} from './../models/page-config.model';
import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, defer, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {Pagination} from '../models/pagination.model';
import {FilterConfig} from 'app/layout/components/filter/interfaces/filter.config';
import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {Sorting} from '../models/sorting.model';
import {ListConfig} from '../models/list-config.model';
import {PageFilter} from '../models/pageFilter.model';

@Injectable()
export class BaseListComponent<TRequest, TResponse> implements OnInit, OnDestroy {
    dataService: BaseService<TRequest, TResponse>;
    pageConfig: PageConfig;
    filter: any;
    page = new Pagination();
    sorting = new Sorting();
    filterConfig: FilterConfig<string>[];
    listConfig = new ListConfig();
    pageFilter: PageFilter = {
        column: ['CreatedDate'],
        count: 10,
        descending: true,
        index: 1,
        search: '',
    };
    searchStringAfterResetFilters = '';

    constructor(service: BaseService<TRequest, TResponse>, private _coreSidebarService: CoreSidebarService) {
        this.dataService = service;
        service.dataList$ = defer(() => {
            this.getCurrentFilters();
            return service.dataSubject$.pipe(
                switchMap((res) =>
                    res
                        ? of(res)
                        : service.getAllApi(this.pageFilter).pipe(
                        tap((res) => {
                            if (res && res.count == 0 && this.page.offset > 0) {
                                this.page.offset -= 1;
                                service.dataSubject$.next(null);
                            } else {
                                service.dataSubject$.next(res);
                            }
                        })
                        )
                )
            );
        });
    }

    getCurrentFilters = () => {
        this.filter = getLocalStorage([`${this.pageConfig.pageKey}CurrentFilter`]);
        this.filterConfig = fillFilterData(this.filterConfig, this.filter);
        const page = getLocalStorage([`${this.pageConfig.pageKey}CurrentPage`]);
        const sorting = getLocalStorage([`${this.pageConfig.pageKey}Sorting`]);
        this.page = {
            offset: 0,
            pageSize: 10,
        };

        this.sorting = {
            sort: '',
            sortBy: 0,
        };
    };

    resetFilters = () => {
        removeLocalStorage([
            `${this.pageConfig.pageKey}CurrentFilter`,
            `${this.pageConfig.pageKey}CurrentPage`,
            `${this.pageConfig.pageKey}Sorting`,
        ]);
        this.filter = {};
        this.pageFilter.search = this.searchStringAfterResetFilters;
        this.page = {
            offset: 0,
            pageSize: 10,
        };

        this.sorting = {
            sort: '',
            sortBy: 0,
        };

        this.dataService.dataSubject$.next(null);
    };

    ngOnInit() {
    }

    ngOnDestroy() {
        this.dataService.dataSubject$ = new BehaviorSubject(null);
    }

    setPage = (pageInfo) => {
        setLocalStorage({
            key: `${this.pageConfig.pageKey}CurrentPage`,
            value: pageInfo,
        });
        this.pageFilter.index = pageInfo.offset + 1;
        this.page = {
            offset: pageInfo.offset,
            pageSize: 10,
        };

        this.dataService.dataSubject$.next(null);
    };

    setFilter = (model) => {
        if (model) {
            this.pageFilter.search = this.searchStringAfterResetFilters + '&';
            Object.keys(model).forEach((key) => {
                if (model[key]) {
                    this.pageFilter.search += key + '=' + model[key] + '&';
                }
            });

            this.dataService.dataSubject$.next(null);
        }

        this.page = {
            offset: 0,
            pageSize: 10,
        };

        if (this.pageConfig.saveFilter) {
            setLocalStorage({
                key: `${this.pageConfig.pageKey}CurrentPage`,
                value: {offset: 0, pageSize: 10},
            });

            setLocalStorage({
                key: `${this.pageConfig.pageKey}CurrentFilter`,
                value: model,
            });
        }

        this.filter = model;

        this.toggleFilter();
    };

    toggleFilter() {
        this._coreSidebarService.getSidebarRegistry(this.pageConfig.filterSidebarKey).toggleOpen();
    }

    onSort(event) {
        this.pageFilter.column = [event.column.prop.charAt(0).toUpperCase() + event.column.prop.slice(1)];
        this.pageFilter.descending = event.newValue == 'asc' ? false : true;
        this.sorting.sort = event.column.prop;
        this.sorting.sortBy = event.newValue == 'asc' ? 0 : 1;
        setLocalStorage({
            key: `${this.pageConfig.pageKey}Sorting`,
            value: this.sorting,
        });
        this.dataService.dataSubject$.next(null);
    }
}
