import {Injectable} from '@angular/core';
import {BaseService} from '../../../shared/base/services/base.service';
import {DocumentFileTypeModel} from '../../file-uploader/shared/models/file.model';
import {HttpClient} from '@angular/common/http';
import {PageFilter} from '../../../shared/base/models/pageFilter.model';

@Injectable({
    providedIn: 'root'
})
export class DocumentTypeService extends BaseService<DocumentFileTypeModel, DocumentFileTypeModel> {

    constructor(private http: HttpClient) {
        super(http, 'document-service/document-types');
    }

    getIdByDocumentTypeNameOrCreateAndReturnId(title: string, resolve) {
        const pageFilter: PageFilter = {
            column: ['Id'],
            count: 1,
            descending: true,
            index: 1,
            search: 'title=' + title
        };
        this.getAllApi(pageFilter).subscribe((res) => {
            if (res && res.results && res.results[0]) {
                resolve(res.results[0].id);
            } else {
                const docTypeModel: DocumentFileTypeModel = {
                    title
                };
                this.postApi(docTypeModel).subscribe(addedRes => {
                    if (addedRes.results) {
                        resolve(addedRes.results.id);
                    }
                });
            }
        });
    }

}
