<core-sidebar
  class="modal modal-slide-in sidebar-todo-modal fade"
  name="{{ sidebarKey }}"
  overlayClass="modal-backdrop"
>
  <div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
      <div class="modal-dialog">
        <div class="modal-content pt-0">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleFilter()">
            ×
          </button>
          <div class="modal-header mb-1">
            <h5 class="modal-title" id="exampleModalLabel">Filter</h5>
          </div>
          <div class="modal-body flex-grow-1">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</core-sidebar>
