<div class="row">
    <div class="col-12">
        <app-employee-time-tracker></app-employee-time-tracker>
    </div>
    <div class="col-12">
        <app-calendar></app-calendar>
    </div>
</div>

<ngb-alert [type]="'warning'" [dismissible]="false" class="mt-3">
    <div class="alert-body">
        <strong>Organisation Chart</strong>
    </div>
</ngb-alert>
<img *ngIf="!isDark" src="/assets/images/pages/Amatisorganization.png" alt="Organisation" class="img-fluid"/>
<img
        *ngIf="isDark"
        src="/assets/images/pages/organisationchartdark.png"
        alt="Organisation"
        class="img-fluid dark-layout"
/>
<div class="card"></div>

