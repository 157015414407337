import {CoreMenu} from '@core/types';

export const menu: CoreMenu[] = [
    {
        title: 'Dashboard',
        id: 'home',
        translate: 'menu.dashboard',
        type: 'item',
        icon: 'home',
        url: 'dashboard',
        role: ['1', '2', '3'],
    },
    {
        id: 'users',
        title: 'Master Data',
        translate: 'menu.masterData',
        type: 'collapsible',
        icon: 'users',
        url: '',
        role: ['1', '2'],
        children: [
            {
                id: 'employees',
                title: 'Employees',
                translate: 'menu.employees',
                type: 'item',
                icon: 'users',
                url: 'employees',
                role: ['1', '2'],
            },
            {
                id: 'users',
                title: 'Users',
                translate: 'menu.users',
                type: 'item',
                icon: 'align-center',
                url: 'user',
                role: ['1', '2'],
            },
            {
                id: 'cards',
                title: 'Cards',
                translate: 'menu.cardsData',
                type: 'item',
                icon: 'credit-card',
                url: 'card-data',
                role: ['1', '2'],
            },
        ],
    },
    {
        id: 'company',
        title: 'Company',
        translate: 'menu.company',
        icon: 'tool',
        type: 'collapsible',
        url: '',
        role: ['1', '2'],
        children: [
            {
                id: 'assets',
                title: 'Assets',
                translate: 'menu.asset',
                type: 'item',
                icon: 'archive',
                url: 'assets',
                role: ['1', '2'],
            },
            {
                id: 'asset-categories',
                title: 'Asset Categories',
                translate: 'menu.assetCategory',
                type: 'item',
                icon: 'archive',
                url: 'asset-categories',
                role: ['1', '2'],
            },
            {
                id: 'official-holidays',
                title: 'Official Holidays',
                translate: 'menu.officialHoliday',
                type: 'item',
                icon: 'gift',
                url: 'official-holidays',
                role: ['1', '2'],
            },
        ]
    },
    {
        id: 'document',
        title: 'Documents',
        translate: 'menu.documents',
        type: 'collapsible',
        icon: 'clipboard',
        url: '',
        role: ['2'],
        children: [
            {
                id: 'contrats',
                title: 'Contracts',
                translate: 'menu.contrats',
                type: 'item',
                icon: 'file-text',
                url: 'documents/contrats',
                role: ['2'],
            },
            {
                id: 'personaldocuments',
                title: 'Personal Documents',
                translate: 'menu.personalDocument',
                type: 'item',
                icon: 'file-text',
                url: 'documents/document-list',
                role: ['2'],
            },
            {
                id: 'documenttypeadd',
                title: 'Add Document Type',
                translate: 'menu.addDocumentType',
                type: 'item',
                icon: 'file-text',
                url: 'documents/document-type-add',
                role: ['2'],
            },
        ],
    },
    {
        id: 'forms',
        title: 'Forms',
        translate: 'menu.forms',
        type: 'collapsible',
        icon: 'file',
        url: '',
        role: ['1', '2', '3'],
        children: [
            {
                id: 'form-management',
                title: 'Form Management',
                translate: 'menu.formManagement',
                type: 'item',
                icon: 'tool',
                url: 'forms',
                role: ['1', '2'],
            },
            {
                id: 'leaveform',
                title: 'Leave Form',
                translate: 'menu.leaveForm',
                type: 'item',
                icon: 'file-text',
                url: 'leave-form',
                role: ['1', '2', '3'],
            },
            {
                id: 'travelform',
                title: 'Travel Form',
                translate: 'menu.travelForm',
                type: 'item',
                icon: 'file-text',
                url: 'travel-form/travel-list',
                role: ['1', '2', '3'],
            },
            {
                id: 'visitorform',
                title: 'Visitor Form',
                translate: 'menu.visitorForm',
                type: 'item',
                icon: 'file-text',
                url: 'visitor-form/visitor-list',
                role: ['1', '2', '3'],
            },
            {
                id: 'leavemanagementform',
                title: 'Leave Management Form',
                translate: 'menu.leaveManagementForm',
                type: 'item',
                icon: 'file-text',
                url: 'leave-management-form',
                role: ['1', '2'],
            },
            {
                id: 'travelmanagementform',
                title: 'Travel Management Form',
                translate: 'menu.travelManagementForm',
                type: 'item',
                icon: 'file-text',
                url: 'travel-management-form',
                role: ['1', '2'],
            },
            // {
            //     id: 'leavemanagementform',
            //     title: 'Leave Management Form',
            //     translate: 'menu.fastLeaveForm',
            //     type: 'item',
            //     icon: 'file-text',
            //     url: 'fast-leave-form/fast-leave-list',
            //     role: ['1', '2'],
            // },
            // {
            //   id: 'educationform',
            //   title: 'Education Form',
            //   type: 'item',
            //   icon: 'file-text',
            //   url: 'education-form/education-list',
            //   role: ['1', '2', '3'],
            // },
        ],
    },
    {
        id: 'notification',
        title: 'Notification',
        translate: 'menu.notification',
        type: 'item',
        icon: 'bell',
        url: 'notifications',
        role: ['1', '2']
    },
    {
        id: 'remaining',
        title: 'Remaining Working Time',
        translate: 'menu.remainingWork',
        type: 'item',
        icon: 'alert-circle',
        url: 'remaining-work',
        role: ['1', '2'],
    },
    {
        id: 'logs',
        title: 'Logs',
        translate: 'menu.logs',
        type: 'collapsible',
        icon: 'file',
        url: '',
        role: ['1'],
        children: [
            // {
            //   id: 'user-logs',
            //   title: 'User Logs',
            //   translate: 'menu.userLogs',
            //   type: 'item',
            //   icon: 'file-text',
            //   url: 'logs/user-logs',
            //   role: ['1', '2'],
            // },
            {
                id: 'forms-logs',
                title: 'Forms Logs',
                translate: 'menu.formsLogs',
                type: 'item',
                icon: 'file-text',
                url: 'logs/forms-logs',
                role: ['1', '2'],
            },
            {
                id: 'employee-logs',
                title: 'Employee Logs',
                translate: 'menu.employeeLogs',
                type: 'item',
                icon: 'file-text',
                url: 'logs/employee-logs',
                role: ['1', '2'],
            },
            {
                id: 'auth-logs',
                title: 'Auth Logs',
                translate: 'menu.authLogs',
                type: 'item',
                icon: 'file-text',
                url: 'logs/auth-logs',
                role: ['1', '2'],
            },
        ],
    },
    {
        id: 'reports',
        title: 'Reports',
        translate: 'menu.reports',
        type: 'collapsible',
        icon: 'file-text',
        url: '',
        role: ['1', '2'],
        children: [
            {
                id: 'monthlyReport',
                title: 'Monthly Report',
                translate: 'menu.monthlyReport',
                type: 'item',
                icon: 'calendar',
                url: 'reports/monthly',
                role: ['1', '2'],
            },
        ],
    },
];
