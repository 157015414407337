import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {MaritalStatus} from '../../employee/data/enums/maritalStatus.enum';
import {EmergencyInformation, EmployeeRequest, OtherInformation,} from '../../employee/data/models/employee-request.model';
import {Role} from '../data/enums/role.enum';
import {UserRequest} from '../data/models/user.model';
import {UserService} from '../user.service';
import {AuthenticationService} from '../../../../auth/service';

@Component({
    selector: 'app-user-add',
    templateUrl: './user-add.component.html',
    styleUrls: ['./user-add.component.scss'],
})
export class UserAddComponent implements OnInit {
    public profilePhotoPath: string;
    public photo: any;
    public rows;
    public roleEnum: any = Role;
    public maritalStatusEnum: any = MaritalStatus;
    public urlLastValue;
    public url = this.router.url;
    private _unsubscribeAll: any;
    public employee: EmployeeRequest = {} as EmployeeRequest;
    public emergency: EmergencyInformation = {} as EmergencyInformation;
    public other: OtherInformation = {} as OtherInformation;
    public user: UserRequest = {} as UserRequest;
    @Output() addNewUser: EventEmitter<any> = new EventEmitter<any>();

    constructor(private router: Router, private _userService: UserService, public _authService: AuthenticationService) {
        this._unsubscribeAll = new Subject();
        this.urlLastValue = this.url.substr(this.url.lastIndexOf('/') + 1);
    }

    ngOnInit(): void {
    }

    addUser() {
        this.addNewUser.emit(this.user);
    }
}
