import { CoreSidebarService } from './../../../../../@core/components/core-sidebar/core-sidebar.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-filter-sidebar',
	templateUrl: './filter-sidebar.component.html',
	styleUrls: ['./filter-sidebar.component.scss'],
})
export class FilterSidebarComponent implements OnInit {
	@Input() sidebarKey: string;
	@Output() filterEmit: EventEmitter<any> = new EventEmitter<any>();
	constructor(private _coreSidebarService: CoreSidebarService) {}

	ngOnInit() {}

	toggleFilter() {
		this._coreSidebarService.getSidebarRegistry(this.sidebarKey).toggleOpen();
	}
}
