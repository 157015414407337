import {Component, OnInit, ViewChild} from '@angular/core';

import {NotificationsService} from 'app/layout/components/navbar/navbar-notification/notifications.service';
import {
    NotificationRecipientStatus,
    NotificationResponseModel
} from '../../../../main/components/notification/shared/model/notification-response.model';
import {AuthenticationService} from '../../../../auth/service';
import {NotificationService} from '../../../../main/components/notification/shared/service/notification-service.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-navbar-notification',
    templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
    // Public
    public notifications: NotificationResponseModel[] = [];
    public length = 0;
    public currentNotification: NotificationResponseModel;

    /**
     *
     * @param {NotificationsService} _notificationsService
     * @param _authService
     * @param _notificationService
     * @param _modalService
     */
    constructor(private _notificationsService: NotificationsService,
                private _authService: AuthenticationService,
                private _notificationService: NotificationService,
                private _modalService: NgbModal) {
    }

    @ViewChild('modalSM') modalSM;

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        this.getNotifications();
        this.startListeningNewNotificatios();

    }

    private getNotifications() {
        this._notificationService.getMyNotifications().subscribe(res => {
            if (res.results) {
                this.notifications = res.results;
                res.results.forEach(notification => {
                    if (notification.peopleWhoReceivedTheNotification
                        .find(x => x.employeeId === this._authService.currentUserValue.employeeId) == null) {
                        this.length++;
                    }
                });

            }
        });
    }

    hasNotificationReceived(notification: NotificationResponseModel) {
        return notification.peopleWhoReceivedTheNotification
            .find(x => x.employeeId === this._authService.currentUserValue.employeeId) != null;
    }

    private startListeningNewNotificatios() {
        this._notificationsService.onNotificationDataChange.subscribe(res => {
            if (res) {
                if (res.notificationRecipients.find(x => x.employee?.id === this._authService.currentUserValue.employeeId)
                    || res.notificationRecipients.find(x => x.company != null)) {
                    this.notifications = [res, ...this.notifications];
                    this.length++;
                }
            }
        });
    }

    onNotificationClick(notification: NotificationResponseModel) {
        if (notification.peopleWhoReceivedTheNotification
            .find(x => x.employeeId === this._authService.currentUserValue.employeeId) == null) {
            const status: NotificationRecipientStatus = {
                employeeId: this._authService.currentUserValue.employeeId,
                notificationId: notification.id
            };
            this._notificationService.setNotificationAsReceived(status).subscribe();
            const currentNotification = this.notifications.find(x => x.id === notification.id);
            currentNotification.peopleWhoReceivedTheNotification = [...currentNotification.peopleWhoReceivedTheNotification, status];
            this.length--;
        }
        this.currentNotification = notification;
        this._modalService.open(this.modalSM, {
            centered: true,
            size: 'sm' // size: 'xs' | 'sm' | 'lg' | 'xl',
        });
    }
}
