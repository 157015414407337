import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {BaseResponse} from 'app/main/data/base-response.model';
import {downloadFile} from 'app/main/shared/functions/downloadFile';
import {formatEmployeePhoto} from 'app/main/shared/functions/getEmployeePhoto';
import {FlatpickrOptions} from 'ng2-flatpickr';
import {Observable, Subject} from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';
import {DocumentModel} from '../../document/shared/document.mode';
import {DocumentService} from '../../document/shared/document.service';
import {UserRequest} from '../../user/data/models/user.model';
import {EmergencyInformation} from '../data/models/employee-request.model';
import {EmployeeResponse, OtherInformation} from '../data/models/employee.model';
import {EmployeeService} from '../employee.service';
import {ContractType} from '../data/enums/contract.enum';
import mimeTypes from '../../../data/mime-types';

@Component({
    selector: 'app-employee-view',
    templateUrl: './employee-view.component.html',
    styleUrls: ['./employee-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EmployeeViewComponent implements OnInit {
    // public
    public data;
    public documentsList: Array<any> = [];
    public employee: EmployeeResponse = {} as EmployeeResponse;
    public employee$: Observable<BaseResponse<EmployeeResponse>>;
    public emergency: EmergencyInformation = {} as EmergencyInformation;
    public other: OtherInformation = {} as OtherInformation;
    public user: UserRequest = {} as UserRequest;
    public rows: DocumentModel[] = [];
    public selectedOption = 10;
    public ColumnMode = ColumnMode;
    public photo: any;
    public contractType = ContractType;

    public contentHeader: object;
    public birthDateOptions: FlatpickrOptions = {
        altInput: true,
    };
    public passwordTextTypeOld = false;
    public passwordTextTypeNew = false;
    public passwordTextTypeRetype = false;

    // private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {Router} router
     * @param _employeeService
     * @param activatedRoute
     * @param _documentService
     */
    constructor(
        private router: Router,
        private _employeeService: EmployeeService,
        private activatedRoute: ActivatedRoute,
        private _documentService: DocumentService
    ) {
        this._unsubscribeAll = new Subject();
    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit(): void {
        this.employee$ = this.activatedRoute.params.pipe(
            mergeMap((data) => {
                return this._employeeService.getEmployeeDetail(data.id);
            }),
            tap((data) => {
                this.employee = data.results;

                if (this.employee) {
                    this._employeeService.getPhoto(this.employee.id).subscribe((res) => {
                        formatEmployeePhoto(res).subscribe((photo) => {
                            this.photo = photo;
                        });
                    });
                }
            })
        );
    }

    downloadDocument(id: number) {
        const document = this.employee.documents.find((d) => d.id === id);
        this._documentService.downloadDocuments(id).subscribe((res) => {
            const name = `${this.employee.name}_${this.employee.surname}-${document.title}.${mimeTypes[document.contentType]}`;
            downloadFile(res, name);
        });
    }
}
