export enum EducationLevel {
  none = 0,
  primarySchool = 1,
  middleSchool = 2,
  highSchool = 3,
  associate = 4,
  underGraduate = 5,
  postGraduate = 6,
  doctorate = 7,
  unspecified = 100,
}
