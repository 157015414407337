<div class="content-wrapper" *ngIf="employee$ | async as employeeModel">
    <div class="content-body">
        <section id="page-account-settings">
            <div class="row nav-vertical d-flex">
                <div class="col-md-3 mb-2 mb-md-0">
                    <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left">
                        <li class="nav-item" ngbNavItem>
                            <a
                                    ngbNavLink
                                    class="nav-link d-flex py-75"
                                    id="account-pill-general"
                                    data-toggle="pill"
                                    href="#account-vertical-general"
                                    aria-expanded="true"
                            >
                                <i data-feather="user" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold"> {{ 'employee.personalInformation' | translate }} </span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <!-- header media -->
                                        <div class="media">
                                            <a href="javascript:void(0);" class="mr-25">
                                                <div *ngIf="!photo || photo === ''">
                                                    <img
                                                            sclass="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer image-fluid ml-sm-75"
                                                            height="90"
                                                            [src]="'/assets/images/avatars/user-avatar.png'"
                                                    />
                                                </div>
                                                <div *ngIf="photo && photo !== ''">
                                                    <img src="{{ photo }}" class="rounded mr-50" alt="profile image"
                                                         height="100"/>
                                                </div>
                                            </a>
                                        </div>
                                        <span>{{employee.name}} {{employee.surname}}</span>
                                        <form class="validate-form mt-2">
                                            <div class="row">
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-username" class="font-weight-bold"
                                                        >{{ 'general.name' | translate }} :</label
                                                        >
                                                        <p class="badge-light form-control">{{ employee.name | titlecase }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-name" class="font-weight-bold"
                                                        >{{ 'general.surname' | translate }} :</label
                                                        >
                                                        <p class="badge-light form-control">{{ employee.surname | titlecase }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-e-mail" class="font-weight-bold"
                                                        >{{ 'employee.idNumber' | translate }} :</label
                                                        >
                                                        <p class="badge-light form-control">
                                                            {{ employee.personalInformationResponse.identificationNumber }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-company " class="font-weight-bold"
                                                        >{{ 'employee.gender' | translate }} :</label
                                                        >
                                                        <p class="badge-light form-control">
                              <span
                                      *ngIf="employee.personalInformationResponse.gender === 1"
                                      [innerText]="'employee.male' | translate"
                              ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.gender === 2"
                                                                    [innerText]="'employee.female' | translate"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.gender === 3"
                                                                    [innerText]="'employee.other' | translate"
                                                            ></span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                            <span for="account-company" class="font-weight-bold"
                            >{{ 'employee.maxLeaveDay' | translate }} :</span
                            >
                                                        <p class="badge-light form-control">{{ employee.maximumLeaveDay }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                            <span for="account-company" class="font-weight-bold"
                            >{{ 'employee.birthDate' | translate }} :</span
                            >
                                                        <p class="badge-light form-control">
                                                            {{ employee.personalInformationResponse.dateOfBirth | date: 'dd-MM-yyyy' }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                            <span for="account-company" class="font-weight-bold"
                            >{{ 'employee.personalMailAddress' | translate }} :</span
                            >
                                                        <p class="badge-light form-control">{{ employee.personalMailAddress }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                            <span for="account-company" class="font-weight-bold"
                            >{{ 'employee.personalPhoneNumber' | translate }} :</span
                            >
                                                        <p class="badge-light form-control">{{ employee.personalPhoneNumber }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                            <span for="account-company" class="font-weight-bold"
                            >{{ 'employee.educationStatus' | translate }} :</span
                            >
                                                        <p class="badge-light form-control">
                              <span
                                      *ngIf="employee.personalInformationResponse.educationStatus === 1"
                                      [innerText]="'employee.graduated' | translate"
                              ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.educationStatus === 2"
                                                                    [innerText]="'employee.student' | translate"
                                                            ></span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                            <span for="account-company" class="font-weight-bold"
                            >{{ 'employee.educationInstitution' | translate }} :</span
                            >
                                                        <p class="badge-light form-control">
                                                            {{ employee.personalInformationResponse.educationalInstitution }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                            <span for="account-company" class="font-weight-bold"
                            >{{ 'employee.educationLevel' | translate }} :</span
                            >
                                                        <p class="badge-light form-control">
                              <span
                                      *ngIf="employee.personalInformationResponse.educationLevel === 1"
                                      [innerText]="'employee.none' | translate"
                              ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.educationLevel === 2"
                                                                    [innerText]="'employee.primarySchool' | translate"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.educationLevel === 3"
                                                                    [innerText]="'employee.middleSchool' | translate"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.educationLevel === 4"
                                                                    [innerText]="'employee.highSchool' | translate"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.educationLevel === 5"
                                                                    [innerText]="'employee.associate' | translate"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.educationLevel === 6"
                                                                    [innerText]="'employee.underGraduate' | translate"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.educationLevel === 7"
                                                                    [innerText]="'employee.postGraduate' | translate"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.educationLevel === 8"
                                                                    [innerText]="'employee.doctorate' | translate"
                                                            ></span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                            <span for="account-company" class="font-weight-bold"
                            >{{ 'employee.maritalStatus' | translate }} :</span
                            >
                                                        <p class="badge-light form-control">
                              <span
                                      *ngIf="employee.personalInformationResponse.maritalStatus === 1"
                                      [innerText]="'employee.single' | translate"
                              ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.maritalStatus === 2"
                                                                    [innerText]="'employee.married' | translate"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.maritalStatus === 3"
                                                                    [innerText]="'employee.divorced' | translate"
                                                            ></span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                            <span for="account-company" class="font-weight-bold"
                            >{{ 'employee.bloodGroup' | translate }} :</span
                            >
                                                        <p class="badge-light form-control">
                              <span
                                      *ngIf="employee.personalInformationResponse.bloodGroup === 1"
                                      [innerText]="'0RH(+)'"
                              ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.bloodGroup === 2"
                                                                    [innerText]="'ORH(-)'"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.bloodGroup === 3"
                                                                    [innerText]="'ARH(+)'"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.bloodGroup === 4"
                                                                    [innerText]="'ARH(-)'"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.bloodGroup === 5"
                                                                    [innerText]="'BRH(+)'"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.bloodGroup === 6"
                                                                    [innerText]="'BRH(-)'"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.bloodGroup === 7"
                                                                    [innerText]="'ABRH(+)'"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.personalInformationResponse.bloodGroup === 8"
                                                                    [innerText]="'ABRH(-)'"
                                                            ></span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                            <span for="account-company" class="font-weight-bold"
                            >{{ 'employee.numberOfChildren' | translate }} :</span
                            >
                                                        <p class="badge-light form-control">
                                                            {{ employee.personalInformationResponse.numberOfChildren }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                            <span for="account-company" class="font-weight-bold">{{
                                'employee.militaryStatus' | translate
                                }}</span>
                                                        <p class="badge-light form-control">
                              <span
                                      *ngIf="employee.militaryService === 1"
                                      [innerText]="'employee.done' | translate"
                              ></span>
                                                            <span
                                                                    *ngIf="employee.militaryService === 2"
                                                                    [innerText]="'employee.undone' | translate"
                                                            ></span>
                                                            <span
                                                                    *ngIf="employee.militaryService === 3"
                                                                    [innerText]="'employee.exempt' | translate"
                                                            ></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" ngbNavItem>
                            <a
                                    ngbNavLink
                                    class="nav-link d-flex py-75"
                                    id="account-pill-password"
                                    data-toggle="pill"
                                    href="#account-vertical-password"
                                    aria-expanded="false"
                            >
                                <i data-feather="briefcase" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold">{{ 'employee.companyInformation' | translate }}</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="media">
                                            <a href="javascript:void(0);" class="mr-25">
                                                <div *ngIf="!photo || photo === ''">
                                                    <img
                                                            sclass="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer image-fluid ml-sm-75"
                                                            height="90"
                                                            [src]="'/assets/images/avatars/user-avatar.png'"
                                                    />
                                                </div>
                                                <div *ngIf="photo && photo !== ''">
                                                    <img src="{{ photo }}" class="rounded mr-50" alt="profile image"
                                                         height="100"/>
                                                </div>
                                            </a>
                                        </div>
                                        <span>{{employee.name}} {{employee.surname}}</span>
                                        <form class="validate-form mt-2">
                                            <div class="row">
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-username" class="font-weight-bold"
                                                        >{{ 'employee.title' | translate }} :</label
                                                        >
                                                        <p class="badge-light form-control">
                                                            {{ employee.title | titlecase }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-name" class="font-weight-bold"
                                                        >{{ 'employee.dateOfStartWork' | translate }} :</label
                                                        >
                                                        <p class="badge-light form-control">{{ employee.dateOfStart | date: 'dd-MM-yyyy' }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-e-mail" class="font-weight-bold"
                                                        >{{ 'employee.contractType' | translate }} :</label>
                                                        <p class="badge-light form-control">
                                                            {{ 'employee.' + contractType[employee.contractType].toString().toLowerCase() | translate }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-company" class="font-weight-bold"
                                                        >{{ 'employee.contractEndDate' | translate }} :</label
                                                        >
                                                        <p class="badge-light form-control">{{ employee.contractEndDate | date: 'dd-MM-yyyy' }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                            <span for="account-company" class="font-weight-bold"
                            >{{ 'employee.companyMailAddress' | translate }} :</span
                            >
                                                        <p class="badge-light form-control">{{ employee.companyMailAddress }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                            <span for="account-company" class="font-weight-bold"
                            >{{ 'employee.companyPhoneNumber' | translate }} :</span
                            >
                                                        <p class="badge-light form-control">{{ employee.companyPhoneNumber }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" ngbNavItem>
                            <a
                                    ngbNavLink
                                    class="nav-link d-flex py-75"
                                    id="account-pill-info"
                                    data-toggle="pill"
                                    href="#account-vertical-info"
                                    aria-expanded="false"
                            >
                                <i data-feather="book-open" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold"> {{ 'employee.otherInformation' | translate }}</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="media">
                                            <a href="javascript:void(0);" class="mr-25">
                                                <div *ngIf="!photo || photo === ''">
                                                    <img
                                                            sclass="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer image-fluid ml-sm-75"
                                                            height="90"
                                                            [src]="'/assets/images/avatars/user-avatar.png'"
                                                    />
                                                </div>
                                                <div *ngIf="photo && photo !== ''">
                                                    <img src="{{ photo }}" class="rounded mr-50" alt="profile image"
                                                         height="100"/>
                                                </div>
                                            </a>
                                        </div>
                                        <span>{{employee.name}} {{employee.surname}}</span>
                                        <form class="validate-form mt-2">
                                            <div class="row">
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-username" class="font-weight-bold"
                                                        >{{ 'employee.addressLine1' | translate }} :</label
                                                        >
                                                        <p class="badge-light form-control">
                                                            {{ employee.personalInformationResponse.otherInformation.address1 | titlecase }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-name" class="font-weight-bold"
                                                        >{{ 'employee.addressLine2' | translate }} :</label
                                                        >
                                                        <p class="badge-light form-control">
                                                            {{ employee.personalInformationResponse.otherInformation.address2 | titlecase }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-e-mail" class="font-weight-bold"
                                                        >{{ 'employee.bankName' | translate }} :</label
                                                        >
                                                        <p class="badge-light form-control">
                                                            {{ employee.personalInformationResponse.otherInformation.bankName }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-company" class="font-weight-bold"
                                                        >{{ 'employee.accountNumber' | translate }} :</label
                                                        >
                                                        <p class="badge-light form-control">
                                                            {{ employee.personalInformationResponse.otherInformation.accountNumber }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                            <span for="account-company" class="font-weight-bold"
                            >{{ 'employee.iban' | translate }} :</span
                            >
                                                        <p class="badge-light form-control">
                                                            {{ employee.personalInformationResponse.otherInformation.iban }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" ngbNavItem>
                            <a
                                    ngbNavLink
                                    class="nav-link d-flex py-75"
                                    id="account-pill-social"
                                    data-toggle="pill"
                                    href="#account-vertical-social"
                                    aria-expanded="false"
                            >
                                <i data-feather="phone-outgoing" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold">{{ 'employee.emergencyInformation' | translate }}</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="media">
                                            <a href="javascript:void(0);" class="mr-25">
                                                <div *ngIf="!photo || photo === ''">
                                                    <img
                                                            sclass="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer image-fluid ml-sm-75"
                                                            height="90"
                                                            [src]="'/assets/images/avatars/user-avatar.png'"
                                                    />
                                                </div>
                                                <div *ngIf="photo && photo !== ''">
                                                    <img src="{{ photo }}" class="rounded mr-50" alt="profile image"
                                                         height="100"/>
                                                </div>
                                            </a>
                                        </div>
                                        <span>{{employee.name}} {{employee.surname}}</span>
                                        <form class="validate-form mt-2">
                                            <div class="row">
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-username" class="font-weight-bold"
                                                        >{{ 'employee.name' | translate }} :</label
                                                        >
                                                        <p class="badge-light form-control">
                                                            {{
                                                            employee.personalInformationResponse.otherInformation.emergencyInformation.name
                                                                | titlecase
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-name" class="font-weight-bold"
                                                        >{{ 'employee.phone' | translate }} :</label
                                                        >
                                                        <p class="badge-light form-control">
                                                            {{
                                                            employee.personalInformationResponse.otherInformation.emergencyInformation.phone
                                                                | titlecase
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="account-e-mail" class="font-weight-bold"
                                                        >{{ 'employee.degreeOfAffinity' | translate }} :</label
                                                        >
                                                        <p class="badge-light form-control">
                                                            {{
                                                            employee.personalInformationResponse.otherInformation.emergencyInformation
                                                                .degreeOfAffinity
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" ngbNavItem>
                            <a
                                    ngbNavLink
                                    class="nav-link d-flex py-75"
                                    id="account-pill-notifications"
                                    data-toggle="pill"
                                    href="#account-vertical-notifications"
                                    aria-expanded="false"
                            >
                                <i data-feather="folder" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold">{{ 'employee.personalDocument' | translate }}</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="media">
                                            <a href="javascript:void(0);" class="mr-25">
                                                <div *ngIf="!photo || photo === ''">
                                                    <img
                                                            sclass="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer image-fluid ml-sm-75"
                                                            height="90"
                                                            [src]="'/assets/images/avatars/user-avatar.png'"
                                                    />
                                                </div>
                                                <div *ngIf="photo && photo !== ''">
                                                    <img src="{{ photo }}" class="rounded mr-50" alt="profile image"
                                                         height="100"/>
                                                </div>
                                            </a>
                                        </div>
                                        <span>{{employee.name}} {{employee.surname}}</span>
                                    </div>
                                    <div class="content-wrapper">
                                        <div class="content-body">
                                            <section class="users-list-wrapper">
                                                <div class="card">
                                                    <ngx-datatable
                                                            [rows]="employeeModel.results.documents"
                                                            [rowHeight]="50"
                                                            class="bootstrap core-bootstrap"
                                                            [limit]="selectedOption"
                                                            [columnMode]="ColumnMode.force"
                                                            [headerHeight]="50"
                                                            [footerHeight]="50"
                                                            [scrollbarH]="true"
                                                    >
                                                        <ngx-datatable-column name="{{'document.title' | translate}}"
                                                                              [width]="50"
                                                        >
                                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                                {{ row.title }}
                                                            </ng-template>
                                                        </ngx-datatable-column
                                                        >
                                                        <ngx-datatable-column
                                                                name="{{'document.documentType' | translate}}"
                                                                [width]="50"
                                                        >
                                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                                <span class="ellipsis"> {{ row.documentType.title }}</span>
                                                            </ng-template>
                                                        </ngx-datatable-column
                                                        >
                                                        <ngx-datatable-column name="{{'general.actions' | translate}}"
                                                                              [width]="20">
                                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                                <label>
                                                                    <button
                                                                            type="button"
                                                                            class="btn btn-raised btn-outline-success btn-sm mr-50"
                                                                            (click)="downloadDocument(row.id)"
                                                                            rippleEffect
                                                                    >
                                                                        <span data-feather="download"></span>
                                                                    </button>
                                                                </label>
                                                            </ng-template>
                                                        </ngx-datatable-column>
                                                    </ngx-datatable>
                                                </div>
                                            </section>
                                        </div>

                                        <core-sidebar
                                                class="modal modal-slide-in sidebar-todo-modal fade"
                                                name="employee-view-sidebar"
                                                overlayClass="modal-backdrop"
                                        >
                                        </core-sidebar>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" ngbNavItem>
                            <a
                                    ngbNavLink
                                    class="nav-link d-flex py-75"
                                    id="assets"
                                    data-toggle="pill"
                                    href="#assets"
                                    aria-expanded="false"
                            >
                                <i data-feather="archive" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold">{{ 'asset.asset' | translate }}</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="media">
                                            <a href="javascript:void(0);" class="mr-25">
                                                <div *ngIf="!photo || photo === ''">
                                                    <img
                                                            sclass="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer image-fluid ml-sm-75"
                                                            height="90"
                                                            [src]="'/assets/images/avatars/user-avatar.png'"
                                                    />
                                                </div>
                                                <div *ngIf="photo && photo !== ''">
                                                    <img src="{{ photo }}" class="rounded mr-50" alt="profile image"
                                                         height="100"/>
                                                </div>
                                            </a>
                                        </div>
                                        <span>{{employee.name}} {{employee.surname}}</span>
                                    </div>
                                    <div class="content-wrapper">
                                        <div class="content-body">
                                            <section class="users-list-wrapper">
                                                <div class="card">
                                                    <ngx-datatable
                                                            [rows]="employeeModel.results.assetses"
                                                            [rowHeight]="50"
                                                            class="bootstrap core-bootstrap"
                                                            [limit]="selectedOption"
                                                            [columnMode]="ColumnMode.force"
                                                            [headerHeight]="50"
                                                            [footerHeight]="50"
                                                            [scrollbarH]="true"
                                                    >
                                                        <ngx-datatable-column prop="title"
                                                                              name="{{ 'asset.assetName' | translate }}"></ngx-datatable-column>
                                                        <ngx-datatable-column prop="identificationNumber"
                                                                              name="{{ 'asset.identificationNumber' | translate }}"></ngx-datatable-column>
                                                        <ngx-datatable-column
                                                                name="{{ 'asset.assetCategoryName' | translate }}">
                                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                                {{row.registeredItemCategory.title}}
                                                            </ng-template>
                                                        </ngx-datatable-column>
                                                    </ngx-datatable>
                                                </div>
                                            </section>
                                        </div>

                                        <core-sidebar
                                                class="modal modal-slide-in sidebar-todo-modal fade"
                                                name="employee-view2-sidebar"
                                                overlayClass="modal-backdrop"
                                        >
                                        </core-sidebar>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                </div>
                <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9"></div>
            </div>
        </section>
    </div>
</div>
