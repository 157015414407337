<div class="content-wrapper">
    <div class="content-body">
        <div class="app-calendar overflow-hidden border">
            <div class="row no-gutters">
                <div class="col position-relative">
                    <div class="card shadow-none border-0 mb-0 rounded-0">
                        <div class="card-body pb-0">
                            <full-calendar class="card-columns" [options]="calendarOptions"></full-calendar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
