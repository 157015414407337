import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardComponent} from './dashboard.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreDirectivesModule} from '@core/directives/directives';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {EmployeeModule} from '../employee/employee.module';
import {CalendarModule} from '../calendar/calendar.module';
import {SharedModule} from 'app/main/shared/shared.module';

@NgModule({
    declarations: [DashboardComponent],
    imports: [
        SharedModule,
        CommonModule,
        DashboardRoutingModule,
        NgApexchartsModule,
        NgbModule,
        CoreDirectivesModule,
        CalendarModule,
        EmployeeModule,
    ],
})
export class DashboardModule {
}
