<div class="input-group">
  <input
    class="form-control"
    placeholder="yyyy-mm-dd"
    name="dp"
    [minDate]="{ year: 1880, month: 1, day: 1 }"
    [maxDate]="maxDate"
    [formlyAttributes]="field"
    [formControl]="formControl"
    (click)="d.toggle()"
    ngbDatepicker
    #d="ngbDatepicker"
  />
  <div class="input-group-append">
    <button class="btn btn-outline-secondary fa fa-calendar" (click)="d.toggle()" type="button"></button>
  </div>
</div>
