import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {EmployeeEntryModel, EntryType} from '../shared/models/employee-entry.model';
import * as _ from 'lodash';

@Component({
    selector: 'app-employee-entries-table',
    templateUrl: './employee-entries-table.component.html',
    styleUrls: ['./employee-entries-table.component.scss']
})
export class EmployeeEntriesTableComponent implements OnInit {

    constructor() {
    }

    @Input('entries') employeeEntries: { day: string, entries: EmployeeEntryModel[] }[];
    @ViewChild('tableRowDetails') tableRowDetails: any;
    public ColumnMode = ColumnMode;

    ngOnInit(): void {
    }

    getEntryType(type: EntryType) {
        return `remainingWork.${EntryType[type]}`;
    }

    rowDetailsToggleExpand(row) {
        this.tableRowDetails.rowDetail.toggleExpandRow(row);
    }

    getFirstEntryOrLastExit(entries: EmployeeEntryModel[], isFirstEntry: boolean): EmployeeEntryModel {
        if (isFirstEntry) {
            return _.find(entries, x => x.entryType === EntryType.ENTRANCE || x.entryType === EntryType.MANUEL_ENTRANCE);
        } else {
            return _.findLast(entries, x => x.entryType === EntryType.EXIT || x.entryType === EntryType.MANUEL_EXIT);
        }
    }

}
