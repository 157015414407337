import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';
import * as signalR from '@microsoft/signalr';
import {HubConnection} from '@microsoft/signalr';
import {NotificationResponseModel} from '../../../../main/components/notification/shared/model/notification-response.model';
import {environment} from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    // Public
    public onNotificationDataChange: BehaviorSubject<NotificationResponseModel>;
    public connection: HubConnection;


    /**
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient) {
        this.onNotificationDataChange = new BehaviorSubject(null);
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.apiUrl}ws/notifications-service/notificationhub`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            }).build();
        this.startGetNotifications();
    }

    /**
     * Get Notifications Data
     */
    startGetNotifications() {
        this.connection.start().then(() => {
            this.connection.on('notification', (data) => {
                this.onNotificationDataChange.next(data as NotificationResponseModel);
            });
        });
    }
}
