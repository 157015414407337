<div class="document-editor">
    <p class="p-1">{{editorHeader}}</p>
    <div class="document-editor__toolbar"></div>
    <div class="document-editor__editable-container">
        <ckeditor [disabled]="isDisabled" #editor class="document-editor__editable" [(ngModel)]="editorContent"
                  [editor]="Editor"
                  (ready)="onReady($event)">
        </ckeditor>
    </div>
</div>


