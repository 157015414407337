import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TextboxControl} from 'app/layout/components/filter/interfaces/textbox.control';
import {BaseListComponent} from 'app/main/shared/base/components/base-list-component';
import {PageFilter} from 'app/main/shared/base/models/pageFilter.model';
import Swal from 'sweetalert2';
import {CardDataService} from './data/card-data.service';
import {CardDataModel} from './data/model/card-data.model';

@Component({
    selector: 'app-card-data',
    templateUrl: './card-data.component.html',
    styleUrls: ['./card-data.component.scss'],
})
export class CardDataComponent extends BaseListComponent<CardDataModel, CardDataModel> implements OnInit {
    public cardInformation: CardDataModel = {} as CardDataModel;
    cardControlForm = this.fb.group({
        fullName: ['', Validators.required],
        userId: ['', Validators.required],
        cardNumber: ['', Validators.required]
    });
    cardControlEditForm = this.fb.group({
        fullName: ['', Validators.required],
        userId: ['', Validators.required],
        cardNumber: ['', Validators.required]
    });

    constructor(
        _coreSidebarService: CoreSidebarService,
        public _cardDataService: CardDataService,
        public modalService: NgbModal,
        private fb: FormBuilder
    ) {
        super(_cardDataService, _coreSidebarService);
        this.pageConfig = {
            pageKey: 'EmployeeList',
            filterSidebarKey: 'employee-list-filter-sidebar',
        };

        this.filterConfig = [
            {
                class: 'row',
                formGroups: [
                    {
                        class: 'col-6',
                        control: new TextboxControl({
                            key: 'FullName',
                            label: 'Card User Name',
                        }),
                    },
                    {
                        class: 'col-6',
                        control: new TextboxControl({
                            key: 'cardNumber',
                            label: 'Card Number',
                        }),
                    },
                ],
            },
        ];
    }

    ngOnInit(): void {
    }

    addEmployeeAndCardId(dismiss: any) {
        if (this.cardControlForm.valid) {
            this._cardDataService.post(this.cardControlForm.value).subscribe((res) => {
                if (res.results) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Added!',
                        text: 'Card has been added.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    });
                    this.dataService.dataSubject$.next(null);
                    dismiss();
                }
            });
        }
    }

    exportexcel(rows, name): void {
        const paging: PageFilter = JSON.parse(JSON.stringify(this.pageFilter));
        paging.count = 1000;
        paging.index = 1;
    }

    modalOpenDanger(modalDanger) {
        this.modalService.open(modalDanger, {
            centered: true,
            windowClass: 'modal modal-danger',
        });
    }

    modalOpenEdit(modal, id) {
        this.dataService.dataList$.subscribe((res) => {
            const card = res.results.find((x) => x.userId == id);
            this.cardControlEditForm.patchValue(card);
            this.cardInformation = card;
        });
        this.modalService.open(modal, {
            centered: true,
            windowClass: 'modal modal-danger',
        });
    }

    deleteCard = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ff9f43',
            cancelButtonColor: '#ee5818',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.value) {
                this._cardDataService.deleteApi(id).subscribe((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Deleted!',
                        text: 'Card has been deleted.',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    });
                    this.dataService.dataSubject$.next(null);
                });
            }
        });
    };

    editCard = (dismiss) => {
        if (this.cardControlEditForm.valid) {
            this.cardInformation = this.cardControlEditForm.value;
            this._cardDataService.put(this.cardInformation.userId.toString(), this.cardInformation).subscribe((res) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Edited!',
                    text: 'Card has been edited.',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                });
                this.dataService.dataSubject$.next(null);
                dismiss();
            });
        }
    };
}
