import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'app/main/shared/base/services/base.service';
import { EmployeeRequest } from '../../employee/data/models/employee-request.model';
import { RemainingWorkDTO } from '../../remaining-work/shared/models/remaining-work-response.model';
import { CardDataModel } from './model/card-data.model';

@Injectable({
  providedIn: 'root',
})
export class CardDataService extends BaseService<CardDataModel, CardDataModel> {
  constructor(private http: HttpClient) {
    super(http, 'employee-service/employee-entries/cards');
  }
}
