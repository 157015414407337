<div class="content-wrapper">
  <div class="content-body">
    <section class="app-user-edit">
      <div class="">
        <div class="">
          <ul ngbNav #nav="ngbNav" class="nav nav-pills">
            <li ngbNavItem>
              <ng-template ngbNavContent>
                <div class="active" id="account" aria-labelledby="account-tab" role="tabpanel">
                  <form class="form-validate">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="username">E-mail</label>
                          <input
                            type="email"
                            class="form-control"
                            name="username"
                            [(ngModel)]="user.username"
                            id="username"
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="role">Role</label>
                          <select class="form-control" [(ngModel)]="user.userRoleId" name="role" id="role">
                            <option *ngIf="_authService.hasRole([1])" value="{{ roleEnum.SystemAdmin }}">System Admin</option>
                            <option *ngIf="_authService.hasRole([1])" value="{{ roleEnum.HrManager }}">HR Manager</option>
                            <option value="{{ roleEnum.User }}">User</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-4 d-flex flex-sm-row flex-column mt-2">
                        <button
                          (click)="addUser()"
                          type="submit"
                          class="btn btn-primary mb-1 mr-0 mr-sm-1"
                          rippleEffect
                        >
                          Save User
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
      <ng-container *ngFor="let row of rows"> </ng-container>
    </section>
  </div>
</div>
