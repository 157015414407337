import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseListResponse, BaseResponse } from 'app/main/data/base-response.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { getPageAndSizeParams, getRequestParams } from '../../utils';
import { ApiResponse } from '../api-response.model';
import { GetAllParametres, PageFilter } from '../models/pageFilter.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService<TRequest, TResponse> {
  constructor(protected httpClient: HttpClient, protected url: string) {}
  /** API Get request */
  getApi(id?: string): Observable<BaseResponse<TResponse>> {
    if (id) {
      return this.httpClient.get<BaseResponse<TResponse>>(`${environment.apiUrl}${this.url}/${id}`);
    }
    return this.httpClient.get<BaseResponse<TResponse>>(`${environment.apiUrl}${this.url}`);
  }
  /** API Get request for list */
  getAllApi(pageFilter: PageFilter): Observable<BaseListResponse<TResponse>> {
    return this.httpClient.get<BaseListResponse<TResponse>>(`${environment.apiUrl}${this.url}`, {
      params: pageFilter ? getPageAndSizeParams(pageFilter) : {},
    });
  }
  /** API Post request */
  postApi(createObject?: TRequest): Observable<BaseResponse<TResponse>> {
    return this.httpClient.post<BaseResponse<TResponse>>(`${environment.apiUrl}${this.url}`, createObject);
  }

  /** API Put request */
  putApi(id?: string, updateObject?: TRequest): Observable<BaseResponse<TResponse>> {
    return this.httpClient.put<BaseResponse<TResponse>>(`${environment.apiUrl}${this.url}/${id}`, updateObject);
  }
  /** API Delete request */
  deleteApi(id?: string): Observable<BaseResponse<TResponse>> {
    return this.httpClient.delete<BaseResponse<TResponse>>(`${environment.apiUrl}${this.url}/${id}`);
  }
}
