<form [formGroup]="filterForm" (ngSubmit)="filter()">
    <div [ngClass]="row.class" *ngFor="let row of config">
        <div class="form-group" [ngClass]="group.class" *ngFor="let group of row.formGroups">
            <label [attr.for]="group.control.key" [ngClass]="group.control.labelClass">{{ group.control.label }}</label>
            <div [ngSwitch]="group.control.controlType">
                <input
                        *ngSwitchCase="'textbox'"
                        [placeholder]="group.control.placeholder"
                        [mask]="group.control.mask"
                        [formControlName]="group.control.key"
                        [id]="group.control.key"
                        class="form-control"
                        [ngClass]="group.control.class"
                        [type]="group.control.type"
                />

                <select
                        [id]="group.control.key"
                        *ngSwitchCase="'dropdown'"
                        class="form-control"
                        [ngClass]="group.control.class"
                        [formControlName]="group.control.key"
                >
                    <option *ngFor="let opt of group.control.options" [value]="opt.key">
                        {{ opt.value }}
                    </option>
                </select>

                <ng-select
                        [id]="group.control.key"
                        *ngSwitchCase="'ngselect'"
                        [formControlName]="group.control.key"
                        bindValue="{{ group.control.bindValue }}"
                        bindLabel="{{ group.control.bindLabel }}"
                        class="custom"
                        [searchable]="true"
                        [multiple]="group.control.multiple"
                        [items]="group.control.observable | async"
                >
                </ng-select>

                <ng-select
                        [id]="group.control.key"
                        *ngSwitchCase="'ngselectstatic'"
                        [formControlName]="group.control.key"
                        bindValue="{{ group.control.bindValue }}"
                        bindLabel="{{ group.control.bindLabel }}"
                        [multiple]="group.control.multiple"
                        class="custom"
                        [searchable]="true"
                >
                    <ng-option *ngFor="let opt of group.control.options" [value]="opt.value">
                        {{ opt.key }}
                    </ng-option>
                </ng-select>

                <div class="input-group" *ngSwitchCase="'datepicker'">
                    <input
                            [id]="group.control.key"
                            class="form-control"
                            [formControlName]="group.control.key"
                            placeholder="dd-mm-yyyy"
                            name="Date picker"
                            ngbDatepicker
                            #datePicker="ngbDatepicker"
                    />
                    <div class="input-group-append">
                        <button
                                class="btn btn-outline-secondary feather icon-calendar"
                                (click)="datePicker.toggle()"
                                type="button"
                                rippleEffect
                        ></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 d-flex justify-content-end">
            <button type="reset" class="btn btn-outline-secondary mt-1 mr-1" (click)="resetFilter()" rippleEffect>
                Reset
            </button>
            <button type="submit" class="btn btn-primary mt-1" rippleEffect>Filter</button>
        </div>
    </div>
</form>
