import {AfterViewChecked, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {FlatpickrOptions} from 'ng2-flatpickr';
import {EmergencyInformation, EmployeeRequest} from '../data/models/employee-request.model';
import {UserRequest} from '../../user/data/models/user.model';
import {EmployeeResponse, OtherInformation} from '../data/models/employee.model';
import {BaseResponse} from 'app/main/data/base-response.model';
import {EmployeeService} from '../employee.service';
import {mergeMap, tap} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Stepper from 'bs-stepper';
import {Role} from '../../user/data/enums/role.enum';
import {Gender} from '../data/enums/gender.enum';
import {BloodGroup} from '../data/enums/bloodType.enum';
import {ContractType} from '../data/enums/contract.enum';
import {EducationLevel} from '../data/enums/educationLevel.enum';
import {EducationStatus} from '../data/enums/educationStatus.enum';
import {EmployeeRole} from '../data/enums/employeeRole.enum';
import {MaritalStatus} from '../data/enums/maritalStatus.enum';
import {generateModel} from 'app/main/shared/utils';
import {FileUploaderComponent} from '../../file-uploader/file-uploader.component';
import {DocumentService} from '../../document/shared/document.service';
import {DocumentModel} from '../../document/shared/document.mode';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {downloadFile} from 'app/main/shared/functions/downloadFile';
import {formatEmployeePhoto} from 'app/main/shared/functions/getEmployeePhoto';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import {Military} from '../data/enums/militarystatus.enum';
import {ToastService} from '../../../../../@core/services/toast.service';

@Component({
    selector: 'app-employee-edit',
    templateUrl: './employee-edit.component.html',
    styleUrls: ['./employee-edit.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EmployeeEditComponent implements OnInit, AfterViewChecked {
    @ViewChild(FileUploaderComponent) fileUploaderComponent: FileUploaderComponent;

    public employee: EmployeeResponse = {} as EmployeeResponse;
    public employee$: Observable<BaseResponse<EmployeeResponse>>;
    public employeeRequest: EmployeeRequest = {} as EmployeeRequest;
    public emergency: EmergencyInformation = {} as EmergencyInformation;
    public other: OtherInformation = {} as OtherInformation;
    public user: UserRequest = {} as UserRequest;
    public militaryEnum: any = Military;
    model: EmployeeRequest;
    employeeForm: FormGroup;
    // Public
    public url = this.router.url;
    public urlLastValue;
    private horizontalWizardStepper: Stepper;
    public profilePhotoPath: string;
    public photo: any;
    public genderEnum: any = Gender;
    public roleEnum: any = Role;
    public bloodTypeEnum: any = BloodGroup;
    public contractEnum: any = ContractType;
    public educationLevelEnum: any = EducationLevel;
    public educationStatusEnum: any = EducationStatus;
    public employeeRoleEnum: any = EmployeeRole;
    public maritalStatusEnum: any = MaritalStatus;
    private id: number;
    public documentsList: Array<any> = [];
    public rows: DocumentModel[] = [];
    public selectedOption = 10;
    public ColumnMode = ColumnMode;
    public birthDateOptions: FlatpickrOptions = {
        altInput: true,
    };

    public selectMultiLanguagesSelected = [];

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param _toastrService
     * @param fb
     * @param {Router} router
     * @param _employeeService
     * @param activatedRoute
     * @param _documentService
     * @param formatter
     */
    constructor(
        private _toastrService: ToastService,
        private fb: FormBuilder,
        private router: Router,
        private _employeeService: EmployeeService,
        private activatedRoute: ActivatedRoute,
        private _documentService: DocumentService,
        public formatter: NgbDateParserFormatter,
    ) {
        this._unsubscribeAll = new Subject();
        this.urlLastValue = this.url.substr(this.url.lastIndexOf('/') + 1);
    }

    ngAfterViewChecked(): void {
        if (document.querySelector('#stepper1') && !this.horizontalWizardStepper) {
            this.horizontalWizardStepper = new Stepper(document.querySelector('#stepper1'), {});
        }
    }

    ngOnInit(): void {
        this.employee$ = this.activatedRoute.params.pipe(
            mergeMap((data) => {
                this.id = data.id;
                return this._employeeService.getEmployeeDetail(data.id);
            }),
            tap((data) => {
                this.employee = data.results;
                this.documentsList = data.results.documents;

                this._employeeService.getPhoto(this.employee.id).subscribe((res) => {
                    if (res) {
                        formatEmployeePhoto(res).subscribe((photo) => {
                            this.profilePhotoPath = photo;
                        });
                    }
                });

                this.employeeForm.patchValue({
                    ...data.results,
                    cardId: data.results.card?.userId,
                    gender: data.results.personalInformationResponse.gender,
                    identificationNumber: data.results.personalInformationResponse.identificationNumber,
                    dateOfBirth: this.formatter.parse(data.results.personalInformationResponse.dateOfBirth?.toString()),
                    dateOfStart: this.formatter.parse(data.results.dateOfStart?.toString()),
                    contractEndDate: this.formatter.parse(data.results.contractEndDate?.toString()),
                    educationStatus: data.results.personalInformationResponse.educationStatus,
                    educationalInstitution: data.results.personalInformationResponse.educationalInstitution,
                    educationLevel: data.results.personalInformationResponse.educationLevel,
                    maritalStatus: data.results.personalInformationResponse.maritalStatus,
                    bloodGroup: data.results.personalInformationResponse.bloodGroup,
                    numberOfChildren: data.results.personalInformationResponse.numberOfChildren,
                    address1: data.results.personalInformationResponse.otherInformation.address1,
                    address2: data.results.personalInformationResponse.otherInformation.address2,
                    bankName: data.results.personalInformationResponse.otherInformation.bankName,
                    accountNumber: data.results.personalInformationResponse.otherInformation.accountNumber,
                    iban: data.results.personalInformationResponse.otherInformation.iban,
                    emergencyName: data.results.personalInformationResponse.otherInformation.emergencyInformation.name,
                    phone: data.results.personalInformationResponse.otherInformation.emergencyInformation.phone,
                    degreeOfAffinity:
                    data.results.personalInformationResponse.otherInformation.emergencyInformation.degreeOfAffinity,
                });
            })
        );

        this.employeeForm = this.fb.group({
            name: ['', [Validators.required, Validators.pattern('^[a-zA-Z -\']+')]],
            surname: ['', Validators.required],
            cardId: ['', Validators.required],
            username: ['', [Validators.required, Validators.email]],
            role: ['', Validators.required],
            companyMailAddress: ['', Validators.required],
            title: ['', Validators.required],
            profilePhotoPath: ['', Validators.required],
            personalMailAddress: ['', Validators.required],
            companyPhoneNumber: ['', [Validators.required]],
            personalPhoneNumber: ['', [Validators.required, Validators.email]],
            dateOfStart: ['', Validators.required],
            contractType: ['', [Validators.required, Validators.email]],
            contractEndDate: ['', [Validators.required, Validators.email]],
            dateOfBirth: ['', [Validators.required]],
            identificationNumber: ['', Validators.required],
            maritalStatus: ['', Validators.required],
            gender: ['', Validators.required],
            numberOfChildren: ['', Validators.required],
            bloodGroup: ['', Validators.required],
            educationStatus: ['', Validators.required],
            educationLevel: ['', Validators.required],
            educationalInstitution: ['', Validators.required],
            nationalityId: ['', Validators.required],
            emergencyInformation: ['', Validators.required],
            otherInformation: ['', Validators.required],
            companyId: ['', Validators.required],
            branchId: ['', Validators.required],
            departmentId: ['', Validators.required],
            userId: ['', Validators.required],
            maximumLeaveDay: ['', Validators.required],
            phone: ['', Validators.required],
            degreeOfAffinity: ['', Validators.required],
            emergencyName: ['', Validators.required],
            address1: ['', Validators.required],
            address2: ['', Validators.required],
            bankName: ['', Validators.required],
            accountNumber: ['', Validators.required],
            iban: ['', Validators.required],
            militaryService: ['']
        });
    }

    get f() {
        return this.employeeForm.controls;
    }

    horizontalWizardStepperNext(form) {
        let isSuccess = true;
        const personalFormRequiredFields = ['name', 'surname', 'dateOfBirth', 'identificationNumber', 'educationStatus', 'educationLevel', 'educationalInstitution'];
        const companyFormRequiredFields = ['companyMailAddress', 'title', 'dateOfStart', 'contractType'];
        if (form === 'personal') {
            personalFormRequiredFields.forEach(field => {
                if (!this.employeeForm.controls[field].value) {
                    isSuccess = false;
                }
            });
        } else if (form === 'company') {
            companyFormRequiredFields.forEach(field => {
                if (!this.employeeForm.controls[field].value) {
                    isSuccess = false;
                }
            });
        }
        if (isSuccess) {
            this.horizontalWizardStepper.next();
        }
    }

    horizontalWizardStepperPrevious() {
        this.horizontalWizardStepper.previous();
    }

    addDocument(document: DocumentModel): void {
        document.employeeId = this.employee.id;
        this._documentService.addDocuments(document).subscribe((res) => {
        });
    }

    submit(): void {
        const updateEmployee = generateModel(this.employeeForm as FormGroup, this.id) as EmployeeRequest;
        updateEmployee.userId = this.employee?.userResponse?.id;
        updateEmployee.branchId = this.employee.branchResponse.id;
        updateEmployee.profilePhotoContentType = this.employee.profilePhotoContentType;
        updateEmployee.profilePhotoPath = this.employee.profilePhotoPath;
        updateEmployee.otherInformation = {
            accountNumber: this.f.accountNumber.value,
            address1: this.f.address1.value,
            address2: this.f.address2.value,
            bankName: this.f.bankName.value,
            iban: this.f.iban.value,
        };
        updateEmployee.emergencyInformation = {
            degreeOfAffinity: this.f.degreeOfAffinity.value,
            name: this.f.emergencyName.value,
            phone: this.f.phone.value,
        };
        updateEmployee.dateOfBirth = moment(this.formatter.format(this.employeeForm.controls.dateOfBirth.value)).add(3, 'hours').toDate();
        updateEmployee.dateOfStart = moment(this.formatter.format(this.employeeForm.controls.dateOfStart.value)).add(3, 'hours').toDate();
        updateEmployee.contractEndDate = moment(this.formatter.format(this.employeeForm.controls.contractEndDate.value))
            .add(3, 'hours').toDate();
        updateEmployee.nationalityId = 1;
        updateEmployee.companyId = this.employee.branchResponse.companyId;
        updateEmployee.departmentId = this.employee.departmentResponse.id;
        this._employeeService.editEmployee(updateEmployee, this.id).subscribe(
            () => {
                if (this.employee.card != null) {
                    const card = {
                        employeeId: this.employee.id,
                        fullName: this.employee.name + ' ' + this.employee.surname,
                        userId: this.employeeForm.controls.cardId?.value,
                        cardNumber: this.employee.card.cardNumber
                    };
                    if (card.userId) {
                        this._employeeService.changeCard(card).subscribe();
                    }
                }
                this.router.navigate(['employees']).then(() =>
                    this._toastrService.success('Customer successfully updated!', 'Success', {
                        toastClass: 'toast ngx-toastr',
                        closeButton: true,
                    })
                );
            },
            (err) => {
                this._toastrService.error(err.error.message, 'Error', {
                    toastClass: 'toast ngx-toastr',
                    closeButton: true,
                });
            }
        );
    }

    onFileChanged(event) {
        const file = event.target.files[0];
        this.photo = file;
        const reader = new FileReader();
        reader.onload = () => {
            this.profilePhotoPath = reader.result as string;
        };
        reader.readAsDataURL(file);
    }

    uploadPhoto() {
        const uploadData = new FormData();
        uploadData.append('files', this.photo, this.photo.name);
        this._employeeService.uploadPhoto(uploadData).subscribe((res) => {
            if (res && res.results) {
                this.employee.profilePhotoPath = res.results.path;
                this.employee.profilePhotoContentType = res.results.contentType;
                this._toastrService.success('Employee photo successfully uploaded. To complete this process you have to submit this form.', 'Photo Uploaded');
            } else {
                this._toastrService.success(res.message, 'Error');
            }
        });
    }

    downloadDocument(id: number) {
        const document = this.documentsList.find((d) => d.id === id);
        this._documentService.downloadDocuments(id).subscribe((res) => {
            downloadFile(res, document.path);
        });
    }
}
