import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
	selector: 'formly-field-button',
	template: `
		<button class="{{ to.class }}" (click)="to.method($event)">
			{{ to.placeholder }}
		</button>
	`,
})
export class FormlyFieldButton extends FieldType {}
