<div class="content-wrapper" *ngIf="_cardDataService.dataList$ | async as rowData">
    <div class="content-body">
        <section class="users-list-wrapper">
            <core-card
                    [actions]="['collapse', 'expand', 'filter', 'add']"
                    (addEvent)="modalOpenDanger(modalDanger)"
                    [sidebarKey]="pageConfig.filterSidebarKey"
            >
                <div class="card-title"></div>
                <div class="card-body">
                    <ngx-datatable
                            class="bootstrap core-bootstrap"
                            [columnMode]="listConfig.columnMode"
                            [headerHeight]="listConfig.headerHeight"
                            [rowHeight]="listConfig.rowHeight"
                            [footerHeight]="listConfig.footerHeight"
                            [limit]="listConfig.limit"
                            [rows]="rowData.results"
                            (sort)="onSort($event)"
                            [count]="rowData.totalCount"
                            [offset]="page.offset"
                            (page)="setPage($event)"
                            [externalPaging]="listConfig.externalPaging"
                            [externalSorting]="listConfig.externalSorting"
                            [scrollbarH]="listConfig.scrollbarH"
                            [scrollbarV]="listConfig.scrollbarV"
                            [reorderable]="listConfig.reorderable"
                    >
                        <ngx-datatable-column name="{{ 'employee.cardUserName' | translate }}" prop="fullName"
                                              [width]="50">
                        </ngx-datatable-column>

                        <ngx-datatable-column name="{{ 'employee.cardUserId' | translate }}" prop="userId" [width]="75">
                        </ngx-datatable-column>

                        <ngx-datatable-column name="{{ 'employee.cardId' | translate }}" prop="cardNumber" [width]="75">
                        </ngx-datatable-column>

                        <ngx-datatable-column name="{{ 'employee.linkedEmployee' | translate }}" prop="employee"
                                              [width]="75">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                {{ row.employee ? row.employee?.name + ' ' + row.employee?.surname : '-' }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{ 'general.actions' | translate }}" [width]="30"
                                              [sortable]="false">
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <div ngbDropdown container="body">
                                    <button ngbDropdownToggle type="button" class="btn icon-btn btn-sm hide-arrow"
                                            rippleEffect>
                                        <span [data-feather]="'more-vertical'" [class]="'cursor-pointer'"></span>
                                    </button>
                                    <div ngbDropdownMenu>
                                        <a (click)="modalOpenEdit(modalEdit, row.userId)" ngbDropdownItem
                                        ><span [data-feather]="'edit'" [size]="16" [class]="'mr-50'"></span>
                                            {{ 'general.edit' | translate }}</a
                                        >
                                        <a href="javascript:;" ngbDropdownItem (click)="deleteCard(row.userId)"
                                        ><span [data-feather]="'trash'" [size]="16" [class]="'mr-50'"></span>
                                            {{ 'general.delete' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </core-card>
        </section>
    </div>
    <ng-template #modalDanger let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="myModalLabel160">{{ 'employee.addCardAndEmployee' | translate }}</h5>
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="cardControlForm" (ngSubmit)="addEmployeeAndCardId()">
            <div class="row">
                <div class="col-11 ml-1">
                    <div class="form-group">
                        <label for="largeInput">{{ 'employee.cardUserId' | translate }}</label>
                        <input class="form-control" [formControlName]="'userId'" type="number"/>
                    </div>
                </div>
                <div class="col-11 ml-1">
                    <div class="form-group">
                        <label for="largeInput">{{ 'forms.employeeName' | translate }}</label>
                        <input class="form-control" [formControlName]="'fullName'" type="text"/>
                    </div>
                </div>
                <div class="col-11 ml-1">
                    <div class="form-group">
                        <label for="largeInput">{{ 'employee.cardId' | translate }}</label>
                        <input class="form-control" [formControlName]="'cardNumber'" type="text"/>
                    </div>
                </div>
            </div>
        </form>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" rippleEffect (click)="addEmployeeAndCardId(modal.dismiss)">
                {{ 'general.submit' | translate }}
            </button>
        </div>
    </ng-template>
    <ng-template #modalEdit let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="myModalLabel160">{{ 'employee.addCardAndEmployee' | translate }}</h5>
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="cardControlEditForm" (ngSubmit)="editCard()">
            <div class="row">
                <div class="col-11 ml-1">
                    <div class="form-group">
                        <label for="largeInput">{{ 'employee.cardUserId' | translate }}</label>
                        <input class="form-control" disabled="true" [formControlName]="'userId'" type="number"/>
                    </div>
                </div>
                <div class="col-11 ml-1">
                    <div class="form-group">
                        <label for="largeInput">{{ 'forms.employeeName' | translate }}</label>
                        <input class="form-control" [formControlName]="'fullName'" type="text"/>
                    </div>
                </div>
                <div class="col-11 ml-1">
                    <div class="form-group">
                        <label for="largeInput">{{ 'employee.cardId' | translate }}</label>
                        <input class="form-control" [formControlName]="'cardNumber'" type="text"/>
                    </div>
                </div>
            </div>
        </form>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" rippleEffect (click)="editCard(modal.dismiss)">
                {{ 'general.submit' | translate }}
            </button>
        </div>
    </ng-template>
    <app-filter-sidebar [sidebarKey]="pageConfig.filterSidebarKey" (filterEmit)="toggleFilter()">
        <app-filter [config]="filterConfig" (emitFilter)="setFilter($event)" (emitResetFilter)="resetFilters()">
        </app-filter
        >
    </app-filter-sidebar>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="card-data-sidebar"
            overlayClass="modal-backdrop"
    >
    </core-sidebar>
</div>
