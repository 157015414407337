import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreSidebarModule} from '@core/components';
import {CoreCardModule} from '@core/components/core-card/core-card.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormlyBootstrapModule} from '@ngx-formly/bootstrap';
import {FormlyModule} from '@ngx-formly/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FilterSidebarComponent} from 'app/layout/components/filter/filter-sidebar/filter-sidebar.component';
import {FilterComponent} from 'app/layout/components/filter/filter.component';
import {Ng2FlatpickrModule} from 'ng2-flatpickr';
import {FormlyFieldButton} from '../components/formly/button/button.component';
import {DateTimePickerComponent} from '../components/formly/date-time-picker/date-time-picker.component';
import {DatepickerComponent} from '../components/formly/datepicker/datepicker.component';
import {MaskedDateInputComponent} from '../components/formly/formly-custom-fields/masked-date-input.component';
import {FormlyFieldNgSelectComponent} from '../components/formly/select/select.component';
import {UserAddComponent} from '../components/user/user-add/user-add.component';
import {CoreCommonModule} from '@core/common.module';
import {CorePipesModule} from '@core/pipes/pipes.module';
import {CoreDirectivesModule} from '@core/directives/directives';
import {
    earlierThanToday,
    emailValidator,
    fieldMatchValidator,
    nameValidator,
    onlyIpAddressBodyValidator,
    onlyIpAddressRangeValidator,
    onlyIpAddressValidator,
    onlyLetterValidator,
    onlyNumberValidator,
    passwordValidator,
    rangeValidator,
} from './validators/validator';
import {
    earlierThanTodayMessage,
    emailValidatorMessage,
    nameValidatorMessage,
    onlyIpAddressBodyValidatorMessage,
    onlyIpAddressRangeValidatorMessage,
    onlyIpAddressValidatorMessage,
    onlyLetterValidatorMessage,
    onlyNumberValidatorMessage,
    passwordValidatorMessage,
    rangeValidatorMessage,
    requiredValidatorMessage,
} from './validators/validator-message';
import {FileUploaderModule} from '../components/file-uploader/file-uploader.module';
import {TranslateModule} from '@ngx-translate/core';
import {EmployeeTimeTrackerComponent} from '../components/employee-time-tracker/employee-time-tracker.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {NgxMaskModule} from 'ngx-mask';
import {EmployeeEntriesModalComponent} from '../components/remaining-work/employee-entries-modal/employee-entries-modal.component';
import {EditorModule} from '../components/editor/editor.module';
import {EmployeeEntriesTableComponent} from '../components/remaining-work/employee-entries-table/employee-entries-table.component';
import {MemoizePipe} from './pipes';

@NgModule({
    declarations: [
        DateTimePickerComponent,
        DatepickerComponent,
        FormlyFieldNgSelectComponent,
        MaskedDateInputComponent,
        FormlyFieldButton,
        FilterComponent,
        FilterSidebarComponent,
        UserAddComponent,
        EmployeeTimeTrackerComponent,
        EmployeeEntriesModalComponent,
        EmployeeEntriesTableComponent,
        MemoizePipe
    ],
    imports: [
        TranslateModule,
        FileUploaderModule,
        NgxDatatableModule,
        CoreSidebarModule,
        CoreCardModule,
        NgSelectModule,
        FormsModule,
        Ng2FlatpickrModule,
        NgbModule,
        CoreCommonModule,
        CorePipesModule,
        CoreDirectivesModule,
        FormlyBootstrapModule,
        ReactiveFormsModule,
        CommonModule,
        FormlyModule.forRoot({
            extras: {lazyRender: true},
            types: [
                {
                    name: 'date',
                    component: DatepickerComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'select',
                    component: FormlyFieldNgSelectComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'date-time',
                    component: DateTimePickerComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'masked-date-input',
                    component: MaskedDateInputComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'button',
                    component: FormlyFieldButton,
                    wrappers: ['form-field'],
                },
            ],
            validators: [
                {name: 'name', validation: nameValidator},
                {name: 'email', validation: emailValidator},
                {name: 'onlyLetter', validation: onlyLetterValidator},
                {name: 'onlyNumber', validation: onlyNumberValidator},
                {name: 'onlyIpAddress', validation: onlyIpAddressValidator},
                {name: 'onlyIpAddressBody', validation: onlyIpAddressBodyValidator},
                {name: 'onlyIpAddressRange', validation: onlyIpAddressRangeValidator},
                {name: 'earlierThanToday', validation: earlierThanToday},
                {name: 'range', validation: rangeValidator},
                {name: 'password', validation: passwordValidator},
                {name: 'fieldMatch', validation: fieldMatchValidator},
            ],

            validationMessages: [
                {name: 'name', message: nameValidatorMessage},
                {name: 'email', message: emailValidatorMessage},
                {name: 'onlyLetter', message: onlyLetterValidatorMessage},
                {name: 'onlyNumber', message: onlyNumberValidatorMessage},
                {name: 'onlyIpAddress', message: onlyIpAddressValidatorMessage},
                {name: 'onlyIpAddressBody', message: onlyIpAddressBodyValidatorMessage},
                {name: 'onlyIpAddressRange', message: onlyIpAddressRangeValidatorMessage},
                {name: 'earlierThanToday', message: earlierThanTodayMessage},
                {name: 'range', message: rangeValidatorMessage},
                {name: 'password', message: passwordValidatorMessage},
                {name: 'required', message: requiredValidatorMessage},
                {name: 'fieldMatch', message: 'Passwords should match'},
            ],
        }),
        NgApexchartsModule,
        NgxMaskModule,
        EditorModule
    ],
    exports: [
        NgSelectModule,
        EditorModule,
        FormsModule,
        FormlyModule,
        CoreCardModule,
        CoreCommonModule,
        CorePipesModule,
        CoreDirectivesModule,
        EmployeeTimeTrackerComponent,
        DateTimePickerComponent,
        DatepickerComponent,
        CoreSidebarModule,
        MaskedDateInputComponent,
        FormlyFieldButton,
        ReactiveFormsModule,
        NgbModule,
        FormlyBootstrapModule,
        CommonModule,
        Ng2FlatpickrModule,
        FilterComponent,
        FilterSidebarComponent,
        NgxDatatableModule,
        UserAddComponent,
        NgxDatatableModule,
        FileUploaderModule,
        EmployeeEntriesModalComponent,
        MemoizePipe
    ],
    entryComponents: [DateTimePickerComponent, EmployeeEntriesTableComponent],
})
export class SharedModule {
}
