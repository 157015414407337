import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function nameValidator(control: FormControl): ValidationErrors {
  return /^([^0-9]*)$/.test(control.value) ? null : { name: true };
}

export function emailValidator(control: FormControl): ValidationErrors {
  if (control.value === null) {
    return null;
  }
  return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(control.value) ? null : { email: true };
}

export function onlyLetterValidator(control: FormControl): ValidationErrors {
  return /\d/.test(control.value) ? { onlyLetter: false } : null;
}

export function onlyNumberValidator(control: FormControl): ValidationErrors {
  return /^[0-9]*$/.test(control.value) ? null : { onlyNumber: false };
}

export function onlyIpAddressValidator(control: FormControl): ValidationErrors {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    control.value
  )
    ? null
    : { onlyIpAddress: false };
}

export function onlyIpAddressBodyValidator(control: FormControl): ValidationErrors {
  return /^\d+\.\d+\.\d+$/.test(control.value) ? null : { onlyIpAddressBody: false };
}

export function onlyIpAddressRangeValidator(control: FormControl): ValidationErrors {
  return /^\d+-\d+$/.test(control.value) ? null : { onlyIpAddressRange: false };
}

export function rangeValidator(
  control: FormControl,
  field: FormlyFieldConfig,
  options: { rangeMin?: number; rangeMax?: number; certainValue?: number }
): ValidationErrors {
  if (control.value) {
    if (options.certainValue) {
      return control.value.length === options.certainValue ? null : { range: false };
    }
    if (options.rangeMin && !options.rangeMax) {
      return control.value.length >= options.rangeMin ? null : { range: false };
    }
    if (options.rangeMax && !options.rangeMin) {
      return control.value.length <= options.rangeMax ? null : { range: false };
    }
    if (control.value.length >= options.rangeMin && control.value.length <= options.rangeMax) {
      return null;
    }
  }
  return { range: false };
}

export function passwordValidator(control: FormControl): ValidationErrors {
  return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/.test(control.value)
    ? null
    : { password: false };
}

export function fieldMatchValidator(control: AbstractControl): ValidationErrors {
  const { password, confirmPassword } = control.value;

  if (!confirmPassword || !password) {
    return null;
  }

  if (confirmPassword === password) {
    return null;
  }

  return { fieldMatch: false };
}

export const earlierThanToday = (control: AbstractControl): ValidationErrors => {
  if (!control.value) {
    return { earlierThanToday: false };
  }

  const day = control.value.substring(0, 2);
  const month = control.value.substring(2, 4);
  const year = control.value.substring(4, 8);
  const date = `${month}-${day}-${year}`;

  if (day && month && year?.length === 4 && date) {
    const newDate: Date = new Date(date);

    if (newDate.toString() === 'Invalid Date') {
      return { earlierThanToday: false };
    }
    if (newDate > new Date()) {
      return { earlierThanToday: false };
    }
    return null;
  }
  return { earlierThanToday: false };
};
