import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthGuard} from './auth/helpers';
import {RouterModule, Routes} from '@angular/router';
import {CardDataComponent} from './main/components/card-data/card-data.component';

const appRoutes: Routes = [
    {
        path: 'pages',
        loadChildren: () => import('./main/pages/pages.module').then((m) => m.PagesModule),
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./main/components/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
    },
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
    },
    {
        path: 'employees',
        loadChildren: () => import('./main/components/employee/employee.module').then((m) => m.EmployeeModule),
        canActivate: [AuthGuard],
        data: {roles: [1, 2]}
    },
    {
        path: 'calendar',
        loadChildren: () => import('./main/components/calendar/calendar.module').then((m) => m.CalendarModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'documents',
        loadChildren: () => import('./main/components/document/document.module').then((m) => m.DocumentModule),
        canActivate: [AuthGuard],
        data: {roles: [2]}
    },
    {
        path: 'remaining-work',
        loadChildren: () =>
            import('./main/components/remaining-work/remaining-work.module').then((m) => m.RemainingWorkModule),
        canActivate: [AuthGuard],
        data: {roles: [1, 2]}
    },
    {
        path: 'user',
        loadChildren: () => import('./main/components/user/user.module').then((m) => m.UserModule),
        canActivate: [AuthGuard],
        data: {roles: [1, 2]}
    },
    {
        path: 'leave-form',
        loadChildren: () => import('./main/components/leave-form/leave-form.module').then((m) => m.LeaveFormModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'leave-management-form',
        loadChildren: () => import('./main/components/leave-management-form/leave-management-form.module').then((m) => m.LeaveManagementFormModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'travel-form',
        loadChildren: () => import('./main/components/travel-form/travel-form.module').then((m) => m.TravelFormModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'travel-management-form',
        loadChildren: () => import('./main/components/travel-management-form/travel-management-form.module').then((m) => m.TravelManagementFormModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'visitor-form',
        loadChildren: () => import('./main/components/visitor-form/visitor-form.module').then((m) => m.VisitorFormModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'education-form',
        loadChildren: () =>
            import('./main/components/education-request-form/education-request-form.module').then(
                (m) => m.EducationRequestFormModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'profile',
        loadChildren: () => import('./main/components/profile/profile.module').then((m) => m.ProfileModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'create-password',
        loadChildren: () =>
            import('./main/components/create-password/create-password.module').then((m) => m.CreatePasswordModule),
    },
    {
        path: 'forgot-password',
        loadChildren: () =>
            import('./main/components/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
    },
    {
        path: 'logs',
        loadChildren: () => import('./main/components/logs/logs.module').then((m) => m.LogsModule),
        canActivate: [AuthGuard],
        data: {roles: [1]}
    },
    {
        path: 'card-data',
        component: CardDataComponent,
        canActivate: [AuthGuard],
        data: {roles: [1, 2]}
    },
    {
        path: 'asset-categories',
        canActivate: [AuthGuard],
        data: {roles: [1, 2]},
        loadChildren: () => import('./main/components/asset-category/asset-category.module').then((m) => m.AssetCategoryModule),
    },
    {
        path: 'assets',
        canActivate: [AuthGuard],
        data: {roles: [1, 2]},
        loadChildren: () => import('./main/components/asset/asset.module').then((m) => m.AssetModule),
    },
    {
        path: 'official-holidays',
        canActivate: [AuthGuard],
        data: {roles: [1, 2]},
        loadChildren: () => import('./main/components/official-holiday/official-holiday.module').then((m) => m.OfficialHolidayModule),
    },
    {
        path: 'notifications',
        canActivate: [AuthGuard],
        data: {roles: [1, 2]},
        loadChildren: () => import('./main/components/notification/notification.module').then((m) => m.NotificationModule),
    },
    {
        path: 'forms',
        canActivate: [AuthGuard],
        data: {roles: [1, 2]},
        loadChildren: () => import('./main/components/form-management/form-management.module').then((m) => m.FormManagementModule),
    },
    {
        path: 'employee-form',
        canActivate: [AuthGuard],
        data: {roles: [1, 2, 3]},
        loadChildren: () => import('./main/components/employee-form/employee-form.module').then((m) => m.EmployeeFormModule),
    },
    {
        path: 'reports',
        canActivate: [AuthGuard],
        data: {roles: [1, 2, 3]},
        loadChildren: () => import('./main/components/reports/reports.module').then((m) => m.ReportsModule),
    },
    {
        path: '**',
        redirectTo: '/pages/miscellaneous/error', // Error 404 - Page not found
    },
];

@NgModule({
    declarations: [],
    imports: [CommonModule, RouterModule.forRoot(appRoutes)],
})
export class AppRoutingModule {
}
