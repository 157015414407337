import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseResponse} from 'app/main/data/base-response.model';
import {PageFilter} from 'app/main/shared/base/models/pageFilter.model';
import {BaseService} from 'app/main/shared/base/services/base.service';
import {Observable} from 'rxjs';
import {DocumentFileTypeModel} from '../../file-uploader/shared/models/file.model';

@Injectable({
    providedIn: 'root',
})
export class DocumentFileTypeService extends BaseService<DocumentFileTypeModel, DocumentFileTypeModel> {
    constructor(private http: HttpClient) {
        super(http, 'document-service/document-file-types');
    }

    getDocumentFileTypes(pageFilter: PageFilter): Observable<BaseResponse<DocumentFileTypeModel[]>> {
        return this.getAll(pageFilter);
    }

    getIdByDocumentFileTypeNameOrCreateAndReturnId(title: string, resolve) {
        const pageFilter: PageFilter = {
            column: ['Id'],
            count: 1,
            descending: false,
            index: 1,
            search: 'title=' + title
        };
        this.getAllApi(pageFilter).subscribe((res) => {
            if (res && res.results && res.results[0]) {
                resolve(res.results[0].id);
            } else {
                const docTypeModel: DocumentFileTypeModel = {
                    title
                };
                this.postApi(docTypeModel).subscribe(addedRes => {
                    if (addedRes.results) {
                        resolve(addedRes.results.id);
                    }
                });
            }
        });
    }
}
