import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr'; // For auth after login toast
import {CoreModule} from '@core/core.module';
import {CoreCommonModule} from '@core/common.module';
import {CoreThemeCustomizerModule} from '@core/components';
import {coreConfig} from 'app/app-config';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {AppRoutingModule} from './app-routing.module';
import {DashboardModule} from './main/components/dashboard/dashboard.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {ChartsModule} from 'ng2-charts';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {ErrorInterceptor, JwtInterceptor} from './auth/helpers';
import {FormlyModule} from '@ngx-formly/core';
import {NgxMatDatetimePickerModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {SharedModule} from './main/shared/shared.module';
import {FormsModule} from '@angular/forms';
import {CardDataComponent} from './main/components/card-data/card-data.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {NgxMaskModule} from 'ngx-mask';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
    declarations: [AppComponent, CardDataComponent],

    imports: [
        NgxMaskModule.forRoot(),
        FormsModule,
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        //NgBootstrap
        ToastrModule.forRoot(),
        // Core modules
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreThemeCustomizerModule,
        // App modules
        LayoutModule,
        AppRoutingModule,
        DashboardModule,
        //Material
        MatTableModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatInputModule,
        RouterModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        //Charts
        NgbDatepickerModule,
        ChartsModule,
        MatCardModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        FormlyModule.forRoot({extras: {lazyRender: true}}),
        NgApexchartsModule,
    ],
    providers: [MatDatepickerModule,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
