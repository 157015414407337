import {Injectable} from '@angular/core';
import {BaseService} from '../../../../shared/base/services/base.service';
import {OfficialHolidayRequestModel} from '../model/official-holiday-request.model';
import {HttpClient} from '@angular/common/http';
import {OfficialHolidayResponseModel} from '../model/official-holiday-response.model';

@Injectable({
    providedIn: 'root'
})
export class OfficialHolidayService extends BaseService<OfficialHolidayRequestModel, OfficialHolidayResponseModel> {

    constructor(private http: HttpClient) {
        super(http, 'company-service/official-holidays');
    }
}
