<div class="content-wrapper">
    <div class="content-body">
        <!-- users edit start -->
        <section class="horizontal-wizard">
            <div id="stepper1" class="bs-stepper horizontal-wizard-example">
                <div class="bs-stepper-header">
                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#personal-info">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">1</span>
                            <span class="bs-stepper-label">
                <span class="bs-stepper-title">{{ 'employee.personalInformation' | translate }}</span>
                <span class="bs-stepper-subtitle"></span>
                                {{ 'employee.personalInformation' | translate }} {{ 'employee.add' | translate }}</span>
                        </button>
                    </div>
                    <div class="step" data-target="#company-info">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">2</span>
                            <span class="bs-stepper-label">
                <span class="bs-stepper-title">{{ 'employee.companyInformation' | translate }}</span>
                <span class="bs-stepper-subtitle"></span>
                                {{ 'employee.companyInformation' | translate }} {{ 'employee.add' | translate }}</span
                            >
                        </button>
                    </div>
                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#other-information">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">3</span>
                            <span class="bs-stepper-label">
                <span class="bs-stepper-title">{{ 'employee.otherInformation' | translate }}</span>
                <span class="bs-stepper-subtitle">
                  {{ 'employee.otherInformation' | translate }} {{ 'employee.add' | translate }}</span
                >
              </span>
                        </button>
                    </div>
                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#emergency-info">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">4</span>
                            <span class="bs-stepper-label">
                <span class="bs-stepper-title">{{ 'employee.emergencyInformation' | translate }}</span>
                <span class="bs-stepper-subtitle"></span>
                                {{ 'employee.emergencyInformation' | translate }} {{ 'employee.add' | translate }}</span
                            >
                        </button>
                    </div>
                </div>
                <div class="bs-stepper-content">
                    <form (ngSubmit)="(HWForm.form.valid)" #HWForm="ngForm">
                        <div id="user-information" class="content">
                            <form #accountDetailsForm="ngForm">
                                <div class="content-header">
                                    <h5 class="mb-0">User Details</h5>
                                    <small class="text-muted">Enter Your Account Details.</small>
                                </div>
                                <div class="row">
                                    <div class="form-group col-12">
                                        <label for="status">Select User</label>
                                        <select
                                                class="form-control"
                                                (change)="onUsersChange($event)"
                                                [(ngModel)]="employee.userId"
                                                name="userId"
                                                id="userId"
                                                #userId
                                        >
                                            <option *ngFor="let user of users"
                                                    value="{{ user.id }}">{{ user.username }}</option>
                                        </select>
                                    </div>
                                </div>
                                <ngb-alert [type]="'warning'" [dismissible]="false">
                                    <div class="alert-body">
                                        <strong>{{ 'employee.or' | translate }}
                                            :</strong> {{ 'employee.createEmployeeBelow' | translate }}
                                    </div>
                                </ngb-alert>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label class="form-label"
                                               for="username"> {{ 'general.email' | translate }}</label>
                                        <input
                                            #email="ngModel"
                                            [(ngModel)]="user.username"
                                            required
                                            type="email"
                                            name="username"
                                            id="username"
                                            class="form-control"
                                            [class.error]="!accountDetailsForm.valid && accountDetailsForm.submitted"
                                            placeholder="johndoe"
                                        />
                                        <span *ngIf="accountDetailsForm.submitted && accountDetailsForm.invalid"
                                              class="invalid-form">
                      <small class="form-text text-danger" *ngIf="accountDetailsForm.errors.required"
                      >This field is required!</small>
                    </span>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="role" required>{{ 'general.role' | translate }}</label>
                                            <select class="form-control" [(ngModel)]="user.userRoleId" name="userRoleId"
                                                    id="userRoleId">
                                                <option value="{{ roleEnum.SystemAdmin }}">System Admin</option>
                                                <option value="{{ roleEnum.HrManager }}">HR Manager</option>
                                                <option value="{{ roleEnum.User }}">User</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <button (click)="addUser(null)" id="add" class="btn btn-primary mt-2">Add User
                                        </button>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button
                                            (click)="horizontalWizardStepperPrevious()"
                                            class="btn btn-outline-secondary btn-prev"
                                            disabled
                                            rippleEffect
                                    >
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                    </button>
                                    <button
                                            (click)="horizontalWizardStepperNext(accountDetailsForm)"
                                            type="submit"
                                            class="btn btn-primary btn-next"
                                            rippleEffect
                                    >
                                        <span class="align-middle d-sm-inline-block d-none">Next</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div id="personal-info" class="content">
                            <form #personalInfoForm="ngForm">
                                <div class="media mb-2">
                                    <div *ngIf="profilePhotoPath && profilePhotoPath !== ''">
                                        <img
                                                height="76"
                                                width="80"
                                                class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer image-fluid"
                                                [src]="profilePhotoPath"
                                                alt="User avatar"
                                        />
                                    </div>
                                    <div *ngIf="!profilePhotoPath || profilePhotoPath === ''" class="dotted-border">
                                        <img
                                                class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer image-fluid ml-sm-75"
                                                [src]="'/assets/images/avatars/user-avatar.png'"
                                                height="90"
                                        />
                                    </div>
                                </div>
                                <div class="media-body mt-50">
                                    <h4>{{ employee?.name }}</h4>
                                    <div class="col-12 d-flex mt-1 px-0">
                                        <label class="btn btn-primary mr-75 mb-0" for="change-picture">
                                            <span class="d-none d-sm-block">{{ 'general.change' | translate }}</span>
                                            <input
                                                    (change)="onFileChanged($event)"
                                                    class="form-control"
                                                    type="file"
                                                    id="change-picture"
                                                    hidden
                                                    accept="image/png, image/jpeg, image/jpg"
                                            />
                                            <span class="d-block d-sm-none">
                        <i class="mr-0" data-feather="edit"></i>
                      </span>
                                        </label>
                                        <button class="btn btn-outline-secondary d-none d-sm-block"
                                                (click)="uploadPhoto()" rippleEffect>
                                            {{ 'general.upload' | translate }}
                                        </button>
                                        <button class="btn btn-outline-secondary d-block d-sm-none">
                                            <i class="mr-0" data-feather="trash-2"></i>
                                        </button>
                                    </div>
                                </div>

                                <div class="row">
                                    <ngb-alert [type]="'warning'" [dismissible]="false" class="mt-3">
                                        <div class="alert-body">
                                            <strong>{{ 'general.note' | translate }}
                                                :</strong> {{ 'employee.createUserBelow' | translate }}.
                                        </div>
                                    </ngb-alert>
                                    <div class="col-md-12 custom-control custom-control-warning">
                                        <input type="checkbox" name="item" value="new" [(ngModel)]="checked"/>
                                        <label>{{ 'employee.youWantCreate' | translate }}</label>
                                    </div>
                                    <div class="col-md-12" [hidden]="!checked">
                                        <app-user-add (addNewUser)="addUser($event)"></app-user-add>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="card-id">{{ 'employee.cardUserId' | translate }}</label>
                                            <input
                                                    id="cardId"
                                                    [(ngModel)]="cardId"
                                                    type="number"
                                                    class="form-control birthdate-picker"
                                                    name="cardId"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="name">{{ 'employee.name' | translate }}*</label>
                                            <input
                                                #name
                                                [(ngModel)]="employee.name"
                                                id="name"
                                                type="text"
                                                class="form-control birthdate-picker"
                                                name="name"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="birth">{{ 'employee.surname' | translate }}*</label>
                                            <input
                                                #surname
                                                [(ngModel)]="employee.surname"
                                                id="surname"
                                                type="text"
                                                class="form-control birthdate-picker"
                                                name="surname"
                                                [class.error]="surname.submitted && surname.invalid"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4 col-md-6">
                                        <label class="form-label"
                                               for="id-number">{{ 'employee.idNumber' | translate }}*</label>
                                        <input
                                            #identificationNumber
                                            [(ngModel)]="employee.identificationNumber"
                                            required
                                            type="text"
                                            name="id-number"
                                            id="id-number"
                                            class="form-control"
                                            [class.error]="identificationNumber.submitted && identificationNumber.invalid"
                                        />
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="status">{{ 'employee.gender' | translate }}</label>
                                            <select
                                                    class="form-control"
                                                    [(ngModel)]="employee.gender"
                                                    name="gender"
                                                    #gender
                                                    id="gender"
                                            >
                                                <option value="{{ genderEnum.Male }}">{{ 'employee.male' | translate }}</option>
                                                <option value="{{ genderEnum.Woman }}">{{ 'employee.female' | translate }}</option>
                                                <option value="{{ genderEnum.Other }}">{{ 'employee.other' | translate }}</option>
                                                <option value="{{ genderEnum.Unspecified }}">{{ 'employee.unspecified' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="birth">{{ 'employee.maxLeaveDay' | translate }}</label>
                                            <input
                                                #maxLeaveDay
                                                [(ngModel)]="employee.maximumLeaveDay"
                                                id="maxLeaveDay"
                                                type="text"
                                                class="form-control birthdate-picker"
                                                name="maxLeaveDay"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="birth">{{ 'employee.birthDate' | translate }}*</label>
                                            <input
                                                #dateOfBirth
                                                [(ngModel)]="employee.dateOfBirth"
                                                id="birth"
                                                type="date"
                                                class="form-control birthdate-picker"
                                                name="birth"
                                                placeholder="YYYY-MM-DD"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="personal-mail">{{ 'employee.personalMailAddress' | translate }}</label>
                                            <input
                                                #personalMailAddress
                                                [(ngModel)]="employee.personalMailAddress"
                                                id="personal-mail"
                                                type="text"
                                                class="form-control birthdate-picker"
                                                name="personal-mail"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="company">{{ 'employee.personalPhoneNumber' | translate }}
                                                *</label>
                                            <input
                                                #personalphoneNumber
                                                type="text"
                                                class="form-control"
                                                [(ngModel)]="employee.personalPhoneNumber"
                                                id="personalPhoneNumber"
                                                name="personalPhoneNumber"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="status">{{ 'employee.educationStatus' | translate }}*</label>
                                            <select
                                                    class="form-control"
                                                    [(ngModel)]="employee.educationStatus"
                                                    name="educationStatus"
                                                    id="educationStatus"
                                                    #educationStatus
                                                    required
                                            >
                                                <option value="{{ educationStatusEnum.GRADUATED }}">
                                                    {{ 'employee.graduated' | translate }}
                                                </option>
                                                <option value="{{ educationStatusEnum.STUDENT }}">{{ 'employee.student' | translate }}</option>
                                                <option value="{{ educationStatusEnum.UNSPECIFIED }}">
                                                    {{ 'employee.unspecified' | translate }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="company">{{ 'employee.educationInstitution' | translate }}
                                                *</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    [(ngModel)]="employee.educationalInstitution"
                                                    id="educationalInstitution"
                                                    name="educationalInstitution"
                                                    #educationalInstitution
                                                    required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="educationLevel">{{ 'employee.educationLevel' | translate }}
                                                *</label>
                                            <select
                                                    required
                                                    class="form-control"
                                                    [(ngModel)]="employee.educationLevel"
                                                    name="educationLevel"
                                                    id="educationLevel"
                                                    #educationLevel
                                            >
                                                <option value="{{ educationLevelEnum.none }}">{{ 'employee.none' | translate }}</option>
                                                <option value="{{ educationLevelEnum.primarySchool }}">
                                                    {{ 'employee.primarySchool' | translate }}
                                                </option>
                                                <option value="{{ educationLevelEnum.middleSchool }}">
                                                    {{ 'employee.middleSchool' | translate }}
                                                </option>
                                                <option value="{{ educationLevelEnum.highSchool }}">
                                                    {{ 'employee.highSchool' | translate }}
                                                </option>
                                                <option value="{{ educationLevelEnum.associate }}">
                                                    {{ 'employee.associate' | translate }}
                                                </option>
                                                <option value="{{ educationLevelEnum.underGraduate }}">
                                                    {{ 'employee.underGraduate' | translate }}
                                                </option>
                                                <option value="{{ educationLevelEnum.postGraduate }}">
                                                    {{ 'employee.postGraduate' | translate }}
                                                </option>
                                                <option value="{{ educationLevelEnum.doctorate }}">
                                                    {{ 'employee.doctorate' | translate }}
                                                </option>
                                                <option value="{{ educationLevelEnum.unspecified }}">
                                                    {{ 'employee.unspecified' | translate }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="status">{{ 'employee.maritalStatus' | translate }}</label>
                                            <select
                                                    class="form-control"
                                                    [(ngModel)]="employee.maritalStatus"
                                                    name="maritalStatus"
                                                    id="maritalStatus"
                                                    #maritalStatus
                                            >
                                                <option value="{{ maritalStatusEnum.SINGLE }}">{{ 'employee.single' | translate }}</option>
                                                <option value="{{ maritalStatusEnum.MARRIED }}">{{ 'employee.married' | translate }}</option>
                                                <option value="{{ maritalStatusEnum.DIVORCED }}">{{ 'employee.divorced' | translate }}</option>
                                                <option value="{{ maritalStatusEnum.UNSPECIFIED }}">
                                                    {{ 'employee.unspecified' | translate }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="status">{{ 'employee.bloodGroup' | translate }}</label>
                                            <select
                                                    class="form-control"
                                                    [(ngModel)]="employee.bloodGroup"
                                                    name="bloodGroup"
                                                    id="bloodGroup"
                                                    #bloodGroup
                                            >
                                                <option value="{{ bloodTypeEnum.ZERORHPOSITIVE }}">0 RH(+)</option>
                                                <option value="{{ bloodTypeEnum.ZERORHNEGATIVE }}">0 RH(-)</option>
                                                <option value="{{ bloodTypeEnum.ARHPOSITIVE }}">A RH(+)</option>
                                                <option value="{{ bloodTypeEnum.ARHNEGATIVE }}">A RH(-)</option>
                                                <option value="{{ bloodTypeEnum.BRHPOSITIVE }}">B RH(+)</option>
                                                <option value="{{ bloodTypeEnum.BRHNEGATIVE }}">B RH(-)</option>
                                                <option value="{{ bloodTypeEnum.ABRHPOSITIVE }}">AB RH(+)</option>
                                                <option value="{{ bloodTypeEnum.ABRHNEGATIVE }}">AB RH(-)</option>
                                                <option value="{{ bloodTypeEnum.UNSPECIFIED }}">
                                                    {{ 'employee.unspecified' | translate }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="role">{{ 'employee.militaryStatus' | translate }}</label>
                                            <select
                                                    class="form-control"
                                                    [(ngModel)]="employee.militaryService"
                                                    name="militaryStatus"
                                                    id="militaryStatus"
                                                    #militaryStatus
                                            >
                                                <option value="{{ militaryEnum.DONE }}">{{ 'employee.done' | translate }}</option>
                                                <option value="{{ militaryEnum.UNDONE }}">{{ 'employee.undone' | translate }}</option>
                                                <option value="{{ militaryEnum.EXEMPT }}">{{ 'employee.exempt' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="company">{{ 'employee.numberOfChildren' | translate }}</label>
                                            <input
                                                    type="number"
                                                    class="form-control"
                                                    [(ngModel)]="employee.numberOfChildren"
                                                    id="numberOfChildren"
                                                    name="numberOfChildren"
                                                    #numberOfChildren
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-prev"
                                            rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.previous' | translate }}</span>
                                    </button>
                                    <button
                                            (click)="horizontalWizardStepperNext(personalInfoForm)"
                                            class="btn btn-primary btn-next"
                                            rippleEffect
                                    >
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.next' | translate }}</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div id="company-info" class="content">
                            <form #companyInfoForm="ngForm">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="title">{{ 'employee.title' | translate }}*</label>
                                            <input
                                                    id="title"
                                                    type="text"
                                                    class="form-control"
                                                    value=""
                                                    placeholder="Front-end Developer"
                                                    name="address"
                                                    [(ngModel)]="employee.title"
                                                    required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="dateOfStartWork">{{ 'employee.dateOfStartWork' | translate }}
                                                *</label>
                                            <input
                                                    type="date"
                                                    class="form-control"
                                                    [(ngModel)]="employee.dateOfStart"
                                                    id="dateOfStartWork"
                                                    name="dateOfStartWork"
                                                    required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="contractType">{{ 'employee.contractType' | translate }}*</label>
                                            <select required class="form-control" [(ngModel)]="employee.contractType"
                                                    name="contractType" id="contractType">
                                                <option value="{{ contractEnum.FULL_TIME }}">
                                                    {{ 'employee.full_time' | translate }}
                                                </option>
                                                <option value="{{ contractEnum.PART_TIME }}">
                                                    {{ 'employee.part_time' | translate }}
                                                </option>
                                                <option value="{{ contractEnum.TEMPORARY }}">
                                                    {{ 'employee.temporary' | translate }}
                                                </option>
                                                <option value="{{ contractEnum.SEASONAL }}">
                                                    {{ 'employee.seasonal' | translate }}
                                                </option>
                                                <option value="{{ contractEnum.CONSULTANT }}">
                                                    {{ 'employee.consultant' | translate }}
                                                </option>
                                                <option value="{{ contractEnum.UNSPECIFIED }}">{{ 'employee.unspecified' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div
                                            class="col-lg-4 col-md-6"
                                    >
                                        <div class="form-group">
                                            <label for="contractEndDate"> {{ 'employee.contractEndDate' | translate }}</label>
                                            <input
                                                    [(ngModel)]="employee.contractEndDate"
                                                    id="contractEndDate"
                                                    type="date"
                                                    class="form-control birthdate-picker"
                                                    name="dob"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="companyMailAddress"> {{ 'employee.companyMailAddress' | translate }}
                                                *</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    [(ngModel)]="employee.companyMailAddress"
                                                    id="companyMailAddress"
                                                    name="companyMailAddress"
                                                    required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="birth"> {{ 'employee.companyPhoneNumber' | translate }}</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    [(ngModel)]="employee.companyPhoneNumber"
                                                    id="company"
                                                    name="company"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <app-file-uploader></app-file-uploader>
                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-prev"
                                            rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.previous' | translate }}</span>
                                    </button>
                                    <button
                                            (click)="horizontalWizardStepperNext(companyInfoForm)"
                                            class="btn btn-primary btn-next"
                                            rippleEffect
                                    >
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.next' | translate }}</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div id="other-information" class="content">
                            <form #otherInfoForm="ngForm">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="address-1">{{ 'employee.addressLine1' | translate }}</label>
                                            <input
                                                    id="address-1"
                                                    type="text"
                                                    class="form-control"
                                                    value=""
                                                    name="address1"
                                                    [(ngModel)]="other.address1"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="address-2">{{ 'employee.addressLine2' | translate }}</label>
                                            <input
                                                    id="address-2"
                                                    name="address2"
                                                    value=""
                                                    type="text"
                                                    class="form-control"
                                                    [(ngModel)]="other.address2"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="postcode">{{ 'employee.bankName' | translate }}</label>
                                            <input
                                                    id="postcode"
                                                    type="text"
                                                    class="form-control"
                                                    name="bankName"
                                                    [(ngModel)]="other.bankName"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="accountnumber">{{ 'employee.accountNumber' | translate }}</label>
                                            <input
                                                    id="accountnumber"
                                                    type="text"
                                                    class="form-control"
                                                    name="accountNumber"
                                                    [(ngModel)]="other.accountNumber"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="iban">{{ 'employee.iban' | translate }}</label>
                                            <input id="iban" type="text" class="form-control" name="iban"
                                                   [(ngModel)]="other.iban"/>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-prev"
                                            rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.previous' | translate }}</span>
                                    </button>
                                    <button
                                            (click)="horizontalWizardStepperNext(otherInfoForm)"
                                            class="btn btn-primary btn-next"
                                            rippleEffect
                                    >
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.next' | translate }}</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div id="emergency-info" class="content">
                            <form #socialLinkForm="ngForm">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="address-1">{{ 'employee.name' | translate }}</label>
                                            <input
                                                    id="emergency.name"
                                                    type="text"
                                                    class="form-control"
                                                    name="name"
                                                    value=""
                                                    [(ngModel)]="emergency.name"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="phone">{{ 'employee.phone' | translate }}</label>
                                            <input
                                                    value=""
                                                    id="phone"
                                                    name="phone"
                                                    type="text"
                                                    class="form-control"
                                                    [(ngModel)]="emergency.phone"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="degreeOfAffinity">{{ 'employee.degreeOfAffinity' | translate }}</label>
                                            <input
                                                    value=""
                                                    name="degreeOfAffinity"
                                                    id="degreeOfAffinity"
                                                    type="text"
                                                    class="form-control"
                                                    [(ngModel)]="emergency.degreeOfAffinity"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-prev"
                                            rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.previous' | translate }}</span>
                                    </button>
                                    <button class="btn btn-success btn-submit" (click)="addEmplooye()" rippleEffect>
                                        {{ 'general.submit' | translate }}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</div>
