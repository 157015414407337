import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterConfig} from './interfaces/filter.config';
import {generateModel} from 'app/main/shared/utils';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
    filterForm: FormGroup;
    @Input() config: FilterConfig<string>[];
    @Output() emitFilter: EventEmitter<any> = new EventEmitter();
    @Output() emitResetFilter: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
        this.filterForm = this.toFormGroup(this.config);
    }

    toFormGroup = (config: FilterConfig<string>[]) => {
        const fg: any = {};
        config.forEach((cnf) => {
            cnf.formGroups.forEach((group) => {
                const control = group.control;
                fg[control.key] = control.required
                    ? new FormControl(control.value || '', Validators.required)
                    : new FormControl(control.value || '');
            });
        });

        return new FormGroup(fg);
    };

    filter = () => {
        this.emitFilter.emit(generateModel(this.filterForm));
    };

    resetFilter = () => {
        this.emitResetFilter.emit();
    };
}
