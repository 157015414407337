import { FormlyFieldConfig } from '@ngx-formly/core';

export function nameValidatorMessage(err, field: FormlyFieldConfig): string {
  return `"${field.formControl.value}" is not a valid name`;
}

export function emailValidatorMessage(err, field: FormlyFieldConfig): string {
  return `"${field.formControl.value}" is not a valid email address`;
}

export function onlyLetterValidatorMessage(err, field: FormlyFieldConfig): string {
  return `"${field.formControl.value}" is not a string. Only string is accepted.`;
}

export function onlyNumberValidatorMessage(err, field: FormlyFieldConfig): string {
  return `"${field.formControl.value}" is not a number. Only number is accepted.`;
}

export function onlyIpAddressValidatorMessage(err, field: FormlyFieldConfig): string {
  return `"${field.formControl.value}" is not a IP address. Only IP address is accepted.`;
}

export function onlyIpAddressBodyValidatorMessage(err, field: FormlyFieldConfig): string {
  return `"${field.formControl.value}" is not a IP address body.`;
}

export function onlyIpAddressRangeValidatorMessage(err, field: FormlyFieldConfig): string {
  return `"${field.formControl.value}" is not a IP address range.`;
}
export function requiredValidatorMessage(err, field: FormlyFieldConfig): string {
  return `${field.templateOptions.label} is required.`;
}

export function rangeValidatorMessage(err, field: FormlyFieldConfig): string {
  let rangeMin: number;
  let rangeMax: number;
  let certainValue: number;
  field.validators.validation.forEach((validation) => {
    if (validation.name === 'range') {
      rangeMin = validation.options.rangeMin;
      rangeMax = validation.options.rangeMax;
      certainValue = validation.options.certainValue;
    }
  });
  if (certainValue) {
    return `Size must be ${certainValue} character`;
  }
  if (rangeMax) {
    if (!rangeMin) {
      return `Size must be smaller than ${rangeMax} character`;
    }
    return `Size must be between "${rangeMin} - ${rangeMax} "`;
  }
  if (rangeMin) {
    return `Size must be bigger than ${rangeMin} character`;
  }

  return null;
}

export function passwordValidatorMessage(err, field: FormlyFieldConfig): string {
  return `Your password must be 8-16 characters, include at least one lowercase letter, one uppercase letter, a number and one special key.`;
}
export function earlierThanTodayMessage(err, field: FormlyFieldConfig): string {
  return `The date should be smaller than current day`;
}
