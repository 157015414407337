import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';

import {CalendarOptions, EventClickArg, EventInput} from '@fullcalendar/angular';

import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {CalendarService} from './shared/calendar.service';
import {EventRef} from './shared/calendar.model';
import {LeaveFormService} from '../leave-form/data/services/leave-form.service';
import {PageFilter} from 'app/main/shared/base/models/pageFilter.model';
import {EventModel} from './shared/models/event.model';
import {ApproveStatusType} from '../leave-form/data/enums/approveStatusType.enum';
import {NotificationService} from '../notification/shared/service/notification-service.service';
import {AuthenticationService} from '../../../auth/service';
import {OfficialHolidayService} from '../official-holiday/data/service/official-holiday.service';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements OnInit, AfterViewInit {
    // Public
    public slideoutShow = false;
    public events: Array<EventInput> = [];
    public event;

    public calendarOptions: CalendarOptions = {
        headerToolbar: {
            start: 'sidebarToggle, prev,next, title',
        },
        initialView: 'listMonth',
        eventTimeFormat: { // like '14:30:00'
            hour: '2-digit',
            minute: '2-digit',
            meridiem: true,
        },
        allDayText: 'All day',
        initialEvents: this.events,
        slotLabelFormat: 'HH:mm',
        firstDay: 1,
        height: 700,
        weekends: true,
        eventResizableFromStart: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: 2,
        navLinks: true,
        eventClick: this.handleUpdateEventClick.bind(this),
        eventClassNames: this.eventClass.bind(this),
        select: this.handleDateSelect.bind(this),
    };
    public allEvents: EventModel[] = [];

    /**
     * Constructor
     *
     * @param {CoreSidebarService} _coreSidebarService
     * @param {CalendarService} _calendarService
     * @param _leaveFormService
     * @param _notificationService
     * @param _authService
     * @param _officialHolidayService
     */
    constructor(
        private _coreSidebarService: CoreSidebarService,
        private _calendarService: CalendarService,
        private _leaveFormService: LeaveFormService,
        private _notificationService: NotificationService,
        private _authService: AuthenticationService,
        private _officialHolidayService: OfficialHolidayService
    ) {
    }

    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Add Event Class
     *
     * @param s
     */
    eventClass(s) {
        const calendarsColor = {
            APPROVED: 'success',
            NOT_APPROVED: 'danger',
            PENDING: 'info',
            Holiday: 'primary'
        };
        const colorName = calendarsColor[s.event._def.extendedProps.calendar];
        return `bg-light-${colorName}`;
    }

    /**
     * Update Event
     *
     * @param eventRef
     */
    handleUpdateEventClick(eventRef: EventClickArg) {
        // this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').toggleOpen();
        // this._calendarService.updateCurrentEvent(eventRef);
    }

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
    }

    /**
     * Date select Event
     *
     * @param eventRef
     */
    handleDateSelect(eventRef) {
        const newEvent = new EventRef();
        newEvent.start = eventRef.start;
        this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').toggleOpen();
        this._calendarService.onCurrentEventChange.next(newEvent);
    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getHolidayList(resolved => {
            this.getLeaveList(resolved);
        });
    }

    getLeaveList(notifications) {
        this._leaveFormService.getAllLeaves().subscribe((res) => {
                const events: EventInput[] = [];
                if (res.results) {
                    res.results.forEach((leave) => {
                        const isHRManagerOrIsEmployeeLeave = leave.employeeId === this._authService.currentUserValue.employeeId
                            || this._authService.hasRole([1, 2]);
                        const url = isHRManagerOrIsEmployeeLeave ? '/leave-form/leave-form-view/' + leave.id : '';
                        events.push({
                            id: leave.id.toString() + 'leave',
                            allDay: false,
                            start: new Date(leave.startDate),
                            end: new Date(leave.endDate),
                            title: leave.employee.name + ' ' + leave.employee.surname + ' İzin',
                            extendedProps: {
                                description: leave.leaveReason,
                            },
                            calendar: ApproveStatusType[leave.approveStatus],
                            url
                        });
                    });
                }
                this.calendarOptions.events = [...notifications, ...events];
            },
            err => {
                this.calendarOptions.events = [...notifications];
            });
    }

    getHolidayList(resolved) {
        const pageFilter: PageFilter = {
            count: 1000,
            descending: true,
            index: 1,
            search: ``,
            column: ['CreatedDate'],
        };
        this._officialHolidayService.getAllApi(pageFilter).subscribe((res) => {
                if (res.results) {
                    const events: EventInput[] = [];
                    res.results.forEach((holiday) => {
                        events.push({
                            id: holiday.id.toString() + 'holiday',
                            allDay: false,
                            start: new Date(holiday.startDate),
                            end: new Date(holiday.endDate),
                            title: holiday.title,
                            calendar: 'Holiday',
                            url: ''
                        });
                    });
                    resolved(events);
                } else {
                    resolved([]);
                }
            },
            (err) => resolved([]));
    }

    ngAfterViewInit() {
        let _this = this;
        this.calendarOptions.customButtons = {
            sidebarToggle: {
                text: '',
                click() {
                    _this.toggleSidebar('calendar-main-sidebar');
                },
            },
        };
    }
}
