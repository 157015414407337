import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse } from 'app/main/data/base-response.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UserResponse } from './data/models/user.response.model';
import { UserRequest } from './data/models/user.model';
import { BaseService } from 'app/main/shared/base/services/base.service';
import { User } from 'app/auth/models';
import { createPasswordModel } from '../create-password/data/model/create-password.model';
import { PageFilter } from 'app/main/shared/base/models/pageFilter.model';
import { getPageAndSizeParams, getRequestParams } from 'app/main/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService<UserRequest, UserResponse> {
  constructor(private http: HttpClient) {
    super(http, 'user-service/users');
  }

  getUsers(paging: PageFilter): Observable<BaseResponse<UserResponse[]>> {
    return this.getAllApi(paging);
  }

  addUser(user: UserRequest): Observable<BaseResponse<UserResponse>> {
    return this.http.post<BaseResponse<UserResponse>>(`${environment.apiUrl}user-service/users`, user);
  }

  updateUser(id: number, user: UserRequest): Observable<BaseResponse<UserResponse>> {
    return this.http.put<BaseResponse<UserResponse>>(`${environment.apiUrl}user-service/users/${id}`, user);
  }

  createPassword(createPassword: createPasswordModel) {
    return this.http.post(`${environment.apiUrl}auth-service/authentication/createPassword`, createPassword);
  }
}
