import {Observable} from 'rxjs';

export const formatEmployeePhoto = (photo: any): Observable<string> => {
    return new Observable((observer) => {
        const reader = new FileReader();
        reader.readAsDataURL(photo);
        reader.onload = () => {
            observer.next(reader.result as string);
            observer.complete();
        };
    });
};
