import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Role} from '../../user/data/enums/role.enum';
import {combineLatest, Observable, Subject} from 'rxjs';
import {Gender} from '../data/enums/gender.enum';
import {EmergencyInformation, EmployeeRequest, OtherInformation} from '../data/models/employee-request.model';
import {BloodGroup} from '../data/enums/bloodType.enum';
import {ContractType} from '../data/enums/contract.enum';
import {EducationLevel} from '../data/enums/educationLevel.enum';
import {EducationStatus} from '../data/enums/educationStatus.enum';
import {MaritalStatus} from '../data/enums/maritalStatus.enum';
import {UserRequest} from '../../user/data/models/user.model';
import {UserService} from '../../user/user.service';
import {EmployeeService} from '../employee.service';
import Stepper from 'bs-stepper';
import {UserResponse} from '../data/models/employee.model';
import {FileUploader} from 'ng2-file-upload';
import {ToastrService} from 'ngx-toastr';
import {FileUploaderComponent} from '../../file-uploader/file-uploader.component';
import {DocumentService} from '../../document/shared/document.service';
import {DocumentModel} from '../../document/shared/document.mode';
import {BaseResponse} from 'app/main/data/base-response.model';
import {Military} from '../data/enums/militarystatus.enum';
import {CardEntryModel} from '../data/models/employee-card-entry-model';

@Component({
    selector: 'app-employee-add',
    templateUrl: './employee-add.component.html',
    styleUrls: ['./employee-add.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EmployeeAddComponent implements OnInit {
    public uploader: FileUploader = new FileUploader({
        isHTML5: true,
    });
    @ViewChild(FileUploaderComponent) fileUploaderComponent: FileUploaderComponent;
    public hasBaseDropZoneOver: boolean = false;
    public uploadedDocuments: DocumentModel[] = [];
    public photo: any;
    public profilePhotoPath: string;
    public rows;
    public genderEnum: any = Gender;
    public roleEnum: any = Role;
    public militaryEnum: any = Military;
    public bloodTypeEnum: any = BloodGroup;
    public contractEnum: any = ContractType;
    public educationLevelEnum: any = EducationLevel;
    public educationStatusEnum: any = EducationStatus;
    public maritalStatusEnum: any = MaritalStatus;
    public urlLastValue;
    public url = this.router.url;
    private _unsubscribeAll: any;
    public employee: EmployeeRequest = {} as EmployeeRequest;
    public emergency: EmergencyInformation = {} as EmergencyInformation;
    public other: OtherInformation = {} as OtherInformation;
    public user: UserRequest = {} as UserRequest;
    public contentHeader: object;
    public employeeId: number;
    public documentsList: DocumentModel[];
    public cardId: number;
    private horizontalWizardStepper: Stepper;
    private userId: number;
    checked: boolean;
    public users: UserResponse[] = [];

    constructor(
        private router: Router,
        private _userService: UserService,
        private _employeeService: EmployeeService,
        private _toastrService: ToastrService,
        private _documentService: DocumentService
    ) {
        this._unsubscribeAll = new Subject();
        this.urlLastValue = this.url.substr(this.url.lastIndexOf('/') + 1);
    }

    ngOnInit(): void {
        this.setUsers();

        this.horizontalWizardStepper = new Stepper(document.querySelector('#stepper1'), {});
    }

    onUsersChange(event) {
        const user = this.users.find((user) => user.id == event.target.value);
        this.user.userRoleId = user.userRoleResponse.id;
        this.user.username = user.username;
    }

    addUser(user: UserRequest) {
        this._userService.addUser(user).subscribe((res) => {
            if (res.results) {
                if (res.results) {
                    this.employee.userId = res.results.id;
                    this.userId = res.results.id;
                    this._toastrService.success('User account successfully created. To complete this process fill the employee form.', 'User account created');
                } else {
                    this._toastrService.error(res.message, 'Error');
                }
            }
        });
    }

    setUsers() {
        this._userService.getUsers(null).subscribe((res) => {
            if (res.results) {
                this.users = res.results;
            }
        });
    }

    addDocuments(employeeId: number): Array<Observable<BaseResponse<DocumentModel>>> {
        const observables: Array<Observable<BaseResponse<DocumentModel>>> = [];
        this.uploadedDocuments.map((item) => {
            item.employeeId = employeeId;
            observables.push(this._documentService.addDocuments(item));
        });
        return observables;
    }

    onFileChanged(event) {
        const file = event.target.files[0];
        this.photo = file;
        const reader = new FileReader();
        reader.onload = () => {
            this.profilePhotoPath = reader.result as string;
        };
        reader.readAsDataURL(file);
    }

    uploadPhoto() {
        const uploadData = new FormData();
        uploadData.append('files', this.photo, this.photo.name);
        this._employeeService.uploadPhoto(uploadData).subscribe((res) => {
            this.employee.profilePhotoPath = res.results.path;
            this.employee.profilePhotoContentType = res.results.contentType;
        });
    }

    addEmplooye() {
        this.employee.branchId = 1;
        this.employee.companyId = 1;
        this.employee.departmentId = 1;
        this.employee.nationalityId = 1;
        this.employee.userId = this.userId;
        this.employee.emergencyInformation = this.emergency;
        this.employee.otherInformation = this.other;
        this._employeeService.addEmployee(this.employee).subscribe((res) => {
            if (res.results) {
                this.uploadedDocuments = this.fileUploaderComponent.uploadedDocuments;
                const observables = this.addDocuments(res.results.id);
                combineLatest(observables).subscribe();

                const cardModel: CardEntryModel = {
                    employeeId: res.results.id,
                    fullName: res.results.name + ' ' + res.results.surname,
                    userId: this.cardId,
                };
                this._employeeService.changeCard(cardModel).subscribe();
                this.router.navigate(['employees']).then(() =>
                    this._toastrService.success('Customer successfully updated!', 'Success', {
                        toastClass: 'toast ngx-toastr',
                        closeButton: true,
                    })
                );
            }
            (err) => {
                this._toastrService.error(err.error.message, 'Error', {
                    toastClass: 'toast ngx-toastr',
                    closeButton: true,
                });
            };
        });
    }

    horizontalWizardStepperNext(data) {
        if (data.form.valid === true) {
            this.horizontalWizardStepper.next();
        }
    }

    horizontalWizardStepperPrevious() {
        this.horizontalWizardStepper.previous();
    }
}
