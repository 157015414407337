import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import * as DocumentEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import {CKEditorComponent} from '@ckeditor/ckeditor5-angular';
import {MyUploadAdapter} from '../MyUploadAdapter';

@Component({
    selector: 'app-document-editor',
    templateUrl: './document-editor.component.html',
    styleUrls: ['./document-editor.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DocumentEditorComponent implements OnInit {

    public Editor = DocumentEditor;
    @ViewChild('editor') editorComponent: CKEditorComponent;
    @Input() editorContent = '';
    @Input() isDisabled = false;
    @Input() editorHeader = '';

    constructor() {
    }

    public onReady(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new MyUploadAdapter(loader);
        };
        editor.ui.getEditableElement().parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
        );
    }

    ngOnInit(): void {
    }

}
