<div class="content-wrapper">
    <div class="content-body">
        <!-- User List -->
        <section class="users-list-wrapper" *ngIf="_employeeService.dataList$ | async as rowData">
            <core-card
                    [actions]="['collapse', 'expand', 'filter', 'add']"
                    [addPageUrl]="'/employees/employee-add'"
                    [sidebarKey]="pageConfig.filterSidebarKey"
            >
                <div class="card-title"></div>
                <div class="card-body">
                    <ngx-datatable
                            class="bootstrap core-bootstrap"
                            [columnMode]="listConfig.columnMode"
                            [headerHeight]="listConfig.headerHeight"
                            [rowHeight]="listConfig.rowHeight"
                            [footerHeight]="listConfig.footerHeight"
                            [limit]="listConfig.limit"
                            [rows]="rowData.results"
                            (sort)="onSort($event)"
                            [count]="rowData.totalCount"
                            [offset]="page.offset"
                            (page)="setPage($event)"
                            [externalPaging]="listConfig.externalPaging"
                            [externalSorting]="listConfig.externalSorting"
                            [scrollbarH]="listConfig.scrollbarH"
                            [scrollbarV]="listConfig.scrollbarV"
                            [reorderable]="listConfig.reorderable"
                    >
                        <ngx-datatable-column name="{{ 'general.user' | translate }}" prop="name" [width]="50">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                <span [ngClass]="{ 'text-danger': !row.isActive, 'text-success': row.isActive }">
                  <span data-feather="target"></span>
                </span>

                                <span (click)="employeeDetailPage(row.id)" style="cursor: pointer"
                                >&nbsp;{{ row.name }} {{ row.surname }}
                </span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{ 'general.email' | translate }}" prop="personalMailAddress">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <span>{{ row.companyMailAddress }} </span>
                            </ng-template>
                        </ngx-datatable-column
                        >
                        <ngx-datatable-column name="{{ 'general.title' | translate }}" prop="title" [width]="50">
                        </ngx-datatable-column>
                        <ngx-datatable-column
                                name="{{ 'general.phoneNumber' | translate }}"
                                prop="personalPhoneNumber"
                                [width]="50"
                        ></ngx-datatable-column>
                        <ngx-datatable-column name="{{ 'employee.educationInstitution' | translate }}" [width]="50">
                            <ng-template let-row="row" let-name="travelsTime" ngx-datatable-cell-template>
                                {{ row.personalInformationResponse.educationalInstitution }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{ 'employee.educationLevel' | translate }}" [width]="100">
                            <ng-template let-row="row" let-name="Travels Cause" ngx-datatable-cell-template>
                                {{ getTranslateName(row.personalInformationResponse.educationLevel) | translate }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{ 'general.actions' | translate }}" [width]="30"
                                              [sortable]="false">
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <div ngbDropdown container="body">
                                    <button ngbDropdownToggle type="button" class="btn icon-btn btn-sm hide-arrow"
                                            rippleEffect>
                                        <span [data-feather]="'more-vertical'" [class]="'cursor-pointer'"></span>
                                    </button>
                                    <div ngbDropdownMenu>
                                        <a routerLink="/employees/employee-edit/{{ row.id }}" ngbDropdownItem
                                        ><span [data-feather]="'edit'" [size]="16" [class]="'mr-50'"></span>
                                            {{ 'general.edit' | translate }}</a
                                        >
                                        <a href="javascript:;" ngbDropdownItem (click)="deleteEmployee(row.id)"
                                        ><span [data-feather]="'trash'" [size]="16" [class]="'mr-50'"></span>
                                            {{ 'general.delete' | translate }}</a
                                        >
                                        <a *ngIf="!row.isActive" href="javascript:;" ngbDropdownItem
                                           (click)="activatedEmployee(row.id)"
                                        ><span [data-feather]="'check'" [size]="16" [class]="'mr-50'"></span>
                                            {{ 'general.activated' | translate }}
                                        </a>
                                        <a *ngIf="row.isActive" href="javascript:;" ngbDropdownItem
                                           (click)="deactivatedEmployee(row.id)"
                                        ><span [data-feather]="'x'" [size]="16" [class]="'mr-50'"></span>
                                            {{ 'general.deactivated' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </core-card>
        </section>
        <!--/ User List -->
    </div>
    <app-filter-sidebar [sidebarKey]="pageConfig.filterSidebarKey" (filterEmit)="toggleFilter()">
        <app-filter [config]="filterConfig" (emitFilter)="setFilter($event)" (emitResetFilter)="resetFilters()">
        </app-filter
        >
    </app-filter-sidebar>
    <!-- New User Sidebar -->
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="employee-list-sidebar"
            overlayClass="modal-backdrop"
    >
    </core-sidebar>
    <!--/ New User Sidebar -->
</div>
