import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'formly-field-ng-select',
    template: `
        <ng-select [compareWith]="compareFunc" [ngModel]="model" [addTag]="to.addTag" [placeholder]="to?.placeholder" [bindValue]="'value'"
                   [items]="to.options"
                   [formControl]="formControl">
        </ng-select>
    `,
    styleUrls: ['./select.component.style.scss'],
})
export class FormlyFieldNgSelectComponent extends FieldType {
    compareFunc(test) {
        return true;
    }
}
