import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PageFilter} from 'app/main/shared/base/models/pageFilter.model';
import {FileUploader} from 'ng2-file-upload';
import {DocumentModule} from '../document/document.module';
import {DocumentFileTypeService} from '../document/shared/document-file-type.service';
import {DocumentModel} from '../document/shared/document.mode';
import {FileUploaderService} from './shared/file-uploader.service';
import {DocumentFileTypeModel, responseDocumentFileModel} from './shared/models/file.model';
import {DocumentTypeService} from '../document/shared/document-type.service';
import {ToastService} from '../../../../@core/services/toast.service';

const URL = 'https://your-url.com';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent implements OnInit {
    @Input() employeeId: number;
    @Input() shouldAddAfterUpload = false;
    @Output() addDocument = new EventEmitter<DocumentModule>();

    // public
    public file: any;
    public uploadedDocuments: DocumentModel[] = [];
    public selectedType: number;
    public selectedFileType: number;
    public fileToUpload: File = null;
    public selectbox: DocumentFileTypeModel[] = [];
    public docTypes: DocumentFileTypeModel[] = [];
    public queueItemHelper: { name: string; fileType: string; documentType: string; viewUrl?: string }[] = [];

    public addDoc: DocumentModel = {} as DocumentModel;
    public fileResponse: responseDocumentFileModel = {} as responseDocumentFileModel;
    public contentHeader: object;
    public hasAnotherDropZoneOver: boolean = false;
    public hasBaseDropZoneOver: boolean = false;
    public documentsList: DocumentModel[];
    public uploader: FileUploader = new FileUploader({
        url: URL,
        isHTML5: true,
    });

    pageFilter: PageFilter = {
        column: ['UpdatedDate'],
        count: 1000,
        descending: true,
        index: 1,
        search: '',
    };

    fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    fileOverAnother(e: any): void {
        this.hasAnotherDropZoneOver = e;
    }

    constructor(
        private _fileUploaderService: FileUploaderService,
        private _documentFileTypeService: DocumentFileTypeService,
        private _documentTypeService: DocumentTypeService,
        private _toastService: ToastService
    ) {
    }

    getQueueItemFromHelper(name: string): { name: string; fileType: string; documentType: string; viewUrl?: string } {
        return this.queueItemHelper.find((x) => x.name === name);
    }

    ngOnInit(): void {
        this.getFileType();
        this.listDocument();
    }

    getFileType() {
        this._documentFileTypeService.getDocumentFileTypes(this.pageFilter).subscribe((res) => {
            if (res.results) {
                this.selectbox = res.results;
            }
        });
    }

    listDocument(): void {
        this._documentTypeService.getAllApi(this.pageFilter).subscribe(res => {
            if (res.results) {
                this.docTypes = res.results;
            }
        });
    }

    onFileChanged(event) {
        const file = event.target.files[0];
        this.file = file;
        const fileTypeName = this.selectbox.find((x) => x.id === this.selectedFileType).title;
        const documentTypeName = this.docTypes.find((x) => x.id === this.selectedType).title;
        const queueItem = this.uploader.queue.find((x) => x.file.name === this.file.name);

        const reader = new FileReader();
        reader.onload = () => {
            const binaryData = [];
            binaryData.push(reader.result as string);
            const viewUrl = window.URL.createObjectURL(new Blob(binaryData, {type: file.type}));
            this.queueItemHelper.push({
                name: queueItem.file.name,
                documentType: documentTypeName,
                fileType: fileTypeName,
                viewUrl,
            });
        };

        reader.readAsDataURL(file);
    }

    uploadFile() {
        const uploadFile = new FormData();
        uploadFile.append('files', this.file);
        const currentFile = this.uploader.queue.find((x) => x.file.name === this.file.name);
        const fileHelper = this.queueItemHelper.find((x) => x.name === this.file.name);

        currentFile.isUploading = false;
        currentFile.isUploaded = true;
        currentFile.isSuccess = true;
        currentFile.progress = 100;
        currentFile.isUploading = true;
        this._fileUploaderService.uploadDocumentFile(uploadFile).subscribe((res) => {
            const fileResponse: DocumentModel = {
                path: res.results.path,
                contentType: res.results.contentType,
                companyId: null,
                documentFileTypeId: this.selectbox.find((x) => x.title === fileHelper.fileType).id,
                documentTypeId: this.docTypes.find((x) => x.title === fileHelper.documentType).id,
                employeeId: this.employeeId ?? null,
                employeeCanView: true,
                title: fileHelper.fileType,
                isArchive: false,
            };
            this._toastService.success(`${fileResponse.title} uploaded.`, 'File Uploaded');
            if (this.shouldAddAfterUpload) {
                this.addDocument.emit(fileResponse);
            }
            this.uploadedDocuments.push(fileResponse);
        });
    }
}
