export interface EmployeeEntryModel {
    entryType: EntryType;
    userId: number;
    verifyDate: Date;
    verifyState: number;
    verifyType: number;
}

export enum EntryType {
    ENTRANCE = 1,
    EXIT = 2,
    MANUEL_ENTRANCE = 3,
    MANUEL_EXIT = 4
}
