<div class="content-wrapper">
  <div class="content-body">
    <ngb-alert [type]="'warning'" [dismissible]="false">
      <div class="alert-body">
        <strong>{{ 'fileUploader.note' | translate }}:</strong> {{ 'fileUploader.uploadNote' | translate }}
      </div>
    </ngb-alert>
    <section id="file-upload">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3">
              <h4>{{ 'fileUploader.selectFiles' | translate }}</h4>
              <ng-select
                [(ngModel)]="selectedFileType"
                [items]="selectbox"
                bindLabel="title"
                bindValue="id"
                placeholder="{{ 'fileUploader.selectFiles' | translate }}"
              >
              </ng-select>

              <h4>{{ 'fileUploader.selectDocTypes' | translate }}</h4>
              <ng-select
                [(ngModel)]="selectedType"
                [items]="docTypes"
                bindLabel="title"
                bindValue="id"
                placeholder="{{ 'fileUploader.selectDocTypes' | translate }}"
              >
              </ng-select>
              <fieldset *ngIf="selectedType" class="form-group">
                <label for="file-upload-single">Single:</label>
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    type="file"
                    ng2FileSelect
                    (change)="onFileChanged($event)"
                    [uploader]="uploader"
                    id="file-upload-single"
                  />
                  <label class="custom-file-label">{{ 'fileUploader.choseeFile' | translate }}</label>
                </div>
              </fieldset>
            </div>
            <div class="col-lg-9 overflow-auto">
              <table class="table">
                <thead>
                  <tr>
                    <th width="50%">{{ 'fileUploader.name' | translate }}</th>
                    <th>{{ 'fileUploader.type' | translate }}</th>
                    <th>{{ 'fileUploader.size' | translate }}</th>
                    <th>{{ 'fileUploader.progress' | translate }}</th>
                    <th>{{ 'fileUploader.status' | translate }}</th>
                    <th>{{ 'fileUploader.actions' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of uploader.queue">
                    <td>
                      <strong>{{ item?.file?.name }}</strong>
                    </td>
                    <td>{{ getQueueItemFromHelper(item.file.name)?.fileType }}</td>
                    <td *ngIf="uploader.options.isHTML5" nowrap>
                      {{ item?.file?.size / 1024 / 1024 | number: '.2' }} MB
                    </td>
                    <td *ngIf="uploader.options.isHTML5">
                      <ngb-progressbar type="primary" [value]="item.progress"></ngb-progressbar>
                    </td>
                    <td class="text-center">
                      <span *ngIf="item.isSuccess"><i data-feather="check"></i></span>
                      <span *ngIf="item.isCancel"><i data-feather="slash"></i></span>
                      <span *ngIf="item.isError"><i data-feather="x-circle"></i></span>
                    </td>
                    <td nowrap>
                      <button
                        type="button"
                        class="btn btn-raised btn-outline-success btn-sm mr-50"
                        (click)="uploadFile()"
                        rippleEffect
                      >
                        <span data-feather="upload"></span>
                      </button>
                      <button
                        type="button"
                        class="btn btn-raised btn-outline-warning btn-sm mr-50"
                        (click)="item.cancel()"
                        [disabled]="!item.isUploading"
                        rippleEffect
                      >
                        <i data-feather="slash"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-raised btn-outline-danger btn-sm"
                        (click)="item.remove()"
                        rippleEffect
                      >
                        <i data-feather="trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
