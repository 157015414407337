import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseResponse} from 'app/main/data/base-response.model';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs';
import {DocumentFileTypeModel, uploadDocumentFileModel} from './models/file.model';

@Injectable({
    providedIn: 'root',
})
export class FileUploaderService {
    constructor(private http: HttpClient) {
    }

    getDocumentType(): Observable<BaseResponse<DocumentFileTypeModel[]>> {
        return this.http.get<BaseResponse<DocumentFileTypeModel[]>>(`${environment.apiUrl}document-service/document-types`);
    }

    uploadDocumentFile(file: FormData): Observable<BaseResponse<uploadDocumentFileModel>> {
        return this.http.post<BaseResponse<uploadDocumentFileModel>>(
            `${environment.apiUrl}document-service/documents/upload`,
            file
        );
    }
}
