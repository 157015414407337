import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseResponse} from 'app/main/data/base-response.model';
import {BaseService} from 'app/main/shared/base/services/base.service';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs';
import {LeaveFormResponseModel} from '../leave-form/data/models/leave-form-response.model';
import {CardEntryModel} from './data/models/employee-card-entry-model';
import {EmployeeRequest} from './data/models/employee-request.model';
import {EmployeeResponse} from './data/models/employee.model';

@Injectable({
    providedIn: 'root',
})

export class EmployeeService extends BaseService<EmployeeRequest, EmployeeResponse> {
    constructor(private http: HttpClient) {
        super(http, 'employee-service/employees');
    }

    getEmployee(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}employee-service/employees`);
    }

    getEmployeeDetail(id: any): Observable<BaseResponse<EmployeeResponse>> {
        return this.getApi(id);
    }

    addEmployee(employee: EmployeeRequest): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}employee-service/employees`, employee);
    }

    uploadPhoto(photo): Observable<BaseResponse<any>> {
        return this.http.post<BaseResponse<any>>(`${environment.apiUrl}employee-service/employees/photo`, photo);
    }

    getPhoto(id: number): Observable<any> {
        if (!id) {
            return new Observable<any>();
        }
        return this.http.get(`${environment.apiUrl}employee-service/employees/${id}/photo`, {
            responseType: 'blob',
        });
    }

    editEmployee(employee: EmployeeRequest, id: number): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}employee-service/employees/${id}`, employee);
    }

    getLeaves(id: number): Observable<BaseResponse<LeaveFormResponseModel[]>> {
        return this.http.get<BaseResponse<LeaveFormResponseModel[]>>(
            `${environment.apiUrl}employee-service/employees/${id}/leaves`
        );
    }

    changeEmployeeActivatedStatus(id: number, status: boolean): Observable<BaseResponse<EmployeeResponse>> {
        return this.http.put<BaseResponse<EmployeeResponse>>(
            `${environment.apiUrl}employee-service/employees/${id}/status`,
            {isActive: status}
        );
    }

    addCard(request: CardEntryModel): Observable<BaseResponse<CardEntryModel>> {
        return this.http.post<BaseResponse<CardEntryModel>>(
            `${environment.apiUrl}employee-service/employee-entries/cards`,
            request
        );
    }

    changeCard(request: CardEntryModel): Observable<BaseResponse<CardEntryModel>> {
        return this.http.put<BaseResponse<CardEntryModel>>(
            `${environment.apiUrl}employee-service/employee-entries/cards/${request.userId}`,
            request
        );
    }
}
