import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
	selector: 'masked-date-input',
	template: `
		<input
			class="form-control "
			[formlyAttributes]="field"
			[formControl]="formControl"
			[ngClass]="{ 'is-invalid': formControl.status === 'INVALID' && formControl.touched === true }"
			type="input"
			mask="00-00-0000"
		/>
	`,
})
export class MaskedDateInputComponent extends FieldWrapper {}
