import {Injectable} from '@angular/core';
import {BaseService} from '../../../../shared/base/services/base.service';
import {HttpClient} from '@angular/common/http';
import {DefinitionRequest} from '../model/definition/definition-request.model';
import {DefinitionResponse} from '../model/definition/definition-response.model';

@Injectable({
    providedIn: 'root'
})
export class FormService extends BaseService<DefinitionRequest, DefinitionResponse> {

    constructor(private http: HttpClient) {
        super(http, 'form-service/definitions');
    }
}
