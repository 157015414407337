import {Observable} from 'rxjs';

export class FilterBase<T> {
    class: string;
    value: any;
    key: string;
    label: string;
    labelClass: string;
    bindLabel: string;
    required: boolean;
    controlType: string;
    type: string;
    options: { key: string; value: string }[];
    observable: Observable<any>;
    bindValue: string;
    multiple: boolean;
    placeholder: string;
    mask: string;

    constructor(
        options: {
            value?: T;
            key?: string;
            label?: string;
            required?: boolean;
            controlType?: string;
            type?: string;
            options?: { key: string; value: string }[];
            class?: string;
            labelClass?: string;
            bindLabel?: string;
            observable?: Observable<any>;
            bindValue?: string;
            multiple?: boolean;
            placeHolder?: string;
            mask?: string;
        } = {},
    ) {
        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.controlType = options.controlType || '';
        this.type = options.type || '';
        this.options = options.options || [];
        this.class = options.class || '';
        this.labelClass = options.labelClass || '';
        this.bindLabel = options.bindLabel || '';
        this.observable = options.observable || null;
        this.bindValue = options.bindValue || '';
        this.multiple = options.multiple || false;
        this.placeholder = options.placeHolder || '';
        this.mask = options.mask || '';
    }
}
