import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse } from 'app/main/data/base-response.model';
import { BaseService } from 'app/main/shared/base/services/base.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { LeaveFormRequestModel } from '../models/leave-form-request.model';
import { LeaveFormResponseModel } from '../models/leave-form-response.model';
import { LeaveFormModel } from '../models/leave-form.model';
import {TotalLeaveTimeModel} from '../models/total-leave-time.model';

@Injectable({
  providedIn: 'root',
})
export class LeaveFormService extends BaseService<LeaveFormRequestModel, LeaveFormResponseModel> {
  constructor(private http: HttpClient) {
    super(http, 'leave-service/leaves');
  }

  getLeaves(id: string): Observable<BaseResponse<LeaveFormResponseModel>> {
    return this.getApi(id);
  }

  getTotalLeaveTime(id: string): Observable<BaseResponse<TotalLeaveTimeModel>> {
    return this.http.get<BaseResponse<TotalLeaveTimeModel>>(`${environment.apiUrl}leave-service/leaves/${id}/total-leave-time`);
  }

  getAllLeaves(): Observable<BaseResponse<LeaveFormResponseModel[]>> {
    return this.http.get<BaseResponse<LeaveFormResponseModel[]>>(`${environment.apiUrl}leave-service/leaves/all`);
  }

  addLeaves(leave: LeaveFormRequestModel): Observable<BaseResponse<LeaveFormModel>> {
    return this.http.post<BaseResponse<LeaveFormModel>>(`${environment.apiUrl}leave-service/leaves`, leave);
  }
}
