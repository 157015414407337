import { HttpParams } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { FilterConfig } from 'app/layout/components/filter/interfaces/filter.config';
import { merge } from 'lodash';
import { Router } from '@angular/router';
import { PageFilter } from './base/models/pageFilter.model';
export const listRowHeight = '38';

export function getRequestParams(
  filterModel,
  paging: { page: string; pageSize: string },
  sorting: { sortBy: 0 | 1; sort: string }
) {
  let params = new HttpParams();
  if (filterModel) {
    Object.keys(filterModel).forEach((key) => {
      if (filterModel[key] != null && filterModel[key] != '') params = params.append(key, filterModel[key]);
    });
  }

  if (sorting) {
    if (sorting.sort != '') {
      params = params.append('sortBy', sorting.sortBy.toString());
      params = params.append('sort', sorting.sort);
    }
  }

  if (paging) {
    params = params.append('pageSize', paging.pageSize);
    params = params.append('page', paging.page);
  }

  return params;
}

export function generateModel(fg: FormGroup): any;
export function generateModel(fg: FormGroup, id: any): any;
export function generateModel(fg: FormGroup, id: any, toMerge: any): any;

export function generateModel(fg: FormGroup, id?: any, toMerge?: any): any {
  let obj = Object.assign({}, fg.value);
  if (toMerge) {
    obj = merge(toMerge, obj);
  }

  if (!id) {
    return obj;
  } else {
    obj.id = id;
    return obj;
  }
}

export const getPageAndSizeParams = (data: PageFilter): HttpParams => {
  let params = new HttpParams();
  if (data) {
    if (data.index) {
      params = params.append('Index', data.index.toString());
    }
    if (data.count) {
      params = params.append('Count', data.count.toString());
    }
    if (data.descending != null) {
      params = params.append('Descending', data.descending.toString());
    }
    if (data.column[0]) {
      data.column.map((item) => {
        params = params.append('Column', item);
      });
    }
    if (data.search) {
      params = params.append('Search', data.search.toString());
    }

    if (data.optionalParameters && data.optionalParameters[0]) {
      data.optionalParameters.forEach((param) => {
        params = params.append(param.paramName, param.paramData);
      });
    }
  }
  return params;
};

export function fillFormValues(fg: FormGroup, data: any) {
  fg.patchValue(data);
  return fg;
}

export const getLocalStorage = (params: Array<string>): any => {
  if (params.length === 1) {
    return deSerialize(localStorage.getItem(params[0]));
  }

  const results = {};
  params.forEach((key) => {
    results[key] = deSerialize(localStorage.getItem(key));
  });
  return results;
};

export const removeLocalStorage = (params: Array<string>): void => {
  params.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const setLocalStorage = (params: { key: string; value: any }): void => {
  localStorage.setItem(params.key, JSON.stringify(params.value));
};

export function fillFilterData(filterConfig: FilterConfig<string>[], filterModel): FilterConfig<string>[] {
  filterConfig.forEach((row) => {
    if (filterModel) {
      Object.keys(filterModel).forEach((key) => {
        row.formGroups.forEach((group) => {
          if (group.control.key == key) {
            group.control.value = filterModel[key];
          }
        });
      });
      return filterConfig;
    }
  });
  return filterConfig;
}

export const serialize = (data: any): string => JSON.stringify(data);
export const deSerialize = (data: string): any => JSON.parse(data);

export function checkPermission(perm, pageperm, nereye, router: Router) {}
