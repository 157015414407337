import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { CoreCommonModule } from '@core/common.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreSidebarModule } from '@core/components';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { EmployeeViewComponent } from './employee-view/employee-view.component';
import { EmployeeEditComponent } from './employee-edit/employee-edit.component';
import { EmployeeAddComponent } from './employee-add/employee-add.component';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { FileUploadModule } from 'ng2-file-upload';
import { SharedModule } from 'app/main/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

// routing
const routes: Routes = [
  {
    path: '',
    component: EmployeeListComponent,
  },
  {
    path: 'employee-add',
    component: EmployeeAddComponent,
  },
  {
    path: 'employee-view/:id',
    component: EmployeeViewComponent,
  },
  {
    path: 'employee-edit/:id',
    component: EmployeeEditComponent,
  },
];

@NgModule({
  declarations: [EmployeeListComponent, EmployeeViewComponent, EmployeeEditComponent, EmployeeAddComponent],
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    CorePipesModule,
    CoreDirectivesModule,
    CoreSidebarModule,
    ContentHeaderModule,
    FileUploadModule,
    TranslateModule,
  ],
  providers: [],
  exports: [EmployeeListComponent, EmployeeViewComponent, EmployeeEditComponent],
})
export class EmployeeModule {}
