export enum BloodGroup {
  ZERORHPOSITIVE = 1,
  ZERORHNEGATIVE = 2,
  ARHPOSITIVE = 3,
  ARHNEGATIVE = 4,
  BRHPOSITIVE = 5,
  BRHNEGATIVE = 6,
  ABRHPOSITIVE = 7,
  ABRHNEGATIVE = 8,
  UNSPECIFIED = 100,
}
