import { Component, EventEmitter, Input, OnInit, Output }  from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { FlatpickrInstance } from 'ng2-flatpickr/src/flatpickr-instance';
import moment from 'moment';

export interface DateTimePicker {
    selectedDate: Date;
    selectedDateString: string;
    instance: FlatpickrInstance;
}

@Component({
    selector: 'app-date-time-picker',
    styleUrls: ['./date-time-picker.component.scss'],
    template: `
        <ng-container *ngIf="hasContainer;then withParent else withoutParent"></ng-container>
        <ng-template #withParent>
            <div [ngClass]="parentClass">
                <ng-container [ngTemplateOutlet]="content"></ng-container>
            </div>
        </ng-template>
        <ng-template #withoutParent>
            <ng-container [ngTemplateOutlet]="content"></ng-container>
        </ng-template>
        <ng-template #content>
            <ng2-flatpickr
                ngbDatepicker
                name="dateTime"
                [placeholder]="placeholder | translate"
                [config]="_dateTimeOptions"
                [(ngModel)]="currentDate"
            >
            </ng2-flatpickr>
        </ng-template>
    `,
})
export class DateTimePickerComponent extends FieldType implements OnInit {
    @Input()
    enable = true;

    @Input()
    setDate = false;

    @Input()
    hasContainer = false;

    @Input()
    parentClass = 'bg-white';

    @Input()
    placeholder = 'reports.calendar.label';

    @Input()
    dateTimeOptions: FlatpickrOptions;
    
    @Output()
    calenderSelection = new EventEmitter<DateTimePicker>();

    _dateTimeOptions: FlatpickrOptions;
    currentDate: Date = null;
    
    ngOnInit(): void {
        this.setDateTimeOptions(this.dateTimeOptions || this._dateTimeOptions)
    }

    private setDateTimeOptions(value: FlatpickrOptions) {
        const defaults: FlatpickrOptions = {
            altInput: true,
            enableTime: !this.enable,
            dateFormat: 'YYYY-MM-DD',
            defaultDate: new Date(),
            altFormat: 'DD-MM-YYYY',
            wrap: true,
            def: false,
            onChange: ([selectedDate]: [Date], selectedDateString: string, instance: FlatpickrInstance, ...args: any): Date => {
                this.calenderSelection.emit({ selectedDate, selectedDateString, instance });
                this.currentDate = selectedDate;
                return this.currentDate;
            },
            parseDate: (datestr, format) => {
                return moment(datestr, format, true).toDate();
            },
            formatDate: (date, format, locale) => {
                // locale can also be used
                return moment(date).format(format);
            },
        };

        this._dateTimeOptions = (value && { ...defaults, ...value }) || defaults;
    }
}
