<ng-template #modalVC let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">{{'remainingWork.employeeEntryDetails' |translate}}</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <app-employee-entries-table [entries]="employeeEntries"></app-employee-entries-table>
    </div>
</ng-template>
