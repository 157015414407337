<div class="content-wrapper">
    <div class="content-body">
        <section class="horizontal-wizard">
            <div id="stepper1" class="bs-stepper horizontal-wizard-example" *ngIf="employee$ | async as employeeModel">
                <div class="bs-stepper-header">
                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#personal-info">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">1</span>
                            <span class="bs-stepper-label">
                <span class="bs-stepper-title">{{ 'employee.personalInformation' | translate }}</span>
              </span>
                        </button>
                    </div>
                    <div class="step" data-target="#company-info">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">2</span>
                            <span class="bs-stepper-label">
                <span class="bs-stepper-title">{{ 'employee.companyInformation' | translate }}</span>
              </span>
                        </button>
                    </div>
                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#other-information">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">3</span>
                            <span class="bs-stepper-label">
                <span class="bs-stepper-title">{{ 'employee.otherInformation' | translate }}</span>
              </span>
                        </button>
                    </div>
                    <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                    <div class="step" data-target="#emergency-info">
                        <button class="step-trigger">
                            <span class="bs-stepper-box">4</span>
                            <span class="bs-stepper-label">
                <span class="bs-stepper-title">{{ 'employee.emergencyInformation' | translate }}</span>
              </span>
                        </button>
                    </div>
                </div>
                <div class="bs-stepper-content">
                    <form [formGroup]="employeeForm">
                        <div id="user-information" class="content">
                            <ng-container #accountDetailsForm>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label class="form-label"
                                               for="username">{{ 'general.email' | translate }}</label>
                                        <input
                                                required
                                                type="email"
                                                formControlName="username"
                                                name="username"
                                                id="username"
                                                class="form-control"
                                                [class.error]="!accountDetailsForm.valid && accountDetailsForm.submitted"
                                        />
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="role" required>{{ 'general.role' | translate }}</label>
                                            <select class="form-control" name="role" id="role" formControlName="role">
                                                <option value="{{ roleEnum.SystemAdmin }}">System Admin</option>
                                                <option value="{{ roleEnum.HrManager }}">HR Manager</option>
                                                <option value="{{ roleEnum.User }}">User</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button
                                            (click)="horizontalWizardStepperPrevious()"
                                            class="btn btn-outline-secondary btn-prev"
                                            disabled
                                            rippleEffect
                                    >
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.previous' | translate }}</span>
                                    </button>
                                    <button
                                            (click)="horizontalWizardStepperNext(accountDetailsForm)"
                                            class="btn btn-primary btn-next"
                                            rippleEffect
                                    >
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.next' | translate }}</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                        <div id="personal-info" class="content">
                            <ng-container #personalInfoForm>
                                <div class="media mb-2">
                                    <div *ngIf="profilePhotoPath && profilePhotoPath !== ''">
                                        <img
                                                class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer image-fluid"
                                                [src]="profilePhotoPath"
                                                height="90"
                                                alt="User avatar"
                                        />
                                    </div>
                                    <div *ngIf="!profilePhotoPath || profilePhotoPath === ''" class="dotted-border">
                                        <img
                                                class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer image-fluid ml-sm-75"
                                                height="90"
                                                [src]="'/assets/images/avatars/user-avatar.png'"
                                        />
                                    </div>
                                    <ng-template #customAvatar>
                                        <div
                                                class="mr-1 ml-0"
                                                [ngClass]="{
                        'bg-light-success': employee.profilePhotoPath == 'active',
                        'bg-light-secondary': employee.profilePhotoPath == 'inactive',
                        'bg-light-warning': employee.profilePhotoPath == 'pending'
                      }"
                                        >
                                            <div class="rounded p-3">{{ employee.name | initials }}</div>
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="media-body mt-50">
                                    <h4>{{ employee?.name }}</h4>
                                    <div class="col-12 d-flex mt-1 px-0">
                                        <label class="btn btn-primary mr-75 mb-0" for="change-picture">
                                            <span class="d-none d-sm-block">{{ 'general.change' | translate }}</span>
                                            <input
                                                    (change)="onFileChanged($event)"
                                                    class="form-control"
                                                    type="file"
                                                    id="change-picture"
                                                    hidden
                                                    accept="image/png, image/jpeg, image/jpg"
                                            />
                                            <span class="d-block d-sm-none">
                        <i class="mr-0" data-feather="edit"></i>
                      </span>
                                        </label>
                                        <button class="btn btn-outline-secondary d-none d-sm-block"
                                                (click)="uploadPhoto()" rippleEffect>
                                            {{ 'general.upload' | translate }}
                                        </button>
                                        <button class="btn btn-outline-secondary d-block d-sm-none">
                                            <i class="mr-0" data-feather="trash-2"></i>
                                        </button>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="card-id">{{ 'employee.cardUserId' | translate }}</label>
                                            <input
                                                    id="cardId"
                                                    formControlName="cardId"
                                                    type="number"
                                                    class="form-control birthdate-picker"
                                                    name="cardId"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="name">{{ 'employee.name' | translate }}*</label>
                                            <input
                                                    id="name"
                                                    formControlName="name"
                                                    type="text"
                                                    class="form-control birthdate-picker"
                                                    name="name"
                                                    required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="birth">{{ 'employee.surname' | translate }}*</label>
                                            <input
                                                    id="surname"
                                                    formControlName="surname"
                                                    type="text"
                                                    [class.error]="f.surname.touched && f.surname.errors"
                                                    class="form-control birthdate-picker"
                                                    name="surname"
                                                    required
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4 col-md-6">
                                        <label class="form-label"
                                               for="id-number">{{ 'employee.idNumber' | translate }}*</label>
                                        <input
                                                required
                                                type="text"
                                                name="identificationNumber"
                                                formControlName="identificationNumber"
                                                id="id-number"
                                                class="form-control"
                                        />
                                        <span *ngIf="personalInfoForm.submitted && personalInfoForm.invalid"
                                              class="invalid-form"> </span>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="status">{{ 'employee.gender' | translate }}</label>
                                            <select class="form-control" name="gender" formControlName="gender"
                                                    id="gender">
                                                <option value="{{ genderEnum.Male }}">{{ 'employee.male' | translate }}</option>
                                                <option value="{{ genderEnum.Woman }}">{{ 'employee.female' | translate }}</option>
                                                <option value="{{ genderEnum.Other }}">{{ 'employee.other' | translate }}</option>
                                                <option value="{{ genderEnum.Unspecified }}">{{ 'employee.unspecified' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="birth">{{ 'employee.maxLeaveDay' | translate }}</label>
                                            <input
                                                    formControlName="maximumLeaveDay"
                                                    id="birth"
                                                    type="text"
                                                    class="form-control birthdate-picker"
                                                    name="maximumLeaveDay"
                                            />

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="birth">{{ 'employee.birthDate' | translate }}*</label>
                                            <input
                                                    type="date"
                                                    id="birth"
                                                    class="form-control birthdate-picker"
                                                    name="dateOfBirth"
                                                    formControlName="dateOfBirth"
                                                    placeholder="YYYY-MM-DD"
                                                    required
                                                    ngbDatepicker
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="personal-mail">{{ 'employee.personalMailAddress' | translate }}</label>
                                            <input
                                                    id="personal-mail"
                                                    type="text"
                                                    class="form-control birthdate-picker"
                                                    name="personalMailAddress"
                                                    formControlName="personalMailAddress"
                                                    placeholder="Personal Mail Address"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="company">{{ 'employee.personalPhoneNumber' | translate }}
                                                *</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Personal Phone Number"
                                                    id="company"
                                                    name="personalPhoneNumber"
                                                    formControlName="personalPhoneNumber"
                                                    required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="status">{{ 'employee.educationStatus' | translate }}*</label>
                                            <select
                                                    class="form-control"
                                                    name="educationStatus"
                                                    formControlName="educationStatus"
                                                    id="educationStatus"
                                                    required
                                            >
                                                <option value="{{ educationStatusEnum.GRADUATED }}">
                                                    {{ 'employee.graduated' | translate }}
                                                </option>
                                                <option value="{{ educationStatusEnum.STUDENT }}">{{ 'employee.student' | translate }}</option>
                                                <option value="{{ educationStatusEnum.UNSPECIFIED }}">
                                                    {{ 'employee.unspecified' | translate }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="company">{{ 'employee.educationInstitution' | translate }}
                                                *</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Education Institution"
                                                    id="educationalInstitution"
                                                    name="educationalInstitution"
                                                    formControlName="educationalInstitution"
                                                    required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="educationLevel">{{ 'employee.educationLevel' | translate }}
                                                *</label>
                                            <select
                                                    class="form-control"
                                                    name="educationLevel"
                                                    formControlName="educationLevel"
                                                    id="educationLevel"
                                                    required
                                            >
                                                <option value="{{ educationLevelEnum.none }}">{{ 'employee.none' | translate }}</option>
                                                <option value="{{ educationLevelEnum.primarySchool }}">
                                                    {{ 'employee.primarySchool' | translate }}
                                                </option>
                                                <option value="{{ educationLevelEnum.middleSchool }}">
                                                    {{ 'employee.middleSchool' | translate }}
                                                </option>
                                                <option value="{{ educationLevelEnum.highSchool }}">
                                                    {{ 'employee.highSchool' | translate }}
                                                </option>
                                                <option value="{{ educationLevelEnum.associate }}">
                                                    {{ 'employee.associate' | translate }}
                                                </option>
                                                <option value="{{ educationLevelEnum.underGraduate }}">
                                                    {{ 'employee.underGraduate' | translate }}
                                                </option>
                                                <option value="{{ educationLevelEnum.postGraduate }}">
                                                    {{ 'employee.postGraduate' | translate }}
                                                </option>
                                                <option value="{{ educationLevelEnum.doctorate }}">
                                                    {{ 'employee.doctorate' | translate }}
                                                </option>
                                                <option value="{{ educationLevelEnum.unspecified }}">
                                                    {{ 'employee.unspecified' | translate }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="status">{{ 'employee.maritalStatus' | translate }}</label>
                                            <select
                                                    class="form-control"
                                                    name="maritalStatus"
                                                    formControlName="maritalStatus"
                                                    id="maritalStatus"
                                            >
                                                <option value="{{ maritalStatusEnum.SINGLE }}">{{ 'employee.single' | translate }}</option>
                                                <option value="{{ maritalStatusEnum.MARRIED }}">{{ 'employee.married' | translate }}</option>
                                                <option value="{{ maritalStatusEnum.DIVORCED }}">{{ 'employee.divorced' | translate }}</option>
                                                <option value="{{ maritalStatusEnum.UNSPECIFIED }}">
                                                    {{ 'employee.unspecified' | translate }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="status">{{ 'employee.bloodGroup' | translate }}</label>
                                            <select class="form-control" name="bloodGroup" formControlName="bloodGroup"
                                                    id="bloodGroup">
                                                <option value="{{ bloodTypeEnum.ZERORHPOSITIVE }}">0 RH(+)</option>
                                                <option value="{{ bloodTypeEnum.ZERORHNEGATIVE }}">0 RH(-)</option>
                                                <option value="{{ bloodTypeEnum.ARHPOSITIVE }}">A RH(+)</option>
                                                <option value="{{ bloodTypeEnum.ARHNEGATIVE }}">A RH(-)</option>
                                                <option value="{{ bloodTypeEnum.BRHPOSITIVE }}">B RH(+)</option>
                                                <option value="{{ bloodTypeEnum.BRHNEGATIVE }}">B RH(-)</option>
                                                <option value="{{ bloodTypeEnum.ABRHPOSITIVE }}">AB RH(+)</option>
                                                <option value="{{ bloodTypeEnum.ABRHNEGATIVE }}">AB RH(-)</option>
                                                <option value="{{ bloodTypeEnum.UNSPECIFIED }}">Unspecified</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="company">{{ 'employee.numberOfChildren' | translate }}</label>
                                            <input
                                                    type="number"
                                                    class="form-control"
                                                    placeholder="Number of Children"
                                                    id="numberOfChildren"
                                                    name="numberOfChildren"
                                                    formControlName="numberOfChildren"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="role">{{ 'employee.militaryStatus' | translate }}</label>
                                            <select
                                                    class="form-control"
                                                    [formControlName]="'militaryService'"
                                                    [(ngModel)]="employee.militaryService"
                                                    name="militaryStatus"
                                                    id="militaryStatus"
                                            >
                                                <option value="{{ militaryEnum.DONE }}">{{ 'employee.done' | translate }}</option>
                                                <option value="{{ militaryEnum.UNDONE }}">{{ 'employee.undone' | translate }}</option>
                                                <option value="{{ militaryEnum.EXEMPT }}">{{ 'employee.exempt' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-prev"
                                            rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.previous' | translate }}</span>
                                    </button>
                                    <button
                                            (click)="horizontalWizardStepperNext('personal')"
                                            class="btn btn-primary btn-next"
                                            rippleEffect
                                    >
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.next' | translate }}</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                        <div id="company-info" class="content">
                            <ng-container #companyInfoForm>
                                <div class="row">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="title">{{ 'employee.title' | translate }}*</label>
                                            <input
                                                    id="title"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Front-end Developer"
                                                    name="title"
                                                    formControlName="title"
                                                    required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="data-of-work">{{ 'employee.dateOfStartWork' | translate }}
                                                *</label>
                                            <input
                                                    type="date"
                                                    class="form-control"
                                                    placeholder="YYYY-MM-DD"
                                                    id="dateOfStart"
                                                    name="dateOfStart"
                                                    formControlName="dateOfStart"
                                                    ngbDatepicker
                                                    required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="contractType">{{ 'employee.contractType' | translate }}*</label>
                                            <select required class="form-control" name="contractType"
                                                    formControlName="contractType" id="contractType">
                                                <option value="{{ contractEnum.FULL_TIME }}">
                                                    {{ 'employee.full_time' | translate }}
                                                </option>
                                                <option value="{{ contractEnum.PART_TIME }}">
                                                    {{ 'employee.part_time' | translate }}
                                                </option>
                                                <option value="{{ contractEnum.TEMPORARY }}">
                                                    {{ 'employee.temporary' | translate }}
                                                </option>
                                                <option value="{{ contractEnum.SEASONAL }}">
                                                    {{ 'employee.seasonal' | translate }}
                                                </option>
                                                <option value="{{ contractEnum.CONSULTANT }}">
                                                    {{ 'employee.consultant' | translate }}
                                                </option>
                                                <option value="{{ contractEnum.UNSPECIFIED }}">{{ 'employee.unspecified' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="birth">{{ 'employee.contractEndDate' | translate }}</label>
                                            <input
                                                    type="date"
                                                    id="contractEndDate"
                                                    class="form-control birthdate-picker"
                                                    name="contractEndDate"
                                                    formControlName="contractEndDate"
                                                    placeholder="YYYY-MM-DD"
                                                    ngbDatepicker
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="birth">{{ 'employee.companyMailAddress' | translate }}*</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="info@amatis.nl"
                                                    id="companyMailAddress"
                                                    name="companyMailAddress"
                                                    formControlName="companyMailAddress"
                                                    required
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="birth">{{ 'employee.companyPhoneNumber' | translate }}</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="+90 (232) 503 88 88"
                                                    id="companyPhoneNumber"
                                                    name="companyPhoneNumber"
                                                    formControlName="companyPhoneNumber"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <app-file-uploader shouldAddAfterUpload="true"
                                                   (addDocument)="addDocument($event)"></app-file-uploader>
                                <ngb-alert [type]="'warning'" [dismissible]="false">
                                    <div class="alert-body">
                                        <strong>You can see the documents upload the employee from below.</strong>
                                    </div>
                                </ngb-alert>
                                <div class="content-wrapper">
                                    <div class="content-body">
                                        <section class="users-list-wrapper">
                                            <div class="card">
                                                <ngx-datatable
                                                        [rows]="documentsList"
                                                        [rowHeight]="50"
                                                        class="bootstrap core-bootstrap"
                                                        [limit]="selectedOption"
                                                        [columnMode]="ColumnMode.force"
                                                        [headerHeight]="50"
                                                        [footerHeight]="50"
                                                        [scrollbarH]="true"
                                                >
                                                    <ngx-datatable-column name="Type" [width]="50"
                                                    >
                                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                                            {{ row.title }}
                                                        </ng-template>
                                                    </ngx-datatable-column
                                                    >
                                                    <ngx-datatable-column name="Name" [width]="50" class="text-ellipsis"
                                                    >
                                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                                            <span class="ellipsis">{{ row.path }} </span>
                                                        </ng-template>
                                                    </ngx-datatable-column
                                                    >
                                                    <ngx-datatable-column name="Doc. Type" [width]="50"
                                                    >
                                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                                            <span class="ellipsis"> {{ row.documentType.title }}</span>
                                                        </ng-template>
                                                    </ngx-datatable-column
                                                    >
                                                    <ngx-datatable-column name="Action" [width]="20">
                                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                                            <label>
                                                                <button
                                                                        type="button"
                                                                        class="btn btn-raised btn-outline-success btn-sm mr-50"
                                                                        (click)="downloadDocument(row.id)"
                                                                        rippleEffect
                                                                >
                                                                    <span data-feather="download"></span>
                                                                </button>
                                                            </label>
                                                            <label>
                                                                <button
                                                                        type="button"
                                                                        class="btn btn-raised btn-outline-danger btn-sm mr-50"
                                                                        (click)="('')"
                                                                        rippleEffect
                                                                >
                                                                    <span data-feather="trash-2"></span>
                                                                </button>
                                                            </label>
                                                        </ng-template>
                                                    </ngx-datatable-column>
                                                </ngx-datatable>
                                            </div>
                                        </section>
                                    </div>

                                    <core-sidebar
                                            class="modal modal-slide-in sidebar-todo-modal fade"
                                            name="employee-edit-sidebar"
                                            overlayClass="modal-backdrop"
                                    >
                                    </core-sidebar>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-prev"
                                            rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.previous' | translate }}</span>
                                    </button>
                                    <button
                                            (click)="horizontalWizardStepperNext('company')"
                                            class="btn btn-primary btn-next"
                                            rippleEffect
                                    >
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.next' | translate }}</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                        <div id="other-information" class="content">
                            <ng-container #otherInfoForm>
                                <div class="row">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="address-1">{{ 'employee.addressLine1' | translate }}</label>
                                            <input
                                                    id="address1"
                                                    type="text"
                                                    class="form-control"
                                                    value=""
                                                    placeholder="A-65, Belvedere Streets"
                                                    name="address1"
                                                    formControlName="address1"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="address-2">{{ 'employee.addressLine2' | translate }}</label>
                                            <input
                                                    id="address2"
                                                    name="address2"
                                                    formControlName="address2"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="T-78, Groove Street"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="postcode">{{ 'employee.bankName' | translate }}</label>
                                            <input
                                                    id="bankName"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Garanti"
                                                    name="bankName"
                                                    formControlName="bankName"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="accountnumber">{{ 'employee.accountNumber' | translate }}</label>
                                            <input
                                                    id="accountNumber"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="0000"
                                                    name="accountNumber"
                                                    formControlName="accountNumber"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="iban">{{ 'employee.iban' | translate }}</label>
                                            <input
                                                    id="iban"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="TR 0000 00000 00000 0000 00 00"
                                                    name="iban"
                                                    formControlName="iban"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-prev"
                                            rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.previous' | translate }}</span>
                                    </button>
                                    <button
                                            (click)="horizontalWizardStepperNext('other')"
                                            class="btn btn-primary btn-next"
                                            rippleEffect
                                    >
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.next' | translate }}</span>
                                        <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                        <div id="emergency-info" class="content">
                            <ng-container #socialLinkForm>
                                <div class="row">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="address-1">{{ 'employee.name' | translate }}</label>
                                            <input
                                                    id="emergencyName"
                                                    type="text"
                                                    class="form-control"
                                                    name="emergencyName"
                                                    formControlName="emergencyName"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="phone">{{ 'employee.phone' | translate }}</label>
                                            <input
                                                    id="phone"
                                                    name="phone"
                                                    formControlName="phone"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="5555555555"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label for="postcode">{{ 'employee.degreeOfAffinity' | translate }}</label>
                                            <input
                                                    id="degreeOfAffinity"
                                                    name="degreeOfAffinity"
                                                    formControlName="degreeOfAffinity"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Father"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <button (click)="horizontalWizardStepperPrevious()" class="btn btn-primary btn-prev"
                                            rippleEffect>
                                        <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                        <span class="align-middle d-sm-inline-block d-none">{{ 'general.previous' | translate }}</span>
                                    </button>
                                    <button class="btn btn-success btn-submit" (click)="submit()" rippleEffect>
                                        {{ 'general.submit' | translate }}
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</div>
