import {Component, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EmployeeEntryModel} from '../shared/models/employee-entry.model';

@Component({
    selector: 'app-employee-entries-modal',
    templateUrl: './employee-entries-modal.component.html',
    styleUrls: ['./employee-entries-modal.component.scss']
})
export class EmployeeEntriesModalComponent implements OnInit {

    constructor(private modalService: NgbModal, private renderer: Renderer2) {
    }

    @Input('entries') employeeEntries: { day: string, entries: EmployeeEntryModel[] }[];
    @ViewChild('modalVC') modalVC: any;
    @ViewChild('tableRowDetails', {static: false}) tableRowDetails: any;
    public ColumnMode = ColumnMode;

    ngOnInit(): void {
    }

    modalOpenVC() {
        this.modalService.open(this.modalVC, {
            centered: true
        });
    }

    rowDetailsToggleExpand(row) {
        this.tableRowDetails.rowDetail.toggleExpandRow(row);
    }

}
