<div class="card">
    <div class="card-header d-flex justify-content-between pb-0">
        <h4 class="card-title">{{'remainingWork.workTimeTracker' | translate}}</h4>
        <div ngbDropdown class="chart-dropdown">
            <button
                    rippleEffect
                    class="btn btn-sm border-0 p-50 font-large-1 text-black-50"
                    ngbDropdownToggle
                    type="button"
                    id="dropdownItem4"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
            >
                <i class="font-large-1" [data-feather]="'calendar'"></i> {{selectedDayCountName}}
            </button>
            <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownItem4">
                <a ngbDropdownItem (click)="onWeekChange('0,today')">{{ 'general.today' | translate }}</a>
                <a ngbDropdownItem (click)="onWeekChange('1,yesterday')">{{ 'general.yesterday' | translate }}</a>
                <a ngbDropdownItem (click)="onWeekChange('7,thisWeek')">{{ 'general.thisWeek' | translate }}</a>
                <a ngbDropdownItem (click)="onWeekChange('7,lastWeek')">{{'general.lastWeek' | translate}} </a>
                <a ngbDropdownItem (click)="onWeekChange('30,thisMonth')">{{ 'general.thisMonth' | translate }}</a>
                <a ngbDropdownItem (click)="onWeekChange('30,lastMonth')">{{ 'general.lastMonth' | translate }}</a>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-sm-2 col-12 d-flex flex-column flex-wrap text-center ml-5 text-success">
                <h1 class="font-large-2 font-weight-bolder mt-4 mb-0 text-success">
                    {{ currentWorkHour }}
                </h1>
                <p class="font-large-1">{{'general.hours' | translate}} </p>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <div id="supportChartoptions">
                    <apx-chart
                            class="cursor-pointer"
                            (click)="openEntriesModal()"
                            #supportChartRef
                            [series]="data.series"
                            [chart]="supportChartoptions.chart"
                            [plotOptions]="supportChartoptions.plotOptions"
                            [colors]="supportChartoptions.colors"
                            [fill]="supportChartoptions.fill"
                            [stroke]="supportChartoptions.stroke"
                            [labels]="supportChartoptions.labels"
                    ></apx-chart>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="text-center col-sm-4">
                <p class="card-text mb-50">{{'general.yourWorkingTime' | translate}}</p>
                <h2 class="font-weight-bold">{{ getTimeFromMinutes(employeeTimeData?.workingTimeInMinute) }}</h2>
            </div>
            <div class="text-center col-sm-4">
                <p class="card-text mb-50">{{'general.yourTravelTime' | translate}}</p>
                <h2 class="font-weight-bold">{{ getTimeFromMinutes(employeeTimeData?.travelTimeInMinute) }}</h2>
            </div>
            <div class="text-center col-sm-4">
                <p class="card-text mb-50">{{'remainingWork.remainingWorkTime' | translate}}</p>
                <h2 class="font-weight-bold">
                    {{ getTimeFromMinutes(currentWorkHour * 60 - employeeTimeData?.workingTimeInMinute
                    - employeeTimeData?.leaveTimeAsMinutes - employeeTimeData?.travelTimeAsMinutes - employeeTimeData?.holidayAsMinutes) }}
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="text-center col-sm-4">
                <p class="card-text mb-50">{{'remainingWork.leaveTimeAsHours' | translate}}</p>
                <h2 class="font-weight-bold">{{ getTimeFromMinutes(employeeTimeData?.leaveTimeAsMinutes) }}</h2>
            </div>
            <div class="text-center col-sm-4">
                <p class="card-text mb-50">{{'remainingWork.travelTimeAsHours' | translate}}</p>
                <h2 class="font-weight-bold">{{ getTimeFromMinutes(employeeTimeData?.travelTimeAsMinutes) }}</h2>
            </div>
            <div class="text-center col-sm-4">
                <p class="card-text mb-50">{{'remainingWork.holidayAsMinutes' | translate}}</p>
                <h2 class="font-weight-bold">{{ getTimeFromMinutes(employeeTimeData?.holidayAsMinutes) }}</h2>
            </div>
        </div>
    </div>
</div>
<app-employee-entries-modal [entries]="groupedEntries"></app-employee-entries-modal>
