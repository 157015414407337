import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseResponse} from 'app/main/data/base-response.model';
import {BaseService} from 'app/main/shared/base/services/base.service';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs';
import {EmployeeRequest} from '../../employee/data/models/employee-request.model';
import {RemainingWorkDTO} from './models/remaining-work-response.model';
import {PageFilter} from '../../../shared/base/models/pageFilter.model';
import {getPageAndSizeParams} from '../../../shared/utils';
import {EmployeeEntryModel} from './models/employee-entry.model';

@Injectable({
    providedIn: 'root',
})
export class RemainingWorkService extends BaseService<EmployeeRequest, RemainingWorkDTO> {
    constructor(private http: HttpClient) {
        super(http, 'employee-service/employee-entries');
    }

    getExport(pageFilter: PageFilter): Observable<BaseResponse<RemainingWorkDTO[]>> {
        return this.http.get<any>(`${environment.apiUrl}employee-service/employee-entries/export`,
            {
                params: pageFilter ? getPageAndSizeParams(pageFilter) : {},
            }
        );
    }

    addWorkTime(entry: EmployeeEntryModel): Observable<BaseResponse<EmployeeEntryModel>> {
        return this.http.post<BaseResponse<EmployeeEntryModel>>(`${environment.apiUrl}employee-service/employee-entries`, entry);
    }

    getEmployeeDetail(id: string, startDate: string, endDate: string): Observable<BaseResponse<RemainingWorkDTO>> {
        if (!id) {
            return new Observable();
        }
        return this.http.get<any>(
            `${environment.apiUrl}employee-service/employee-entries/${id}/?startDate=${startDate}&endDate=${endDate}`
        );
    }
}
