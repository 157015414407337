import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthenticationService} from 'app/auth/service';
import {ToastService} from '../../../@core/services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    /**
     * @param {Router} _router
     * @param {AuthenticationService} _authenticationService
     * @param toastService
     */
    constructor(private _router: Router, private _authenticationService: AuthenticationService, private toastService: ToastService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                if (this._authenticationService.isLoggedIn() && [401].indexOf(err.status) !== -1) {

                    this._router.navigate(['/pages/authentication/login']).then(x => {
                        this.toastService.error('Not authorized!', 'Not authorized!');
                        this._authenticationService.logout();
                    });
                }
                if ([403].indexOf(err.status) !== -1) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    this._router.navigate(['/pages/miscellaneous/not-authorized']).then(x => {
                        this.toastService.error('Not authorized!', 'Not authorized!');
                    });

                    // ? Can also logout and reload if needed
                    // this._authenticationService.logout();
                    // location.reload(true);
                }
                // throwError
                const error = err?.error?.message || err.statusText;
                return throwError(error);
            })
        );
    }
}
