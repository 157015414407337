<li ngbDropdown class="nav-item dropdown-notification mr-25">
    <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span
            [ngClass]="{
                'badge-secondary': length === 0,
                'badge-danger': length > 0
            }"
            class="badge badge-pill badge-up">{{
        length
        }}</span></a>
    <ul
            ngbDropdownMenu
            aria-labelledby="navbarNotificationDropdown"
            class="dropdown-menu dropdown-menu-media dropdown-menu-right"
    >
        <!-- Notifications header -->
        <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
                <div class="badge badge-pill badge-light-primary">
                    {{ length }} New
                </div>
            </div>
        </li>
        <!--/ Notifications header -->

        <!-- Notifications content -->
        <li class="scrollable-container media-list" [perfectScrollbar]>
            <a (click)="onNotificationClick(notification)" class="d-flex" href="javascript:void(0)"
               *ngFor="let notification of notifications">
                <div class="media d-flex align-items-start">
                    <div class="media-left">
                        <div class="avatar">
                            <i class="text-primary" [data-feather]="'bell'"></i>
                        </div>
                    </div>
                    <div class="media-body">
                        <p [ngClass]="{
                            'text-primary': !hasNotificationReceived(notification)
                        }" class="media-heading" [innerHTML]="notification.title"></p>
                        <small class="notification-text">{{ notification.message }}</small>
                    </div>
                </div>
            </a>
        </li>
        <!--/ Notifications content -->

        <!--        <li class="dropdown-menu-footer">-->
        <!--            <a class="btn btn-primary btn-block" href="javascript:void(0)">Read all notifications</a>-->
        <!--        </li>-->
    </ul>
</li>

<ng-template #modalSM let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel160">{{currentNotification?.title}}</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div>
            Message: {{currentNotification?.message}}
        </div>
        <div>
            Description: {{currentNotification?.desc}}
        </div>
    </div>
</ng-template>
