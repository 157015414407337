import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'app/main/shared/base/services/base.service';
import {NotificationRecipientStatus, NotificationResponseModel} from '../model/notification-response.model';
import {environment} from '../../../../../../environments/environment';
import {Observable} from 'rxjs';
import {BaseResponse} from '../../../../data/base-response.model';
import {NotificationRequestModel} from '../model/notification-request.model';

@Injectable({
    providedIn: 'root',
})
export class NotificationService extends BaseService<NotificationRequestModel, NotificationResponseModel> {
    constructor(private http: HttpClient) {
        super(http, 'notification-service/notifications');
    }

    getMyNotifications(): Observable<BaseResponse<NotificationResponseModel[]>> {
        return this.http.get<BaseResponse<NotificationResponseModel[]>>(`${environment.apiUrl}notification-service/notifications/my`);
    }

    setNotificationAsReceived(status: NotificationRecipientStatus): Observable<BaseResponse<NotificationRecipientStatus>> {
        return this.http.post<BaseResponse<NotificationRecipientStatus>>
        (`${environment.apiUrl}notification-service/notifications/received`, status);
    }
}
