import { ApiResponse } from './../api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { BaseListResponse, BaseResponse } from 'app/main/data/base-response.model';
import { GetAllParametres, PageFilter } from '../models/pageFilter.model';

@Injectable({
  providedIn: 'root',
})
export class BaseService<TRequest, TResponse> extends ApiService<TRequest, TResponse> {
  dataSubject$ = new BehaviorSubject(null);
  data$: Observable<BaseResponse<TResponse>>;
  dataList$: Observable<BaseListResponse<TResponse>>;

  constructor(http: HttpClient, url: string) {
    super(http, url);
    this.data$ = this.dataSubject$.asObservable();
  }

  getAll(pageFilter?: PageFilter) {
    return this.dataSubject$.pipe(
      switchMap((res) => {
        return res ? of(res) : this.refreshAll$(pageFilter);
      })
    );
  }

  /** Get single data from api
   *
   * Example: this.get(userId).subscribe(() => {
   * ....
   * });
   * @param id
   * @returns {Observable<T>}
   */
  get(id?: string): Observable<BaseResponse<TResponse>> {
    return this.getApi(id);
  }

  /** To post entities for create or etc.
   *
   * Example: this.post(userModel).subscribe(() => {
   * ....
   * });
   *
   * @param createObject
   * @returns {Observable<T>}
   */
  post(createObject?: TRequest): Observable<BaseResponse<TResponse>> {
    return this.postApi(createObject).pipe(
      tap((data) => {
        if (this.dataSubject$.value) {
          this.dataSubject$.value.data = [...this.dataSubject$.value.results, data];
          this.dataSubject$.value.totalCount += 1;
          this.dataSubject$.next(this.dataSubject$.value);
        }
      })
    );
  }

  /** Delete request with id for entities
   *
   * Example : this.delete(userId).subscribe(() => {
   * ....
   * });
   * @param id
   * @returns {Observable<T>}
   */
  delete(id?: string): Observable<BaseResponse<TResponse>> {
    return this.deleteApi(id).pipe(
      tap(() => {
        if (this.dataSubject$.value) {
          this.dataSubject$.value.data = this.dataSubject$.value.data.filter((data) => data.id != id);
          this.dataSubject$.value.total -= 1;
          if (this.dataSubject$.value.data && this.dataSubject$.value.data.length > 0)
            this.dataSubject$.next(this.dataSubject$.value);
          else if (!this.dataSubject$.value.data) this.dataSubject$.next(this.dataSubject$.value);
          else this.dataSubject$.next(null);
        }
      })
    );
  }

  /** Updates the given entity, returns updated entity
   *
   * Example: this.put(userId, userModel).subscribe(() => {
   * ....
   * });
   *
   * @param id
   * @param updateObject
   * @returns {Observable<T>}
   *
   */
  put(id?: string, updateObject?: TRequest): Observable<BaseResponse<TResponse>> {
    return this.putApi(id, updateObject).pipe(
      tap((data: BaseResponse<TResponse>) => {
        if (this.dataSubject$.value) {
          if (Array.isArray(this.dataSubject$.value.results)) {
            const array = this.dataSubject$.value.results;
            array[array.findIndex((x) => x.id == id)] = data.results;

            this.dataSubject$.value.results = array;

            this.dataSubject$.next(this.dataSubject$.value);
          } else {
            this.dataSubject$.next(data.results);
          }
        } else {
          this.dataSubject$.next(null);
        }
      })
    );
  }

  private refreshAll$(pageFilter?: PageFilter): Observable<BaseListResponse<TResponse>> {
    return this.getAllApi(pageFilter).pipe(
      tap((res: BaseListResponse<TResponse>) => {
        this.dataSubject$.next(res);
      })
    );
  }
}
