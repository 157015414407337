<ngx-datatable
    #tableRowDetails
    id="tableRowDetails"
    class="bootstrap core-bootstrap"
    [columnMode]="ColumnMode.force"
    [headerHeight]="40"
    [rowHeight]="58"
    [footerHeight]="50"
    rowHeight="auto"
    [limit]="10"
    [rows]="employeeEntries"
>
    <ngx-datatable-row-detail [rowHeight]="'auto'">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <ul class="list-group m-2">
                <li style="overflow-y: scroll" *ngFor="let row of row.entries" class="list-group-item">
                    <span><span [ngClass]="{
                         'text-danger': [2,4].includes(row.entryType),
                         'text-success': [1,3].includes(row.entryType)}">{{getEntryType(row.entryType) | translate}}</span>
                    </span> :
                    <span>{{row.verifyDate | date: 'HH:mm:ss'}}</span>
                </li>
            </ul>

        </ng-template>
    </ngx-datatable-row-detail>
    <ngx-datatable-column
            [width]="50"
            [resizeable]="false"
            [sortable]="false"
            [draggable]="false"
            [canAutoResize]="false"
    >
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <a
                    href="javascript:void(0)"
                    [class.datatable-icon-right]="!expanded"
                    [class.datatable-icon-down]="expanded"
                    title="Expand/Collapse Row"
                    (click)="rowDetailsToggleExpand(row)"
            >
            </a>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'general.date' | translate}}" prop="verifyDate">
        <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.day}}
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'remainingWork.firstEntryTime' | translate}}">
        <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="text-success">{{getFirstEntryOrLastExit(row.entries, true).verifyDate | date: 'HH:mm'}}</span>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'remainingWork.lastExitTime' | translate}}">
        <ng-template let-row="row" ngx-datatable-cell-template>
                    <span *ngIf="getFirstEntryOrLastExit(row.entries, false)"
                          class="text-danger">{{getFirstEntryOrLastExit(row.entries, false).verifyDate | date: 'HH:mm'}}
                    </span>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
