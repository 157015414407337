import {Component, OnInit} from '@angular/core';
import {CoreConfigService} from '@core/services/config.service';
import moment from 'moment';
import {FlatpickrOptions} from 'ng2-flatpickr';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})

export class DashboardComponent implements OnInit {
    nodeContentProperty = 'title';
    isDark = false;
    private _unsubscribeAll: Subject<any>;
    dateTimeOptions: FlatpickrOptions = {
        altInput: true,
        enableTime: true,
        dateFormat: 'YYYY-MM-DD HH:mm',
        altFormat: 'YYYY-MM-DD HH:mm',
        parseDate: (datestr, format) => {
            return moment(datestr, format, true).toDate();
        },
        formatDate: (date, format, locale) => {
            // locale can also be used
            return moment(date).format(format);
        },
    };

    constructor(private _coreConfigService: CoreConfigService) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.isDark = config && config.layout.skin === 'dark';
        });
    }
}
