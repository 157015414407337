import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from 'environments/environment';
import {Role, User} from 'app/auth/models';
import {ToastrService} from 'ngx-toastr';
import {BaseResponse} from 'app/main/data/base-response.model';
import {TokenResponse} from '../models/token-response';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    token: string;
    //public
    public currentUser: Observable<User>;
    user: User;

    //private
    private currentUserSubject: BehaviorSubject<User>;

    /**
     *
     * @param {HttpClient} _http
     * @param {ToastrService} _toastrService
     */
    constructor(private _http: HttpClient, private _toastrService: ToastrService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.user = this.currentUserSubject.value;
    }

    // getter: currentUserValue
    public get currentUserValue(): User {
        this.currentUserSubject.next(JSON.parse(localStorage.getItem('currentUser')));
        return this.currentUserSubject.value;
    }

    /**
     *  Confirms if employee is admin
     */
    get isAdmin() {
        return this.currentUser && this.currentUserSubject.value.role === Role.SystemAdmin;
    }

    /**
     *  Confirms if employee is client
     */
    get isClient() {
        return this.currentUser && this.currentUserSubject.value.role === Role.User;
    }

    /**
     * User login
     *
     * @param username
     * @param password
     * @returns user
     */
    login(username: string, password: string) {
        return this._http
            .post<BaseResponse<TokenResponse>>(`${environment.apiUrl}auth-service/authentication/login`, {
                username,
                password,
            })
            .pipe(
                map((res) => {
                    if (res && res.results && res.results.token) {
                        this.user = {
                            id: res.results.id,
                            email: res.results.username,
                            role: res.results.userRole.id,
                            token: res.results.token,
                            companyId: res.results.companyId,
                            companyName: res.results.companyName,
                            employeeId: res.results.employeeId,
                            profilePhotoUrl: `${environment.apiUrl}employee-service/employees/${res.results.employeeId}/photo`,
                            fullName: res.results.fullName,
                        };
                        localStorage.setItem('currentUser', JSON.stringify(this.user));

                        setTimeout(() => {
                            this._toastrService.success(
                                'You have successfully logged in as an ' + res.results.userRole.name,
                                '👋 Welcome, ' + '!',
                                {toastClass: 'toast ngx-toastr', closeButton: true}
                            );
                        }, 2500);

                        // notify
                        this.currentUserSubject.next(this.user);
                    } else {
                    }

                    return res;
                })
            );
    }

    /**
     * User logout
     *
     */
    logout() {
        // remove employee from local storage to log employee out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('config');
        // notify
        this.currentUserSubject.next(null);
    }

    resetPasswordRequest = (email: string): Observable<BaseResponse<any>> => {
        return this._http.post<BaseResponse<any>>
        (`${environment.apiUrl}auth-service/authentication/send-reset-password-email`, {email});
    };

    resetPassword = (password: string, forgotPasswordCode: string): Observable<BaseResponse<any>> => {
        return this._http.post<BaseResponse<any>>(`${environment.apiUrl}auth-service/authentication/reset-password`,
            {forgotPasswordCode, password});
    };

    hasRole = (routeRoles: Array<number | string>): boolean => this.isLoggedIn() && routeRoles.includes(this.user.role);

    isLoggedIn = (): boolean => !!this.user;
}
