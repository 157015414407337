import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse } from 'app/main/data/base-response.model';
import { BaseService } from 'app/main/shared/base/services/base.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { DocumentFileTypeModel } from '../../file-uploader/shared/models/file.model';
import { DocumentModel } from './document.mode';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends BaseService<DocumentModel, DocumentModel> {
  constructor(private http: HttpClient) {
    super(http, 'document-service/documents');
  }

  getDocuments(): Observable<BaseResponse<DocumentModel[]>> {
    return this.http.get<BaseResponse<DocumentModel[]>>(`${environment.apiUrl}document-service/documents`);
  }

  addDocuments(document: DocumentModel): Observable<BaseResponse<DocumentModel>> {
    return this.http.post<BaseResponse<DocumentModel>>(`${environment.apiUrl}document-service/documents`, document);
  }

  downloadDocuments(id: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}document-service/documents/${id}/file`, {
      responseType: 'arraybuffer',
    });
  }

  addDocumentFileType(title: string): Observable<BaseResponse<DocumentFileTypeModel>> {
    return this.http.post<BaseResponse<DocumentFileTypeModel>>(
      `${environment.apiUrl}document-service/document-file-types`,
      { title }
    );
  }
  addDocumentType(title: string): Observable<BaseResponse<DocumentFileTypeModel>> {
    return this.http.post<BaseResponse<DocumentFileTypeModel>>(`${environment.apiUrl}document-service/document-types`, {
      title,
    });
  }
}
